import React from "react";
import "./signupPortal.scss";
import signupimage from "../../../assets/img/signup-portal.svg";
import logo from "../../../assets/img/logowithname.svg";
import divider from "../../../assets/img/Or-divider.svg";
import email from "../../../assets/img/a.svg";
import pass from "../../../assets/img/shield-security.svg";
import companylogo from "../../../assets/img/company-name.svg";
import { Link } from "react-router-dom";
import FormInput from "../../../core/components/Inputs/FormInput";
import OtpEmail from "./OtpEmail";
import tickIcon from "../../../assets/img/Check-pass.svg";
import crossIcon from "../../../assets/img/Close-red.svg";
import clap from "../../../assets/img/welcome_clapping.svg";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import { ToastContainer } from "react-toastify";

const SignupPortal = (props) => {
  const {
    navigate,
    otpModal,
    setOtpModal,
    param,
    data,
    handleChange,
    handleValidation,
    formData,
    emailExistance,
    error,
    handleSubmit,
  } = props;
  return (
    <div className="signup-portal">
      <div className="col-4 left">
        <img
          src={data?.background_img || signupimage}
          alt="signupimage"
          className="center"
        />
      </div>
      <div className="col-8 right" style={{ overflow: "auto" }}>
        <div className="me-5 text-end" style={{ marginTop: "47px" }}>
          <p style={{ alignContent: "end" }}>
            Already a member?
            <Link to={`/tp/portal/${param}/login`}>
              <span
                className="font-700"
                style={{ color: "#0432a3", marginLeft: "4px" }}
              >
                Log in
              </span>
            </Link>
          </p>
        </div>
        <div className="signup-form">
          <form className="auth-form float-none portal">
            <p>
              {/* Welcome <img src={clap} alt="clap" /> */}
              Welcome 👋
            </p>
            <h3 className="form-heading text-dark">Sign up to your account</h3>
            <h4 className="sub-heading my-4">
              Sign up with{" "}
              <img
                className="nobalaccount"
                src={logo}
                alt="logo"
                onClick={() => navigate(`/signup`)}
              />{" "}
            </h4>
            <img src={divider} alt="divider" className="mb-3" />
            <h4 className="text-dark">Sign up </h4>
            <FormInput
              icon={emailExistance && formData?.email.trim() && tickIcon}
              label="Email"
              name="email"
              type="email"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Email"
              onBlur={() => handleValidation("email")}
              error={error?.email}
              imageCustomClass="img-prop"
              value={formData?.email}
              labelStyle={{ fontWeight: 400, color: "#333333" }}
            />
            <FormInput
              label="Password"
              name="password"
              type="password"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Password"
              onBlur={() => handleValidation("password")}
              error={error?.password}
              value={formData?.password}
              labelStyle={{ fontWeight: 400, color: "#333333" }}
              containerClassName={"mt-2"}
            />
            <FormInput
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              onChange={(e) => handleChange(e)}
              placeholder="Enter Password"
              onBlur={() => handleValidation("confirmPassword")}
              error={error?.confirmPassword}
              value={formData?.confirmPassword}
              labelStyle={{ fontWeight: 400, color: "#333333" }}
              containerClassName={"mt-2"}
            />
            <div className="text-center mt-3 privacy-policy">
              <p>
                By clicking &quot;Sign up&quot;, you agree to NobelPage&apos;s{" "}
                <Link to="/legal">
                  <span>
                    Privacy Policy, Cookie Policy and Terms and Conditions
                  </span>
                </Link>{" "}
                {/* including Terms of Use, Privacy Policy, Cookie Policy and
                Community GuidelinesTerms and Conditions & Privacy Policy */}
              </p>
            </div>
            <div className="text-center d-grid mt-3 mb-4">
              <button
                type="submit"
                className="btn btn-orange"
                onClick={(e) => handleSubmit(e)}
                style={{ backgroundColor: `${data?.theme}` }}
                // onMouseDown={submitSignUpData}
              >
                Sign up
              </button>
            </div>
            <div
              className="d-flex justify-content-center align-items-center mt-4"
              style={{ height: "40px" }}
            >
              <img
                src={data?.icon ? data?.icon : pagePlaceholder}
                alt="companny-logo"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
              <h4 className="ms-2 card-control-text-oneline">
                {data?.company_name}
              </h4>
            </div>
          </form>
        </div>
      </div>
      {otpModal && (
        <OtpEmail
          modal={otpModal}
          toggle={() => setOtpModal(false)}
          title="Verify email"
          data={data}
          email={formData?.email}
          setOtpModal={setOtpModal}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default SignupPortal;
