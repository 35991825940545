import { React, useRef } from "react";
import Navbar from "../../pages/navbar/navbar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Notification.scss";
import "../dashboard/dashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import Advertisement from "../dashboard/Advertisement";
import Ad from "../../core/components/layout/Sidebar/Ad";
import { ToastContainer, toast } from "react-toastify";
import Chat from "../../pages/chat/chat";
import SidebarFooter from "../setting/SidebarFooter";
import NotificationMenuIcon from "../../assets/img/NotificationMenuIcon.svg";
import defaultIcon from "../../assets/img/default_profile_sqr img.svg";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import cale from "../../assets/img/calendar.svg";
import {
  getAllNotification,
  updateAsReadNotification,
  updateAsUnreadNotification,
  updateNotification,
} from "../../store/notification/notificationAction";
import NpMailModal from "../../core/components/modal/NpMailModal";
import Npmail from "../../core/components/layout/Sidebar/Npmail";
// import NpMailNotification from "./NpmailNotification";

import NpMailNotification from "./NpMailNotification";
import { abbreviateNumber } from "../../core/components/Utility/Utility";
import InfiniteScroll from "react-infinite-scroll-component";
import ShowNotification from "./ShowNotification";
//import InfiniteScroll from "react-infinite-scroll-component";

const Notification = () => {
  const { isLoading } = useSelector((state) => state.loader);

  const [isSticky, setIsSticky] = useState(false);

  const { suggested_groups } = useSelector((state) => state.groups);
  const { suggested_events } = useSelector((state) => state.events);
  const { suggested_articles } = useSelector((state) => state.article);
  const { user } = useSelector((state) => state.auth);
  const { pageData } = useSelector((state) => state.pages);
  const { followedTagList } = useSelector((state) => state.hashtag);
  const { allNotifications } = useSelector((state) => state.notification);

  const [tabs, setTabs] = useState("all");
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isHasMore, setIsHasMore] = useState(false);
  const [isNotificationHeaderMenu, setIsNotificationHeaderMenu] =
    useState(true);
  const [dateFilter, setDateFilter] = useState("");
  const node1 = useRef();
  const menu = useRef();
  const navigate = useNavigate();

  const viewConnection = () => {
    navigate("/connections");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menu.current && !menu.current.contains(event.target)) {
        setIsNotificationHeaderMenu(true);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch();
  const fetchNotifications = () => {
    dispatch(getAllNotification({ limit: 20 }, currentCount, "all")).then(
      (res) => {
        setTotalCount(res?.count);
      }
    );
  };
  useEffect(() => {
    fetchNotifications();
  }, []);
  useEffect(() => {
    if (allNotifications?.length < totalCount) setIsHasMore(true);
    else setIsHasMore(false);
  }, [currentCount, totalCount]);

  const npmails = allNotifications?.filter((ele) => ele?.type === "user");
  const handleItemClick = (notification) => {
    dispatch(
      updateNotification(notification?.id, { readStatus: true }, "read")
    );
    let routePath = "";

    if (notification?.id != undefined) {
      switch (notification?.type) {
        case "connection-request":
          routePath = `/invitations`;
          break;
        case "newsletter-unsubscribe":
          routePath = `/newsletterdetail/${notification?.Newsletter?.id}`;
          break;
        case "newsletter-subscribe":
          routePath = `/newsletterdetail/${notification?.Newsletter?.id}`;
          break;
        case "page-follow":
          routePath = `/pageadminview/${notification?.Page?.id}`;
          break;
        case "page-admin":
          routePath = `/pageadminview/${notification?.Page?.id}`;
          break;
        case "page-transfer":
          routePath = `/pageadminview/${notification?.Page?.id}`;
          break;
        case "group-member":
          routePath = `/groupdetails/${notification?.Group?.id}`;
          break;
        case "group-exit":
          routePath = `/groupdetails/${notification?.Group?.id}`;
          break;
        case "group-request":
          routePath = `/groupdetails/${notification?.Group?.id}`;
          break;
        case "group-join":
          routePath = `/groupdetails/${notification?.Group?.id}`;
          break;

        case "hashtag-follow":
          routePath = `/hashtag/feed/?keyword=${notification?.Hashtag?.name}`;
          break;
        case "hashtag-unfollow":
          routePath = `/hashtag/feed/?keyword=${notification?.Hashtag?.name}`;
          break;
        case "event-attend":
          routePath = `/eventdetail/${notification?.Event?.id}`;
          break;
        case "job-shortlist":
          routePath = `/jobdetails/${notification?.Job?.id}`;
          break;
        case "job-reject":
          routePath = `/jobdetails/${notification?.Job?.id}`;
          break;
        case "job-toplist":
          routePath = `/jobdetails/${notification?.Job?.id}`;
          break;
        case "job-close":
          routePath = `/jobs`;
          break;
        case "job-desc-change":
          routePath = `/jobs`;
          break;
        case "job-alert":
          routePath = "/myjobs";
          break;
        case "user":
          routePath = `/profile/${notification?.User?.id}`;
          break;
        case "new-follwer":
          routePath = `/profile/${notification?.Requested_User?.id}`;
          break;
        case "view-profile":
          routePath = `/profile/${notification?.Requested_User?.id}`;
          break;
        case "smart-interview":
          routePath = `/recuriter/give-interview`;
          break;
        case "event-speaker":
          routePath = `/eventdetail/${notification?.Event?.id}`;
          break;
        case "birthday":
          routePath = `/profile/${notification?.Requested_User?.id}`;
          break;
        case "new-connection":
          routePath = `/profile/${notification?.Requested_User?.id}`;
          break;
        case "group-post":
          routePath = `/groupdetails/${notification?.sender_id}`;
          break;
        case "group-post_notify_admin":
          routePath = `/groupdetails/${notification?.sender_id}`;
          break;
        case "view-job":
          routePath = `/jobdetail/${notification?.Job?.id}`;
          break;
        case "job-matched":
          // Redirect to job details
          routePath = `/jobdetail/${notification?.Job?.id}`;
          break;
        case "like-feed":
          if (notification?.sender_type === "feed") {
            // Redirect to feed liked
            routePath = `/postdetails/${notification?.sender_id}`;
          }
          break;
        case "add-comment":
          if (notification?.sender_type === "feed") {
            // Redirect to feed comment
            routePath = `/postdetails/${notification?.sender_id}`;
          }
          break;
        case "poll-expire":
          if (notification?.sender_type === "feed") {
            // Redirect to feed
            routePath = `/postdetails/${notification?.sender_id}`;
          }
          break;
        case "post-promote":
          routePath = ``;
          break;
        case "user-job-alert":
          if (notification?.sender_type === "job") {
            // Redirect to job details
            routePath = `/jobdetail/${notification?.Job?.id}`;
          } else {
            // Redirect to jobs
            routePath = `/jobs`;
          }
          break;
        case "user-job-recommendation":
          routePath = ``;
          break;
        case "job-preference-update":
          // Job Preference
          routePath = `/profile`;
          navigate(routePath, { state: { isSeekingBadge: true } });
          break;
        case "job-reminder":
          if (notification?.sender_type === "job") {
            routePath = `/jobdetail/${notification?.Job?.id}`;
          } else {
            // Redirect to jobs
            routePath = `/jobs`;
          }
          break;
        case "same-job-opportunity-you-hiring":
          const userId = notification?.Requested_User?.id;
          if (userId) {
            routePath = `/profile/${userId}`;
          } else {
            routePath = ``;
          }
          break;
        case "coworker-activity":
          if (notification?.sender_type === "page") {
            const pageId = notification?.Page?.id;
            routePath = `/pageadminview/${pageId}`;
          } else if (notification?.sender_type === "event") {
            const eventId = notification?.Event?.id;
            routePath = `/eventdetail/${eventId}`;
          } else if (notification?.sender_type === "group") {
            const groupId = notification?.Group?.id;
            routePath = `/groupdetails/${groupId}`;
          } else if (notification?.sender_type === "newsletter") {
            const newsletterId = notification?.Newsletter?.id;
            routePath = `/newsletterdetail/${newsletterId}`;
          }
          break;
        case "connection-activity":
          if (notification?.sender_type === "page") {
            const pageId = notification?.Page?.id;
            routePath = `/pageadminview/${pageId}`;
          } else if (notification?.sender_type === "event") {
            const eventId = notification?.Event?.id;
            routePath = `/eventdetail/${eventId}`;
          } else if (notification?.sender_type === "group") {
            const groupId = notification?.Group?.id;
            routePath = `/groupdetails/${groupId}`;
          } else if (notification?.sender_type === "newsletter") {
            const newsletterId = notification?.Newsletter?.id;
            routePath = `/newsletterdetail/${newsletterId}`;
          }
          break;
        case "skill-endorsement":
          routePath = `/profile`;
          navigate(routePath, { state: { isRecommendationTabActive: true } });
          break;
        case "membership-reminder":
          routePath = `/membership`;
          break;
        case "page-inivitation":
          routePath = `/pageadminview/${notification?.Page?.id}`;
          break;
        case "page-reached-insight":
          routePath = `/pageadminview/${notification?.Page?.id}`;
          break;
        case "page-followed-insight":
          routePath = `/pageadminview/${notification?.Page?.id}`;
          break;
        case "page-engaged-insight":
          routePath = `/pageadminview/${notification?.Page?.id}`;
        case "background-screening":
          routePath = `/background-report`;
          break;
        default:
          break;
      }
    }
    if (notification?.type === "smart-interview") {
      navigate(routePath, { state: { shortlist_id: notification?.Job?.id } });
    } else navigate(routePath);
  };

  const updateAllAsRead = (e) => {
    setIsNotificationHeaderMenu(true);
    dispatch(updateAsReadNotification()).then((res) => {
      toast.success(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };
  const updateAllAsUnRead = () => {
    setIsNotificationHeaderMenu(true);
    dispatch(updateAsUnreadNotification()).then((res) => {
      toast.success(res.data.message, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    });
  };

  const notificationHeaderMenuClick = () => {
    setIsNotificationHeaderMenu(!isNotificationHeaderMenu);
  };

  useEffect(() => {
    setCurrentCount(0);
    if (tabs === "all") {
      dispatch(
        getAllNotification(
          { limit: 20, skip: 0, date_posted: dateFilter },
          0,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    } else if (tabs === "unread") {
      dispatch(
        getAllNotification(
          { limit: 20, skip: 0, filter: "unread", date_posted: dateFilter },
          0,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    } else if (tabs === "pinned") {
      dispatch(
        getAllNotification(
          { limit: 20, skip: 0, filter: "pinned", date_posted: dateFilter },
          0,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    } else if (tabs === "connections") {
      dispatch(
        getAllNotification(
          {
            limit: 20,
            skip: 0,
            filter: "connection-request",
            date_posted: dateFilter,
          },
          0,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    } else if (tabs === "following") {
      dispatch(
        getAllNotification(
          { limit: 20, skip: 0, filter: "following", date_posted: dateFilter },
          0,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
      });
    }
  }, [tabs, dateFilter]);
  const loadMore = () => {
    const newSkip = currentCount + 20;
    if (tabs === "all") {
      dispatch(
        getAllNotification(
          { limit: 20, skip: newSkip, date_posted: dateFilter },
          newSkip,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
        setCurrentCount(currentCount + 20);
      });
    } else if (tabs === "unread") {
      dispatch(
        getAllNotification(
          {
            limit: 20,
            skip: newSkip,
            filter: "unread",
            date_posted: dateFilter,
          },
          newSkip,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
        setCurrentCount(currentCount + 20);
      });
    } else if (tabs === "pinned") {
      dispatch(
        getAllNotification(
          {
            limit: 20,
            skip: newSkip,
            filter: "pinned",
            date_posted: dateFilter,
          },
          newSkip,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
        setCurrentCount(currentCount + 20);
      });
    } else if (tabs === "connections") {
      dispatch(
        getAllNotification(
          {
            limit: 20,
            skip: newSkip,
            filter: "connection-request",
            date_posted: dateFilter,
          },
          newSkip,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
        setCurrentCount(currentCount + 20);
      });
    } else if (tabs === "following") {
      dispatch(
        getAllNotification(
          {
            limit: 20,
            skip: newSkip,
            filter: "following",
            date_posted: dateFilter,
          },
          newSkip,
          "all"
        )
      ).then((res) => {
        setTotalCount(res?.count);
        setCurrentCount(currentCount + 20);
      });
    }
  };

  return (
    <>
      {isLoading && <AppLoader />}
      <div className="dashboard bg-grey">
        <Navbar />

        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-3">
              <div
                className={isSticky ? "sidebar sidebar-sticky-left" : "sidebar"}
              >
                <div className="left border-radius-5">
                  <div className="profile">
                    {user?.data?.background_img ? (
                      <img
                        src={user?.data?.background_img}
                        alt="background img"
                        className="back-image-fluid"
                        style={{ position: "absolute" }}
                      />
                    ) : (
                      <></>
                    )}

                    <div className="img">
                      <Link to={"/profile/" + user?.data?.id}>
                        <img
                          src={
                            user?.data?.profile_img
                              ? user?.data?.profile_img
                              : defaultIcon
                          }
                          alt="profile img"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="starter">
                    {user?.data?.membership?.name || "Starter"}
                  </div>
                  <div className="px-3">
                    <div className="profile-details">
                      <Link to={"/profile/" + user?.data?.id}>
                        <h6 className="userNameTextWrap">
                          {user?.data?.first_name} {user?.data?.last_name}
                        </h6>
                      </Link>

                      <p className="card-control-text-twoline">
                        {user?.data?.profile_headline
                          ? user?.data?.profile_headline
                          : ""}
                      </p>
                    </div>
                    <div className="margin">
                      <div className="follower" onClick={viewConnection}>
                        <div className="box curserPointer">
                          <h6>
                            {user?.data?.connections
                              ? abbreviateNumber(user?.data?.connections)
                              : 0}
                          </h6>
                          <p>
                            {user?.data?.connections > 1
                              ? "Connections"
                              : "Connection"}
                          </p>
                        </div>
                        <div className="h-border "></div>
                        <div className="box curserPointer">
                          <h6>
                            {user?.data?.followers
                              ? abbreviateNumber(user?.data?.followers)
                              : 0}
                          </h6>
                          <p>
                            {user?.data?.followers > 1
                              ? "Followers"
                              : "Follower"}
                          </p>
                        </div>
                        <div className="h-border"></div>

                        <div className="box curserPointer">
                          <h6>
                            {user?.data?.followings
                              ? abbreviateNumber(user?.data?.followings)
                              : 0}
                          </h6>
                          <p>
                            {/* {user?.data?.followings > 1
                              ? "Followings"
                              : "Following"} */}
                            Following
                          </p>
                        </div>
                      </div>
                      <div className="line mb-20"></div>
                    </div>
                    <div className="update mt-3">
                      <Link to={"/profile/" + user?.data?.id}>
                        <span>View My Profile</span>
                      </Link>
                    </div>
                    {pageData?.data?.length > 0 && (
                      <div className="pages my-4">
                        <h4 className="mb-3">
                          {pageData?.data?.length > 1 ? "My Pages" : "My Page"}
                        </h4>
                        <div className="line"></div>
                        <div className="mt-3">
                          {pageData?.data?.map((item, index) => {
                            if (index < 3) {
                              return (
                                <div key={index} className="box">
                                  <Link to={`/pageadminview/${item?.id}`}>
                                    <div className="img">
                                      {item?.background_img ? (
                                        <img
                                          src={item?.background_img}
                                          alt=""
                                        />
                                      ) : (
                                        <img alt="" />
                                      )}

                                      <img
                                        src={
                                          item?.icon
                                            ? item?.icon
                                            : pagePlaceholder
                                        }
                                        alt=""
                                        className="page-icon"
                                      />
                                    </div>
                                  </Link>
                                  <div className="text">
                                    <Link to={`/pageadminview/${item?.id}`}>
                                      <h6
                                        className="card-control-text-twoline"
                                        style={{
                                          wordBreak: "break-word",
                                          color: "#0432a3",
                                        }}
                                      >
                                        {item.name}
                                      </h6>
                                    </Link>
                                    <span>
                                      {item?.followers_count}{" "}
                                      {item?.followers_count > 1
                                        ? "followers"
                                        : "follower"}
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          })}
                          <div className="mt-3">
                            <Link to={"/pages"}>
                              <span>See all</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="article my-4">
                      <h4 className="mb-3">Articles for you</h4>
                      <div className="line"></div>
                      <div className="mt-3">
                        {suggested_articles?.map((item, index) => {
                          if (index < 3) {
                            return (
                              <div key={index} className="box">
                                <Link to={`/articledetail/${item?.id}`}>
                                  <div className="img">
                                    {item?.media ? (
                                      <img src={item?.media} alt="" />
                                    ) : (
                                      <img src={null} alt="" />
                                    )}
                                  </div>
                                </Link>
                                <div className="text">
                                  <Link to={`/articledetail/${item?.id}`}>
                                    <h6
                                      className="card-control-text-twoline"
                                      style={{ wordBreak: "break-word" }}
                                    >
                                      {item.title}
                                    </h6>
                                  </Link>
                                  <span>12,932 viewers</span>
                                </div>
                              </div>
                            );
                          }
                        })}
                        <div className="mt-3">
                          <Link to={"/articles"}>
                            <span>See all</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="groups my-4">
                      <h4 className="mb-3">Groups</h4>
                      <div className="line mb-3"></div>
                      <ul>
                        {suggested_groups?.map((item, index) => {
                          if (index < 3) {
                            return (
                              <Link to={`/groupdetails/${item?.id}`}>
                                <li
                                  className="card-control-text-oneline mb-2"
                                  style={{ wordBreak: "break-word" }}
                                  key={index}
                                >
                                  {item.name}
                                </li>
                              </Link>
                            );
                          }
                        })}
                      </ul>
                      <Link to={"/groups"}>
                        <span className="ms-0">See all</span>
                      </Link>
                    </div>
                    <div className="events mb-3">
                      <h4 className="mb-3">Events</h4>
                      <div className="line mb-3"></div>
                      <ul className="mb-2">
                        {suggested_events?.map((item, index) => {
                          if (index < 3) {
                            return (
                              <Link to={`/eventdetail/${item?.id}`}>
                                <li
                                  className="card-control-text-oneline"
                                  style={{ wordBreak: "break-word" }}
                                  key={index}
                                >
                                  <div className="icon">
                                    <img src={cale} alt="" />
                                  </div>{" "}
                                  {item.title}
                                </li>
                              </Link>
                            );
                          }
                        })}
                      </ul>
                      <Link to={"/event"}>
                        <span className="ms-0">See all</span>
                      </Link>
                    </div>
                    {followedTagList?.length > 0 ? (
                      <div className="follow mt-4 mb-10 pb-2">
                        <h4 className="mb-3">Followed Hashtags</h4>
                        <div className="line"></div>
                        <div className="tag_parent">
                          {followedTagList?.map((item, index) => {
                            if (index < 6) {
                              return (
                                <div>
                                  <p className="tags_text">#{item?.name}</p>
                                </div>
                              );
                            }
                          })}
                        </div>
                        <Link to={"/hashtag"}>
                          <span>See all</span>
                        </Link>
                      </div>
                    ) : (
                      <div className="mb-10 pb-2"></div>
                    )}
                  </div>
                </div>
                <Link to={"/network"} style={{ backgroundColor: "white" }}>
                  <div className="left px-3 py-3 mb-10 d-flex justify-content-center border-radius-5 bg-white">
                    <div className="discover">
                      <h4>Discover more</h4>
                    </div>
                  </div>
                </Link>
                <div>
                  <div>
                    <SidebarFooter />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="my-jobs-section">
                <div className="head" style={{ position: "relative" }}>
                  <h4>Notifications</h4>
                  <img
                    src={NotificationMenuIcon}
                    onClick={() => notificationHeaderMenuClick()}
                    style={{ height: "5px", cursor: "pointer" }}
                    alt=""
                    ref={node1}
                  />
                  {!isNotificationHeaderMenu ? (
                    <div className="notificationMenuDropdown" ref={menu}>
                      <ul>
                        <li
                          className="notification-dropdown-span"
                          onClick={() => updateAllAsRead()}
                        >
                          <span>Mark All Read</span>
                        </li>

                        <li
                          className="notification-dropdown-span"
                          onClick={() => updateAllAsUnRead()}
                        >
                          <span>Mark All Unread</span>
                        </li>
                        <li>
                          <div className="line my-1"></div>
                        </li>
                        <li
                          className="notification-dropdown-span"
                          onClick={() => {
                            setIsNotificationHeaderMenu(true);
                            // setTabs("all-time");
                            setDateFilter("");
                          }}
                        >
                          <span>Any Time</span>
                        </li>
                        <li
                          className="notification-dropdown-span"
                          onClick={() => {
                            setDateFilter("month");
                            setIsNotificationHeaderMenu(true);
                          }}
                        >
                          <span>Past Month</span>
                        </li>
                        <li
                          className="notification-dropdown-span"
                          onClick={() => {
                            setDateFilter("week");
                            setIsNotificationHeaderMenu(true);
                          }}
                        >
                          <span>Past Week</span>
                        </li>
                        <li
                          className="notification-dropdown-span"
                          onClick={() => {
                            setDateFilter("hour");
                            setIsNotificationHeaderMenu(true);
                          }}
                        >
                          <span>Past 24 Hours</span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="tabs-area mt-20">
                  <div className="overflow_hidden">
                    <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-bs-toggle="tab"
                          href="#all"
                          onClick={() => {
                            setTabs("all");
                          }}
                        >
                          All
                        </a>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          setTabs("unread");
                        }}
                      >
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#completed"
                        >
                          Unread
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#featured"
                          onClick={() => {
                            setTabs("pinned");
                          }}
                        >
                          Pinned
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#published"
                          onClick={() => {
                            setTabs("connections");
                          }}
                        >
                          Connections
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-bs-toggle="tab"
                          href="#scheduled"
                          onClick={() => {
                            setTabs("following");
                          }}
                        >
                          Following
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content mt-20 pb-20">
                    {allNotifications?.length > 0 ? (
                      <div id="all" className="tab-pane fade show active">
                        <div className="row">
                          <InfiniteScroll
                            dataLength={allNotifications?.length}
                            next={() => loadMore()}
                            hasMore={isHasMore}
                            style={{ overflow: "hidden" }}
                          >
                            {allNotifications.map((notification, index) => (
                              <ShowNotification
                                index={index}
                                notification={notification}
                                handleItemClick={handleItemClick}
                              />
                            ))}
                            {/* </ul> */}
                          </InfiniteScroll>
                        </div>
                      </div>
                    ) : !isLoading ? (
                      <div className="d-flex justify-content-center">
                        <h6>No notifications</h6>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3">
              <div className="middle ">
                <Advertisement />

                <div style={{ marginTop: "26px" }}>
                  <Ad />
                  {/* <Npmail/> */}
                  {npmails.length > 0 && (
                    <NpMailNotification notifications={allNotifications} />
                  )}

                  {/* <NpmailNotification/> */}
                </div>
              </div>
              <div className="sidebar-sticky">
                <SidebarFooter isNoBackground={true} />
              </div>
              {/* <div className="sidebar-sticky">
                <NpMailModal isNoBackground={true} />
              </div> */}
            </div>
          </div>
        </div>
        <ToastContainer />
        <Chat />
      </div>
    </>
  );
};

export default Notification;
