import React, { useEffect } from "react";
import SignupPortal from "./SignupPortal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTalentPoolDetails,
  getTalentPoolDetailsByToken,
  register,
} from "../../../store/portal/portalAction";
import { call } from "../../../api/apiCall";
import { toast } from "react-toastify";

const SignupPortalContainer = () => {
  const navigate = useNavigate();
  const [otpModal, setOtpModal] = useState(false);
  const dispatch = useDispatch();
  const { param } = useParams();
  const token = localStorage.getItem("accessTpToken");
  const { talentPoolData, talentPoolDataByToken } = useSelector(
    (state) => state.portal
  );
  const [emailExistance, setEmailExistence] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    dispatch(getTalentPoolDetailsByToken());
    if (token) dispatch(getTalentPoolDetails(param));
  }, []);

  useEffect(() => {
    if (talentPoolData?.id === talentPoolDataByToken?.id)
      navigate(`/tp/portal/${param}/dashboard`);
  });

  const emailExist = (emailName) => {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call(
            "get",
            `api/v1/auth/email-exist/${emailName}?type=tp`,
            null,
            null,
            null
          );
          resolve(true);
        } catch (err) {
          resolve(false);
        }
      })();
    });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleValidation = (type) => {
    let formIsValid = true;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let passwordValid =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

    if (type === "email") {
      if (!formData?.email.trim()) {
        setError({ ...error, email: "Please enter email" });
        formIsValid = false;
      } else if (!emailRegex.test(formData?.email.trim())) {
        setError({
          ...error,
          email: "Please enter your email in a valid format.",
        });
        formIsValid = false;
      } else if (formData?.email.trim()) {
        emailExist(formData?.email).then((response) => {
          if (response === false) {
            setError({
              ...error,
              email: "Email already exists on nobelpage",
            });
            setEmailExistence(false);
            formIsValid = false;
          } else {
            setError({
              ...error,
              email: "",
            });
            setEmailExistence(true);
          }
        });
      } else {
        setError({
          ...error,
          email: "",
        });
        setEmailExistence(true);
      }
    }

    if (type === "all" || type === "password") {
      if (!formData?.password) {
        setError({
          ...error,
          password: "Please enter password",
        });
        formIsValid = false;
      } else if (!passwordValid.test(formData?.password.trim())) {
        setError({
          ...error,
          password:
            'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]',
        });
        formIsValid = false;
      } else {
        setError({
          ...error,
          password: "",
        });
      }
    }

    if (type === "all" || type === "confirmPassword") {
      if (!formData?.confirmPassword) {
        setError({
          ...error,
          confirmPassword: "Please enter confirm password",
        });
        formIsValid = false;
      } else if (formData?.confirmPassword !== formData?.password) {
        setError({
          ...error,
          confirmPassword: "Password should match to confirm password",
        });
        formIsValid = false;
      } else {
        setError({
          ...error,
          confirmPassword: "",
        });
      }
    }

    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation("all") && emailExistance) {
      dispatch(
        register({
          email: formData?.email,
          password: formData?.password,
          id: parseInt(talentPoolData?.id),
        })
      )
        .then((res) => {
          console.log(res, "res");
          if (res?.success) {
            toast.success("Registered successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOtpModal(true);
            // navigate(`/tp/portal/${param}/dashboard`);
          } else alert(res?.message);
        })
        .catch((err) => {
          alert(err?.response?.data?.message);
        });
    }
  };

  return (
    <SignupPortal
      data={talentPoolData}
      param={param}
      navigate={navigate}
      otpModal={otpModal}
      setOtpModal={setOtpModal}
      handleChange={handleChange}
      handleValidation={handleValidation}
      formData={formData}
      emailExistance={emailExistance}
      error={error}
      handleSubmit={handleSubmit}
    />
  );
};

export default SignupPortalContainer;
