import React, { useEffect } from "react";
import Chat from "../../../../pages/chat/chat";
import Navbar from "../../../../pages/navbar/navbar";
import NetworkSidebar from "../../Layout/NetworkSidebar";
import edit from "../../../../assets/img/edit.svg";
import photo from "../../../../assets/img/photo.svg";
import video from "../../../../assets/img/video.svg";
import article from "../../../../assets/img/article.svg";
import news from "../../../../assets/img/news.svg";
import eve from "../../../../assets/img/events.svg";
import Ad from "../../../../core/components/layout/Sidebar/Ad";
import { Insight } from "../../../../core/components/layout/Sidebar/Insight";
import EditPage from "../../../../core/components/layout/Sidebar/EditUrl";
import ellipse3 from "../../../../assets/img/Ellipse3.png";
import nopreview from "../../../../assets/img/no-preview.png";
import pagePlaceholder from "../../../../assets/img/page-placeholder.svg";
import pageAdminIcon1 from "../../../../assets/img/page-admin-icon1.svg";
import productIcon from "../../../../assets/img/productIcon.svg";
import pageAdminIcon2 from "../../../../assets/img/page-admin-icon2.svg";
import pageAdminIcon3 from "../../../../assets/img/page-admin-icon3.svg";
import pageAdminIcon4 from "../../../../assets/img/page-admin-icon4.svg";
import pageAdminIcon5 from "../../../../assets/img/page-admin-icon5.svg";
import pageAdminIcon6 from "../../../../assets/img/page-admin-icon6.svg";
import deleteIconPage from "../../../../assets/img/deleteIconPage.png";
import InviteConnections from "../components/InviteConnections";
import PageAdminsModal from "../components/PageAdminsModal";
import CreatePageModal from "../components/CreatePageModal";
import EventBannerModal from "../../Events/components/eventBannerModal";
import InfiniteScroll from "react-infinite-scroll-component";
import PageViewSidebar from "../../../../core/components/layout/Sidebar/PageViewSidebar";
import PostOnDashboard from "../../../dashboard/components/postComponent/PostOnDashboard";
import ViewReactionModal from "../../../dashboard/components/viewReactionModal";
import CreatePostModal from "../../../dashboard/components/createPost/CreatePostModal";
import { useDispatch, useSelector } from "react-redux";
import ReportModal from "../../../dashboard/components/reportTypeModal";
import PagePeople from "../components/PagePeople";
import CreateArticleModal from "../../Articles/components/createArticle";
import CreateEventModal from "../../Events/components/createEvent";
import CreateNewsletterModal from "../../Newsletter/components/CreateNewsletter";
import PageArticle from "../components/PageArticle";
import PageEvent from "../components/PageEvent";
import PageNewsletter from "../components/PageNewsletter";
import Loader from "../../../../core/components/Loaders/ComponentLoader";
import { useNavigate, useParams } from "react-router-dom";
import "../pages.scss";
import AppLoader from "../../../../core/components/Loaders/appLoader/appLoader";
import ProfilePhotoModal from "../../../profile-pages/profile/components/ProfilePhotoModal";
import ShareModal from "../../../../core/components/sharePost/sharePost";
import { useState } from "react";
import CreateTalentPool from "../../../talentPool/createTalentPool/CreateTalentPool";
import RoleChangeModal from "../../../dashboard/components/RoleChangeModal";
import { ToastContainer, toast } from "react-toastify";
import EditUrl from "../../../../core/components/layout/Sidebar/EditUrl";
import {
  initLoad,
  initPageLoad,
  stopLoad,
  stopPageLoad,
} from "../../../../store/loader/loaderActions";
import { call } from "../../../../api/apiCall";
import { FormGroup, Input } from "reactstrap";
import { UpdatePortal } from "../../../../store/pages/pagesAction";
import SuccessModal from "../../../talentPool/createTalentPool/SuccessModal";
import DeletePage from "../../../../core/components/layout/Sidebar/DeletePage";
import ProductServiceModel from "../../../dashboard/components/ProductServiceModel";
import PageProduct from "../components/PageProduct";
import PageJobs from "../components/PageJobs";
import { useLocation } from "react-router-dom";
import {
  getJobs,
  getJobsDetail,
  jobViewlog,
  updateJobForm,
} from "../../../../store/jobs/jobAction";
import RecruiterModals from "../../../Jobs/Recruiter/RecruiterModals/RecruiterModals";
import DeleteModal from "../../../../core/components/modal/DeleteModal";
import messageIcon from "../../../../assets/img/notification.svg";
import createIcon from "../../../../assets/img/plus-grey.svg";
import reportIcon from "../../../../assets/img/blockIcon.svg";
const PageAdminDetails = (props) => {
  const {
    singlePageDetails,
    handleFollow,
    pageAdminData,
    pagePostDetails,
    invitemodal,
    setInvitemodal,
    pageAdminmodal,
    setPageAdminmodal,
    isEdit,
    setIsEdit,
    setIsPageEdit,
    isPageEdit,
    pageID,
    sendPageInvitation,
    EditPageURL,
    pageNavURL,
    setPageNavURL,
    singlePageDetailsbyURL,
    getPageInsights,
    insightData,
    isPositive,
    setIsPositive,
    currentCount,
    pagePostData,
    suggested_people,
    suggested_jobs,
    suggestedPeoplePage,
    sendRequest,
    isSeeMore,
    toggleSeeMore,
    editPageData,
    setEditPageData,
    createModal,
    setCreateModal,
    uploadIcon,
    handleBannerPicture,
    handleRemovePicture,
    onChangePicture,
    croppedImage,
    uploadImage,
    iconPhotoModal,
    setIconPhotoModal,
    setBannerModal,
    bannerModal,
    setCroppedImage,
    createExploreLink,
    addPageAdminRole,
    getPageAdminDetails,
    deletePageAdminDetail,
    dropDown,
    setDropDown,
    editPost,
    delete_post,
    isSaved,
    setSaved,
    dropRef,
    privacy_type,
    save_post,
    removeSavePost,
    handleLikes,
    handleReaction,
    isComments,
    setIsComments,
    isReaction,
    isViewReaction,
    setViewReaction,
    setPostID,
    pageOwner,
    isPagePosts,
    postViewCounter,
    createPostModal,
    setCreatePostModal,
    updateData,
    postId,
    createPostClick,
    setCreatePostClick,
    isDataAvailable,
    setDataAvailable,
    draftDetails,
    setDraftDetails,
    hideUserPost,
    likeResult,
    commentBoxStatus,
    setCommentBoxStatus,
    dropDownShare,
    setDropDownShare,
    repostPost,
    closeAllModal,
    setUpdateData,
    setReportModal,
    reportModal,
    pageReportModal,
    setPageReportModal,
    articleModal,
    setArticleModal,
    page_articles,
    page_jobs,
    getPageArticles,
    updatedArticlesList,
    updatedJobsList,
    createMarkup,
    eventModal,
    setEventModal,
    getPageEvents,
    page_events,
    pinHandle,
    newsletterModal,
    setNewsletterModal,
    getPageNewsletters,
    page_newsletters,
    user,
    openShareModal,
    setOpenShareModal,
    shareData,
    setShareData,
    pagePermission,
    createTalentModal,
    setCreateTalentModal,
    tpSuccessModal,
    setTpSuccessModal,
    talentPoolData,
    setTalentPoolData,
    roleShareModal,
    setRoleShareModal,
    getPageDetailData,
    setManageFeedStatus,
    manageFeedStatus,
    rolePageId,
    setRolePageId,
    dropDownRepost,
    setDropDownRepost,
    dropRefRepost,
    setSelectedUserId,
    selectedUserId,
    productModel,
    setProductModel,
    getProductList,
    productCount,
    setProductCount,
    productData,
  } = props;

  const navigate = useNavigate();

  const getPermissions = pagePermission?.data;
  // console.log("getPermissions", getPermissions);
  const [updatePageStatus, setUpdatePageStatus] = useState(false);
  const [insightStatus, setInsightStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tabs, setTabs] = useState("");

  useEffect(() => {
    if (getPermissions?.update_page) {
      setUpdatePageStatus(true);
    }
    if (getPermissions?.manage_feed) {
      setManageFeedStatus(true);
    }
    if (getPermissions?.manage_feed) {
      setInsightStatus(true);
    }
  }, [getPermissions]);

  useEffect(() => {
    setIsPositive(Math.sign(insightData?.percentage));
  }, [insightData]);

  const { postData } = useSelector((state) => state.dashboard);
  const { isPageLoading } = useSelector((state) => state.loader);

  const [isSticky, setIsSticky] = useState(false);
  const [editURL, setEditURL] = useState(true);
  const [textURL, setTextURL] = useState(pageNavURL);
  const [pageNavURLStatus, setPageNavURLStatus] = useState("");
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [isEnable, setIsEnable] = useState(true);
  const dispatch = useDispatch();
  let token = window.localStorage.getItem("accessToken");

  useEffect(() => {
    const handleScroll = () => {
      const sidebar = document.querySelector(".page-view-details");
      const sidebarRect = sidebar?.getBoundingClientRect();
      if (sidebarRect?.top + sidebarRect?.height < window.innerHeight) {
        setIsSticky(true);
        sidebar.style.top = `${sidebarRect.top}px`;
      } else setIsSticky(false);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handlePostData = (tab) => {
    if (!isPageLoading) {
      setTabs(tab);
      if (tab === "all") pagePostDetails();
      else if (tab === "page-employee") pagePostDetails(tab);
      else if (tab === "featured") pagePostDetails(tab);
    }
  };

  const checkUrlExist = async () => {
    try {
      dispatch(initLoad());
      const res = await call(
        "get",
        `api/v1/page/url-exist/${textURL}`,
        null,
        null,
        token
      );
      dispatch(stopLoad());

      if (res.data.success === true) {
        setPageNavURLStatus("available");
        // handleSubmit();
      }
      if (res.data.success === false) {
        setPageNavURLStatus("Unavailable");
        setUrlErrorMessage(
          "This page url already exist. Please enter another url"
        );
        setIsEnable(true);
        return;
      }
    } catch (err) {
      setPageNavURLStatus("Unavailable");
      setIsEnable(true);
      console.log(err);
      dispatch(stopLoad());
      return;
    }
  };

  const handleSubmit = () => {
    const data = {
      page_url: textURL,
      page_id: pageID,
    };
    EditPageURL(data);
    setEditURL(!editURL);
    checkUrlExist();
    setPageNavURLStatus("");
    setUrlErrorMessage("");
  };

  const handlePortalActivate = (id, status) => {
    dispatch(initPageLoad());
    dispatch(UpdatePortal(id, { status }))
      .then(() => {
        singlePageDetails();
        toast.success(
          status
            ? "Talent Pool activated successfully."
            : "Talent Pool deactivated successfully.",
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
        dispatch(stopPageLoad());
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message || err?.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        dispatch(stopPageLoad());
      });
  };

  const [reportPage, setReportPage] = useState(false);
  console.log(productData);
  const [showPageJobs, setShowPageJobs] = useState(false);

  //post the job by ram

  const location = useLocation();
  const { state } = useLocation();
  const isPost = location.state?.isPost;
  const [showRecruiterModal, setShowRecruiterModal] = useState(false);
  const { getAllJobs, getFeaturedJobs } = useSelector((state) => state.jobs);
  //const [currentCount, setCurrectCount] = useState(0);
  const [selectedJob, setSelectedJob] = useState();
  // const {
  //   title,
  //   company,
  //   // listImage,
  //   employmentType,
  //   companyLocation,
  //   companyVisible,
  //   companyId,
  //   numberOfVacancies,
  //   workspacetype,
  // } = location.state || {};

  useEffect(() => {
    if (getAllJobs?.length > 0)
      // setSelectedJob(cardData[0]);
      dispatch(getJobsDetail(getAllJobs[0]?.id)).then((res) => {
        try {
          dispatch(jobViewlog(getAllJobs[0]?.id));
        } catch (error) {
          console.log(error);
        }
        setSelectedJob(res?.data);
      });
  }, [getAllJobs]);

  useEffect(() => {
    if (isPost) {
      setShowRecruiterModal(true);
    }
  }, [isPost]);
  const [jobType, setJobType] = useState("");
  const getFilteredJobs = () => {
    setJobType("featured");
    dispatch(
      getJobs(
        { limit: 30, skip: currentCount, filter: "featured" },
        currentCount,
        "featured"
      )
    ).then((res) => {
      // setCardData(res?.data);
      console.log("jobCount");
    });
  };

  useEffect(() => {
    // dispatch(getJobs({ limit: 10, skip: currentCount }, currentCount)).then(
    //   (res) => {
    //     setTotalCount(res?.totalItem);
    //     setIsJobAlert(res?.jobAlert);
    //   }
    // );

    if (state?.isOpenCreatePost) {
      openRecruiterModal();
    }
    if (state?.isFeatured) {
      getFilteredJobs();
    } else {
      setJobType("");
      dispatch(
        getJobs(
          { limit: 10, skip: currentCount, filter: "recommended" },
          currentCount
        )
      ).then((res) => {
        //setTotalCount(res?.totalItem);
        console.log("totalcount");
      });
    }
  }, []);
  const openRecruiterModal = () => {
    setShowRecruiterModal(!showRecruiterModal);
    //dispatch(updateJobForm({ jobType: "new" }));
  };

  //code for page delete

  const [modalOpen, setModalOpen] = useState(false);
  let page_id = useParams();
  let delete_id = Number(page_id.id);
  const toggleModal = () => {
    console.log("modalOpen", modalOpen);
    setModalOpen(!modalOpen); // Toggle modal state
  };
  const handleDelete = async (delete_id, data) => {
    let token = localStorage.getItem("accessToken");
    try {
      // Make API call to delete endpoint using Axios
      const response = await call(
        "delete",
        `api/v1/remove-page/${delete_id}`,
        null,
        data,
        token
      );

      toast.success(response.data.message, {
        position: "bottom-left",
        autoClose: 2000,
      });
      setTimeout(() => {
        navigate("/pages");
      }, 2000);
    } catch (error) {
      toast.error(error, { position: "bottom-left" });
      console.error("Error deleting:", error);
    }
  };

  return (
    <div>
      {isPageLoading && <AppLoader />}
      <Navbar isJobs={false} />
      <div className="main-bg admin-bg ">
        <div className="page-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="wrapper ">
                  <div className="big-img">
                    <img
                      src={
                        pageAdminData?.background_img
                          ? pageAdminData.background_img
                          : null
                      }
                      className="img-cover"
                      alt=""
                    />

                    {isPageEdit || updatePageStatus ? (
                      <div className="edit">
                        <img
                          alt=""
                          src={edit}
                          onClick={() => {
                            try {
                              document.getElementById("chosethefile").click();
                            } catch (error) {
                              console.log(error);
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-lg-8 pe-0">
                      <div className="left ml-20 mb-20">
                        <div className="profile-details">
                          <div className="d-flex gap-3 ">
                            <div className="img">
                              <img
                                src={
                                  croppedImage
                                    ? croppedImage
                                    : pageAdminData?.icon
                                    ? pageAdminData?.icon
                                    : pagePlaceholder
                                }
                                className="image-fit"
                                alt=""
                              />
                              {isPageEdit || updatePageStatus ? (
                                <div className="edit">
                                  <img
                                    alt=""
                                    src={edit}
                                    onClick={() => {
                                      setIconPhotoModal(!iconPhotoModal);
                                    }}
                                  />
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="details mt-30 ">
                              <h3 style={{ color: "#0432a3" }}>
                                {pageAdminData?.name}
                              </h3>
                              {console.log(pageAdminData)}
                              <p>{pageAdminData?.website}</p>
                              <small>
                                {pageAdminData?.category &&
                                  pageAdminData?.category
                                    ?.slice(0, 1)
                                    .toUpperCase() +
                                    pageAdminData?.category?.slice(1) +
                                    " " +
                                    "|" +
                                    " "}
                                {pageAdminData?.industry &&
                                  pageAdminData?.industry + " " + "|" + " "}
                                {pageAdminData?.location &&
                                  pageAdminData?.location + " "}

                                <div>
                                  {pageAdminData?.followers_count > 0 &&
                                  pageAdminData?.followers_count > 999
                                    ? "| " +
                                      `${Math.floor(
                                        pageAdminData?.followers_count / 1000
                                      )}k followers`
                                    : "| " +
                                      `${pageAdminData?.followers_count} ${
                                        pageAdminData?.followers_count > 1
                                          ? "followers"
                                          : "follower"
                                      }`}
                                </div>

                                {/* {pageAdminData?.followers_count > 0 &&
                                pageAdminData?.followers_count > 1
                                  ? "| " +
                                    pageAdminData?.followers_count +
                                    " followers"
                                  : "| " +
                                    pageAdminData?.followers_count +
                                    " follower"} */}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 ps-lg-0 ">
                      <div className="right mr-20 mb-20 ">
                        <div className="float-xl-end">
                          {isPageEdit || updatePageStatus ? (
                            <div className="d-flex gap-3">
                              <div className="dropdown mt-30">
                                <button
                                  className="btn btn-light dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton1"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Admin Tools
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  {getPermissions?.invite_connection && (
                                    <li
                                      className="dropdown-item"
                                      onClick={() => setInvitemodal(true)}
                                    >
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src={pageAdminIcon1}
                                          alt="icon-1"
                                        />
                                        <p>Invite connections</p>
                                      </div>
                                    </li>
                                  )}
                                  {getPermissions?.post_job && (
                                    <li
                                      className="dropdown-item"
                                      onClick={() => openRecruiterModal()}
                                    >
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src={pageAdminIcon2}
                                          alt="icon-1"
                                        />
                                        <p>Post a job</p>
                                      </div>
                                    </li>
                                  )}
                                  {getPermissions?.promote_page && (
                                    <li
                                      className="dropdown-item"
                                      onClick={() =>
                                        navigate("/createcampaign", {
                                          state: { id: pageID, source: "page" },
                                        })
                                      }
                                    >
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src={pageAdminIcon3}
                                          alt="icon-1"
                                        />
                                        <p>Promote page</p>
                                      </div>
                                    </li>
                                  )}
                                  {manageFeedStatus && (
                                    <li
                                      className="dropdown-item"
                                      onClick={() => setEventModal(!eventModal)}
                                    >
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src={pageAdminIcon4}
                                          alt="icon-1"
                                        />
                                        <p>Create an event</p>
                                      </div>
                                    </li>
                                  )}
                                  {getPermissions?.product_service && (
                                    <li
                                      className="dropdown-item"
                                      onClick={() => setProductModel(true)}
                                    >
                                      <div className="d-flex align-items-center gap-3">
                                        <img src={productIcon} alt="icon-1" />
                                        <p>Add Product / Service</p>
                                      </div>
                                    </li>
                                  )}
                                  {getPermissions?.manage_admin && (
                                    <li
                                      className="dropdown-item"
                                      onClick={() => setPageAdminmodal(true)}
                                    >
                                      <div className="d-flex align-items-center gap-3">
                                        <img
                                          src={pageAdminIcon5}
                                          alt="icon-1"
                                        />
                                        <p>Manage admins</p>
                                      </div>
                                    </li>
                                  )}
                                  <li
                                    className="dropdown-item"
                                    onClick={() => navigate("/help")}
                                  >
                                    <div className="d-flex align-items-center gap-3">
                                      <img src={pageAdminIcon6} alt="icon-1" />
                                      <p>View help center</p>
                                    </div>
                                  </li>

                                  <li
                                    className="dropdown-item"
                                    onClick={() => toggleModal()}
                                  >
                                    <div className="d-flex align-items-center gap-3">
                                      <img
                                        src={deleteIconPage}
                                        alt="icon-1"
                                        style={{
                                          height: "31px",
                                          width: "31px",
                                        }}
                                      />
                                      <p>Delete Page</p>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              {updatePageStatus ? (
                                <button
                                  className="btn btn-blue mt-30"
                                  style={{ padding: "10px 16px" }}
                                  onClick={() => {
                                    setCreateModal(true);
                                    setEditPageData(pageAdminData);
                                  }}
                                >
                                  Edit Page
                                </button>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <div className="d-flex gap-3">
                              {pageAdminData?.custom_button ? (
                                <button
                                  className="btn btn-light mt-30"
                                  onClick={() => createExploreLink()}
                                >
                                  Explore more
                                </button>
                              ) : (
                                <></>
                              )}
                              <button
                                className="btn btn-blue mt-30"
                                onClick={() =>
                                  handleFollow(
                                    pageID,
                                    pageAdminData.is_following
                                  )
                                }
                              >
                                {pageAdminData?.is_following
                                  ? "Unfollow"
                                  : "Follow"}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-8 col-md-7 col-sm-6 pe-0">
                      <div className="left ml-20 mb-20">
                        <div className="profile-details ">
                          <div className="list">
                            <ul className="nav nav-tabs gap-0" role="tablist">
                              <li className="nav-item nav-insight-item">
                                <a
                                  className="nav-link active"
                                  data-bs-toggle="tab"
                                  href="#page-post"
                                >
                                  Post
                                </a>
                              </li>
                              <li className="nav-item nav-insight-item">
                                <a
                                  className="nav-link"
                                  data-bs-toggle="tab"
                                  href="#page-people"
                                  onClick={() => {
                                    suggestedPeoplePage();
                                  }}
                                >
                                  People
                                </a>
                              </li>
                              <li className="nav-item nav-insight-item">
                                <a
                                  className="nav-link"
                                  data-bs-toggle="tab"
                                  href="#page-jobs"
                                  onClick={() => {
                                    setShowPageJobs(true);
                                  }}
                                >
                                  Jobs
                                </a>
                              </li>
                              <li className="nav-item nav-insight-item">
                                <a
                                  className="nav-link "
                                  data-bs-toggle="tab"
                                  href="#page-newsletters"
                                  onClick={() => {
                                    getPageNewsletters();
                                  }}
                                >
                                  Newsletter
                                </a>
                              </li>
                              <li className="nav-item nav-insight-item">
                                <a
                                  className="nav-link "
                                  data-bs-toggle="tab"
                                  href="#page-article"
                                  onClick={() => {
                                    getPageArticles();
                                  }}
                                >
                                  Article
                                </a>
                              </li>
                              <li className="nav-item nav-insight-item">
                                <a
                                  className="nav-link "
                                  data-bs-toggle="tab"
                                  href="#page-events"
                                  onClick={() => {
                                    getPageEvents();
                                  }}
                                >
                                  Events
                                </a>
                              </li>
                              <li className="nav-item nav-insight-item">
                                <a
                                  className="nav-link"
                                  data-bs-toggle="tab"
                                  href="#page-product"
                                  onClick={() => {
                                    getProductList();
                                  }}
                                >
                                  Products & Services
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-5 col-sm-6  ps-lg-0">
                      <div className="right mr-20 mb-20">
                        <div className="float-end">
                          <div className="list mt-4 ">
                            {(isPageEdit || getPermissions?.talent_pool) &&
                            updatePageStatus ? (
                              <ul>
                                {pageAdminData?.Talent_Pool ? (
                                  <li className="d-flex gap-2">
                                    <div
                                      onClick={() =>
                                        pageAdminData?.Talent_Pool?.status &&
                                        navigate(
                                          `/tp/${pageAdminData?.Talent_Pool?.id}/dashboard`
                                        )
                                      }
                                    >
                                      Talent Pool
                                    </div>
                                    <FormGroup switch>
                                      <Input
                                        type="switch"
                                        role="switch"
                                        checked={
                                          pageAdminData?.Talent_Pool?.status
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handlePortalActivate(
                                            pageAdminData?.Talent_Pool?.id,
                                            !pageAdminData?.Talent_Pool?.status
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </li>
                                ) : (
                                  <li
                                    onClick={() => setCreateTalentModal(true)}
                                    // onClick={() =>
                                    //   alert(
                                    //     "This functionality will be implemneted in uncomming miletstones"
                                    //   )
                                    // }
                                  >
                                    Create Talent Pool
                                  </li>
                                )}
                                <li
                                  onClick={() => {
                                    setIsPageEdit(false);
                                    setUpdatePageStatus(false);
                                    setManageFeedStatus(false);
                                    setInsightStatus(false);
                                  }}
                                >
                                  View as Member
                                </li>
                              </ul>
                            ) : (
                              <ul>
                                {
                                  // getPermissions?.talent_pool ? (
                                  //   <li
                                  //     onClick={() => setCreateTalentModal(true)}
                                  //     // onClick={() =>
                                  //     //   alert(
                                  //     //     "This functionality will be implemneted in uncomming miletstones"
                                  //     //   )
                                  //     // }
                                  //   >
                                  //     Create Talent Pool
                                  //   </li>
                                  // ) :
                                  pageAdminData?.Talent_Pool?.status && (
                                    <li
                                      onClick={() =>
                                        pageAdminData?.Talent_Pool?.status &&
                                        navigate(
                                          `/tp/portal/${pageAdminData?.Talent_Pool?.id}/login`
                                        )
                                      }
                                    >
                                      Talent Pool
                                    </li>
                                  )
                                }
                                <li>
                                  <div className="dropdown">
                                    <button
                                      className="dropdown-toggle custom-more"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      More
                                    </button>
                                    <ul
                                      className={`dropdown-menu custom-drop`}
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li className="d-flex">
                                        <img src={messageIcon} width={15} />
                                        <a
                                          className="dropdown-item ms-2"
                                          href="#"
                                          onClick={() => {
                                            navigate("/message");
                                          }}
                                        >
                                          Send Message
                                        </a>
                                      </li>
                                      <li className="d-flex">
                                        <img src={reportIcon} width={20} />
                                        <a
                                          className="dropdown-item ms-2"
                                          href="#"
                                          onClick={() => {
                                            // // alert(
                                            // //   "This feature will be implemented in the future milestone"
                                            // // );
                                            // setPageReportModal(true);
                                            setReportPage(true);
                                          }}
                                        >
                                          Report Page
                                        </a>
                                      </li>
                                      <li className="d-flex">
                                        <img src={createIcon} width={20} />
                                        <a
                                          className="dropdown-item ms-2"
                                          href="#"
                                          onClick={() =>
                                            setCreateModal(!createModal)
                                          }
                                        >
                                          Create Page
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </li>

                                {/* {!isPageEdit &&
                                getPermissions?.type !== "Admin" ? (
                                  <li>More</li>
                                ) : (
                                  <></>
                                )} */}
                                {!isPageEdit &&
                                !updatePageStatus &&
                                getPermissions &&
                                (getPermissions?.type === "Admin" ||
                                  getPermissions?.type === "Super Admin") ? (
                                  <li
                                    onClick={() => {
                                      setIsPageEdit(true);
                                      setUpdatePageStatus(true);
                                      setManageFeedStatus(true);
                                    }}
                                  >
                                    View as Admin
                                  </li>
                                ) : (
                                  <></>
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-26">
              <div className="col-lg-3">
                {/* <div className="page-view-details"> */}
                <div
                  className={
                    isSticky
                      ? "page-view-details sidebar-sticky-left"
                      : "page-view-details"
                  }
                >
                  <NetworkSidebar />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="middle" style={{ paddingBottom: "140px" }}>
                  {pageAdminData?.description ? (
                    <div className="bg-white b-5 p-20 mb-3">
                      <div className="about ">
                        <h2 className="text-dark">About</h2>
                        <p className="mt-20">
                          {isSeeMore == true
                            ? pageAdminData?.description.slice(0, 150)
                            : pageAdminData?.description}
                          {pageAdminData?.description?.length > 150 ? (
                            <span className="see" onClick={toggleSeeMore}>
                              {isSeeMore == true ? "...See more" : " See less"}
                            </span>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="tab-content">
                    {/* page post details */}
                    <div className="dashboard  tab-pane active" id="page-post">
                      {isPageEdit || manageFeedStatus ? (
                        <>
                          <div className="bg-white b-5" id="page-post">
                            <div className="msg-box">
                              <div className="type">
                                <div className="profile">
                                  <img
                                    src={
                                      croppedImage
                                        ? croppedImage
                                        : pageAdminData?.icon
                                        ? pageAdminData?.icon
                                        : pagePlaceholder
                                    }
                                    alt=""
                                    className="img-fluid"
                                  />
                                </div>
                                <textarea
                                  id="w3review"
                                  rows="5"
                                  className="curserPointer"
                                  onKeyDown={() => {
                                    setCreatePostModal(!createPostModal);
                                    setIsEdit(0);
                                    setCreatePostClick("");
                                  }}
                                  placeholder="Share your thoughts..."
                                  onClick={() => {
                                    setUpdateData({});
                                    setCreatePostModal(!createPostModal);
                                    setIsEdit(0);
                                    setCreatePostClick("");
                                  }}
                                ></textarea>
                              </div>
                              <div className="social mt-2">
                                <div
                                  className="box"
                                  onClick={() => {
                                    setCreatePostModal(!createPostModal);
                                    setCreatePostClick("photo");
                                  }}
                                >
                                  <img src={photo} alt="" />
                                  <p>Photo</p>
                                </div>
                                <div
                                  className="box"
                                  onClick={() => {
                                    setCreatePostModal(!createPostModal);
                                    setCreatePostClick("video");
                                  }}
                                >
                                  <img src={video} alt="" />
                                  <p>Video</p>
                                </div>
                                <div
                                  className="box"
                                  onClick={() => setArticleModal(!articleModal)}
                                >
                                  <img src={article} alt="" />
                                  <p>Article</p>
                                </div>
                                <div
                                  className="box"
                                  onClick={() => setEventModal(!eventModal)}
                                >
                                  <img src={eve} alt="" />
                                  <p>Event</p>
                                </div>
                                <div
                                  className="box"
                                  onClick={() =>
                                    setNewsletterModal(!newsletterModal)
                                  }
                                >
                                  <img src={news} alt="" />
                                  <p>Newsletter</p>
                                </div>
                                {/* <div className="">
                                  <div className="dots">
                                    <span style={{ fontSize: "11px" }}>
                                      &bull;
                                    </span>
                                    <span style={{ fontSize: "11px" }}>
                                      &bull;
                                    </span>
                                    <span style={{ fontSize: "11px" }}>
                                      &bull;
                                    </span>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="bg-white b-5 mt-3">
                            <div className=" msg-box ">
                              <div className="col-lg-12">
                                <div className="d-flex gap-3">
                                  <div
                                    className={
                                      !tabs || tabs === "all"
                                        ? "btn-active text-white"
                                        : "round btn-light"
                                    }
                                    onClick={() => handlePostData("all")}
                                  >
                                    Page Posts
                                  </div>
                                  <div
                                    className={
                                      tabs === "page-employee"
                                        ? "btn-active text-white"
                                        : "round btn-light"
                                    }
                                    onClick={() =>
                                      handlePostData("page-employee")
                                    }
                                  >
                                    Employee Posts
                                  </div>
                                  <div
                                    className={
                                      tabs === "featured"
                                        ? "btn-active text-white"
                                        : "round btn-light"
                                    }
                                    onClick={() => handlePostData("featured")}
                                  >
                                    Sponsored Posts
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="center">
                        {postData?.data.length > 0 ? (
                          <div className="msg">
                            {postData?.data.map((item, index) => (
                              <PostOnDashboard
                                item={item}
                                index={index}
                                dropDown={dropDown}
                                userId={pageID}
                                editPost={editPost}
                                delete_post={delete_post}
                                isSaved={isSaved}
                                setSaved={setSaved}
                                dropRef={dropRef}
                                privacy_type={privacy_type}
                                save_post={save_post}
                                removeSavePost={removeSavePost}
                                handleLikes={handleLikes}
                                setDropDown={setDropDown}
                                isComments={isComments}
                                setIsComments={setIsComments}
                                isReaction={isReaction}
                                handleReaction={handleReaction}
                                isViewReaction={isViewReaction}
                                setViewReaction={setViewReaction}
                                setPostID={setPostID}
                                userName={pageOwner}
                                isPagePosts={isPagePosts}
                                setReportModal={setReportModal}
                                reportModal={reportModal}
                                hideUserPost={hideUserPost}
                                commentBoxStatus={commentBoxStatus}
                                setCommentBoxStatus={setCommentBoxStatus}
                                dropDownShare={dropDownShare}
                                setDropDownShare={setDropDownShare}
                                repostPost={repostPost}
                                likeResult={likeResult}
                                postViewCounter={postViewCounter}
                                openShareModal={openShareModal}
                                setOpenShareModal={setOpenShareModal}
                                shareData={shareData}
                                setShareData={setShareData}
                                getPermissions={getPermissions}
                                dropDownRepost={dropDownRepost}
                                setDropDownRepost={setDropDownRepost}
                                dropRefRepost={dropRefRepost}
                                roleShareModal={roleShareModal}
                                setRoleShareModal={setRoleShareModal}
                                rolePageId={rolePageId}
                                setRolePageId={setRolePageId}
                                setSelectedUserId={setSelectedUserId}
                                selectedUserId={selectedUserId}
                                user={user}
                              />
                            ))}
                          </div>
                        ) : (
                          <div className="bg-white b-5  mt-20">
                            <h5 className="msg-box">No Posts Available</h5>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* page people details */}
                    <div
                      id="page-people"
                      className="bg-white b-5  mt-1 tab-pane fade"
                    >
                      {suggested_people?.data?.length > 0 ? (
                        <PagePeople
                          suggested_people={suggested_people?.data}
                          sendRequest={sendRequest}
                        />
                      ) : (
                        <h5 className="msg-box">No People Available</h5>
                      )}
                    </div>
                    {/* page jobs details */}
                    {/* <div
                      id="page-jobs"
                      className="bg-white b-5  mt-20 tab-pane fade"
                    >
                      <h5 className="msg-box">No Jobs Available</h5>
                    </div> */}
                    {/* ramChange the code */}
                    <div
                      id="page-jobs"
                      className="bg-white b-5  mt-20 tab-pane fade"
                    >
                      {page_jobs?.length > 0 ? (
                        showPageJobs && (
                          <PageJobs
                            updatedJobsList={updatedJobsList}
                            createMarkup={createMarkup}
                            pageID={pageID}
                          />
                        )
                      ) : (
                        <h5 className="msg-box">No Jobs Available</h5>
                      )}
                    </div>
                    {/* page article details */}
                    <div
                      id="page-article"
                      className="bg-white b-5  mt-20 tab-pane fade"
                    >
                      {page_articles?.length > 0 ? (
                        <PageArticle
                          updatedArticlesList={updatedArticlesList}
                          createMarkup={createMarkup}
                          pageID={pageID}
                        />
                      ) : (
                        <h5 className="msg-box">No Articles Available</h5>
                      )}
                    </div>
                    {/* page event details */}
                    <div
                      id="page-events"
                      className="bg-white b-5  mt-20 tab-pane fade"
                    >
                      {page_events?.length > 0 ? (
                        <PageEvent
                          page_events={page_events}
                          pageID={pageID}
                          pinHandle={pinHandle}
                          pageName={pageAdminData?.name}
                          createdAt="page"
                          pageIcon={pageAdminData?.icon}
                        />
                      ) : (
                        <h5 className="msg-box">No Events Available</h5>
                      )}
                    </div>
                    {/* page newsletters details */}
                    <div
                      id="page-newsletters"
                      className="bg-white b-5  mt-20 tab-pane fade"
                    >
                      {page_newsletters?.length > 0 ? (
                        <PageNewsletter
                          page_newsletters={page_newsletters}
                          createMarkup={createMarkup}
                        />
                      ) : (
                        <h5 className="msg-box">No Newletters Available</h5>
                      )}
                    </div>

                    <div
                      id="page-product"
                      className="bg-white b-5  mt-20 tab-pane fade"
                    >
                      {productData?.length > 0 ? (
                        <PageProduct
                          productData={productData}
                          isPageEdit={isPageEdit}
                          pageID={pageID}
                        />
                      ) : (
                        <h5 className="msg-box">No Product Available</h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                {isPageEdit || insightStatus ? (
                  <>
                    <div className=" mt-lg-0 mt-5">
                      <Insight
                        followers={pageAdminData?.followers_count}
                        getPageInsights={getPageInsights}
                        insightData={insightData}
                        isPositive={isPositive}
                        pageID={pageID}
                      />
                    </div>
                    {updatePageStatus && (
                      <>
                        <div
                          className="mt-20 sidebar-sticky-left"
                          style={{ top: "10px" }}
                        >
                          <EditUrl
                            EditPageURL={EditPageURL}
                            pageID={pageID}
                            pageNavURL={pageNavURL}
                            setPageNavURL={setPageNavURL}
                            singlePageDetailsbyURL={singlePageDetailsbyURL}
                            title="Edit Page URL"
                            staticUrlText="/pageadminview/"
                            handleSubmit={handleSubmit}
                            editURL={editURL}
                            setEditURL={setEditURL}
                            textURL={textURL}
                            setTextURL={setTextURL}
                            pageNavURLStatus={pageNavURLStatus}
                            setPageNavURLStatus={setPageNavURLStatus}
                            urlErrorMessage={urlErrorMessage}
                            setUrlErrorMessage={setUrlErrorMessage}
                            checkUrlExist={checkUrlExist}
                            isEnable={isEnable}
                            setIsEnable={setIsEnable}
                            urlCanEdit={true}
                          />
                          <div></div>
                        </div>
                        {/* <div
                          className="mt-20 sidebar-sticky-left"
                          style={{ top: "10px" }}
                        >
                          <DeletePage
                            // urlCanEdit={true}
                            title="Delete Page"
                          />
                        </div> */}
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div>
                      <Ad />
                      <PageViewSidebar />
                    </div>

                    <div
                      className="mt-20 sidebar-sticky-left"
                      style={{ top: "10px" }}
                    >
                      <EditUrl
                        EditPageURL={EditPageURL}
                        pageID={pageID}
                        pageNavURL={pageNavURL}
                        setPageNavURL={setPageNavURL}
                        singlePageDetailsbyURL={singlePageDetailsbyURL}
                        title="Page URL"
                        staticUrlText="/pagememberview/"
                        handleSubmit={handleSubmit}
                        editURL={editURL}
                        setEditURL={setEditURL}
                        textURL={textURL}
                        setTextURL={setTextURL}
                        pageNavURLStatus={pageNavURLStatus}
                        setPageNavURLStatus={setPageNavURLStatus}
                        urlErrorMessage={urlErrorMessage}
                        setUrlErrorMessage={setUrlErrorMessage}
                        checkUrlExist={checkUrlExist}
                        isEnable={isEnable}
                        setIsEnable={setIsEnable}
                        urlCanEdit={true}
                        updatePageStatus={updatePageStatus}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Chat />
        {createModal && (
          <CreatePageModal
            modal={createModal}
            toggle={() => setCreateModal(!createModal)}
            editPageData={editPageData}
            setEditPageData={setEditPageData}
            singlePageDetails={singlePageDetails}
          />
        )}

        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          id="choseiconfile"
          onChange={onChangePicture}
          onClick={(e) => (e.target.value = "")}
        />
        <input
          type="file"
          accept="image/png, image/gif, image/jpeg"
          id="chosethefile"
          onChangeCapture={(e) => {
            uploadIcon(e, "backgroundImg");
          }}
        />
      </div>

      {invitemodal && (
        <InviteConnections
          modal={invitemodal}
          toggle={() => setInvitemodal(!invitemodal)}
          title="Invite Connections from NobelPage"
          pageID={pageID}
          sendPageInvitation={sendPageInvitation}
        />
      )}
      {pageAdminmodal && (
        <PageAdminsModal
          modal={pageAdminmodal}
          toggle={() => setPageAdminmodal(!pageAdminmodal)}
          title="Page Admins"
          addPageAdminRole={addPageAdminRole}
          pageID={pageID}
          getPageAdminDetails={getPageAdminDetails}
          deletePageAdminDetail={deletePageAdminDetail}
          getPermissions={getPermissions}
        />
      )}
      {bannerModal && (
        <EventBannerModal
          modal={bannerModal}
          toggle={() => setBannerModal(!bannerModal)}
          title="Edit Banner"
          uploadImage={uploadImage}
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          handleBannerPicture={handleBannerPicture}
          setBannerModal={setBannerModal}
        />
      )}
      {createPostModal && (
        <CreatePostModal
          modal={createPostModal}
          toggle={() => {
            setCreatePostModal(!createPostModal);
          }}
          toggleOff={() => {
            setCreatePostModal(!createPostModal);
          }}
          title="Create Post"
          userName={pageAdminData?.name}
          userId={pageID}
          createPostClick={createPostClick}
          closeAllModal={closeAllModal}
          updateData={updateData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          isDataAvailable={isDataAvailable}
          setDataAvailable={setDataAvailable}
          draftDetails={draftDetails}
          setDraftDetails={setDraftDetails}
          isPagePosts={isPagePosts}
          dashboardData={pageAdminData?.icon}
          setCommentBoxStatus={setCommentBoxStatus}
        />
      )}
      {isViewReaction && (
        <ViewReactionModal
          modal={isViewReaction}
          toggle={() => setViewReaction(!isViewReaction)}
          title="View Reactions"
          userName={pageOwner}
          postId={postId}
        />
      )}
      {reportModal && (
        <ReportModal
          modal={reportModal}
          toggle={() => setReportModal(!reportModal)}
          post_id={postId}
        />
      )}
      {pageReportModal && (
        <ReportModal
          modal={pageReportModal}
          toggle={() => setPageReportModal(!pageReportModal)}
          reportType={"page"}
          reportTypeId={pageID}
        />
      )}
      {articleModal && (
        <CreateArticleModal
          modal={articleModal}
          toggle={() => setArticleModal(!articleModal)}
          title="Create Article"
          source="home"
          pageName={pageAdminData?.name}
          createdAt="page"
          pageID={pageID}
          pageIcon={pageAdminData?.icon}
        />
      )}

      {eventModal && (
        <CreateEventModal
          modal={eventModal}
          toggle={() => setEventModal(!eventModal)}
          title="Create Event"
          source="home"
          pageName={pageAdminData?.name}
          createdAt="page"
          pageID={pageID}
          pageIcon={pageAdminData?.icon}
        />
      )}

      {showRecruiterModal && (
        <RecruiterModals
          toggle={() => openRecruiterModal()}
          isOpen={showRecruiterModal}
          //title={title}
          isPageJobs={true}
          pageID={pageID}
          pageName={pageAdminData?.name}
          pageIcon={pageAdminData?.icon}
        />
      )}

      {newsletterModal && (
        <CreateNewsletterModal
          modal={newsletterModal}
          toggle={() => setNewsletterModal(!newsletterModal)}
          title="Create Newsletter"
          source="home"
          pageName={pageAdminData?.name}
          createdAt="page"
          pageID={pageID}
          pageIcon={pageAdminData?.icon}
        />
      )}
      {iconPhotoModal && (
        <ProfilePhotoModal
          modal={iconPhotoModal}
          toggle={() => setIconPhotoModal(!iconPhotoModal)}
          title="Page Icon"
          editProfileImage={croppedImage ? croppedImage : pageAdminData?.icon}
          handleBannerPicture={handleBannerPicture}
          handleRemovePicture={handleRemovePicture}
          // setBannerModal={setBannerModal}
        />
      )}

      {openShareModal && (
        <ShareModal
          modal={openShareModal}
          toggle={() => setOpenShareModal(!openShareModal)}
          title="Share"
          shareData={shareData}
          type="shared_post"
          source="dashboard"
          createdAt="page"
          toggleSuccessful={() => setOpenShareModal(!openShareModal)}
        />
      )}

      {roleShareModal && (
        <RoleChangeModal
          modal={roleShareModal}
          toggle={() => setRoleShareModal(!roleShareModal)}
          title="Post, like, comment and share "
          postId={postId}
          getPageDetailData={getPageDetailData}
          rolePageId={rolePageId}
          setSelectedUserId={setSelectedUserId}
          isPagePosts={isPagePosts}
        />
      )}
      {createTalentModal && (
        <CreateTalentPool
          modal={createTalentModal}
          toggle={() => setCreateTalentModal(!createTalentModal)}
          successModal={tpSuccessModal}
          setSuccessModal={setTpSuccessModal}
          setTalentPoolData={setTalentPoolData}
          title="Create Talent Pool"
          pageDetail={pageAdminData}
          singlePageDetails={singlePageDetails}
          handlePortalActivate={handlePortalActivate}
        />
      )}
      {tpSuccessModal && (
        <SuccessModal
          modal={tpSuccessModal}
          toggle={() => setTpSuccessModal(!tpSuccessModal)}
          title="Talent Pool was created successfully"
          talentPoolData={talentPoolData}
          singlePageDetails={singlePageDetails}
          sAdminEmail={pageAdminData?.Created_by?.email}
        />
      )}

      {reportPage && (
        <ReportModal
          modal={reportPage}
          toggle={() => setReportPage(!reportPage)}
          reportType={"page"}
          reportTypeId={pageID}
        />
      )}
      {productModel && (
        <ProductServiceModel
          modal={productModel}
          toggle={() => setProductModel(!productModel)}
          page_id={pageID}
          isPageEdit={isPageEdit}
          productModel={productModel}
          setProductModel={setProductModel}
        />
      )}
      <DeleteModal
        modal={modalOpen}
        toggle={toggleModal}
        title="Delete"
        text="Are you sure you want to delete?"
        deleteConfirm={() => handleDelete(delete_id)} // Placeholder function for delete confirmation
      />

      <ToastContainer />
    </div>
  );
};

export default PageAdminDetails;
