import React from "react";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import back from "../../../assets/img/backArrow.svg";
import plus from "../../../assets/img/plus-orange.svg";
import EditorBox from "../../Jobs/PostJob/components/JobEditor";
import { useState } from "react";
import { useEffect } from "react";
import JobPreview from "../../Jobs/PostJob/components/JobPreview";
import Autocomplete from "react-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import { createJobPostAction } from "../../../store/jobs/jobAction";
import { toast } from "react-toastify";

const AddJobDetailModal = (props) => {
  const {
    modal,
    close,
    toggle,
    title,
    formData,
    setPostJobModal,
    setSuccessfulModal,
    tab,
    setGetJobs,
    createdJobData,
    setCreatedJobData,
  } = props;
  const dispatch = useDispatch();
  const { portal } = useSelector((state) => state.talentPool);
  const [convertedContent, setConvertedContent] = useState();
  const [jobDescription, setJobDescription] = useState();
  const [dbFormat, setDbFormat] = useState();
  const [showAddInput, setShowAddInput] = useState();
  const [selected, setSelected] = useState([]);
  const [skillsError, setSkillsError] = useState("");
  const [skillsLengthError, setSkillsLengthError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [selectedMinExperience, setSelectedMinExperience] = useState(0);
  const [selectedMaxExperience, setSelectedMaxExperience] = useState(0);
  const [experienceError, setExperienceError] = useState("");
  const [minexperienceError, setMinExperienceError] = useState("");
  const [maxExperienceError, setMaxExperienceError] = useState("");
  const [jobDescriptionError, setJobdDescriptionError] = useState("");
  const [salaryError, setSalaryError] = useState("");
  const [minsalaryError, setMinsalaryError] = useState("");
  const [input, setInput] = useState("");
  const [locationOpen, setLocationOpen] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedMinExp, setSelectedMinExp] = useState("");
  const [selectedMaxExp, setSelectedMaxExp] = useState("");
  const [selectedMinSalary, setSelectedMinSalary] = useState("");
  const [selectedMaxSalary, setSelectedMaxSalary] = useState("");
  const [selectedMinSalaryLabel, setSelectedMinSalaryLabel] = useState("");
  const [selectedMaxSalaryLabel, setSelectedMaxSalaryLabel] = useState("");
  const [addSkillsError, setAddSkillsError] = useState("");
  const [pageDetails, setPageDetails] = useState({
    location: searchFieldValue,
  });
  const [previewModal, setPreviewModal] = useState("");

  useEffect(() => {
    if (selected.length > 0) {
      setSkillsError("");
    }
  }, [selected]);

  const handleKeyDown = (event) => {
    const inputValue = event.target.value.trim();
    if (event.key === "Enter" && inputValue != "") {
      console.log("selected", selected);
      if (selected.length < 10) {
        if (inputValue.length <= 100) {
          // Check if the input length is within 100 characters
          setSelected((selected) => [...selected, inputValue]);
          setInput("");
          setShowAddInput(false);
          setSkillsLengthError("");
        } else {
          setShowAddInput(false);
          setSkillsLengthError("Skill must not exceed 100 characters.");
          setInput("");
        }
      } else {
        setShowAddInput(false);
        setSkillsLengthError("Cannot add more than 10 skills");
        setInput("");
      }
    }
  };

  const removeItem = (item) => {
    setSelected((current) => current.filter((selected) => selected !== item));
    if (selected.length < 11) {
      setSkillsLengthError("");
    }
  };

  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const handleMinExperienceChange = (e) => {
    setSelectedMinExperience(e.target.value);
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    const selectedMinExp = parseInt(e.target.value, 10);
    const selectedMaxExp = parseInt(selectedMaxExperience, 10);

    if (selectedMaxExperience) {
      if (selectedMaxExp < selectedMinExp) {
        setExperienceError("Max experience level should be greater than Min");
      } else {
        setExperienceError("");
      }
    }
    setSelectedMinExp(selectedLabel);
    setMinExperienceError("");
  };

  const handleMaxExperienceChange = (e) => {
    setMaxExperienceError("");
    setExperienceError("");
    setSelectedMaxExperience(e.target.value);
    // handleValidation();
    const selectedMinExp = parseInt(selectedMinExperience, 10);
    const selectedMaxExp = parseInt(e.target.value, 10);

    if (selectedMaxExp < selectedMinExp) {
      setExperienceError("Max experience level should be greater than Min");
    } else {
      setExperienceError("");
    }
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    setSelectedMaxExp(selectedLabel);
  };

  const handleMinSalaryChange = (e) => {
    setSelectedMinSalary(e.target.value);
    setSalaryError("");
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    setSelectedMinSalaryLabel(selectedLabel);
    const selectedMin = parseInt(e.target.value, 10);
    const selectedMax = parseInt(selectedMaxSalary, 10);

    if (selectedMax < selectedMin) {
      setSalaryError(
        "Max salary should be greater than or equal to Min salary"
      );
      console.log(salaryError);
    } else {
      setSalaryError("");
    }
    setMinsalaryError("");
  };

  const handleMaxSalaryChange = (e) => {
    setSalaryError("");
    setSelectedMaxSalary(e.target.value);

    const selectedMin = parseInt(selectedMinSalary, 10);
    const selectedMax = parseInt(e.target.value, 10);

    if (selectedMax < selectedMin) {
      setSalaryError(
        "Max salary should be greater than or equal to Min salary"
      );
      console.log(salaryError);
    } else {
      setSalaryError("");
    }
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    setSelectedMaxSalaryLabel(selectedLabel);
  };

  const descriptionRegex = /^[a-zA-Z0-9\s]+$/;

  const handleValidation = () => {
    let isValid = true;

    if (selected.length === 0) {
      setSkillsError("Please add at least one skill.");
      isValid = false;
    } else {
      setSkillsError("");
    }

    if (!selectedLocation) {
      setLocationError("Location must not be empty.");
      isValid = false;
    } else {
      setLocationError("");
    }

    if (!selectedMinExperience) {
      setMinExperienceError("Please select a minimum experience level.");
      isValid = false;
      return;
    } else {
      setMinExperienceError("");
    }

    if (!selectedMaxExperience) {
      setMaxExperienceError("Please select a maximum experience level.");
      isValid = false;
      return;
    } else {
      setMaxExperienceError("");
    }
    if (!selectedMinSalary || selectedMinSalary === "") {
      setMinsalaryError("Please select a minimum  salary level.");
      isValid = false;
      return;
    } else {
      setMinsalaryError("");
    }

    if (!selectedMaxSalary || selectedMaxSalary === "") {
      setSalaryError("Please select a maximum  salary level.");
      isValid = false;
      return;
    } else {
      setSalaryError("");
    }

    // Validate add skills
    if (selected.length === 0) {
      setAddSkillsError("Please add at least one skill.");
      isValid = false;
    } else {
      setAddSkillsError("");
    }

    return isValid;
  };

  // const handleContinue = () => {
  //   setSuccessfulModal(true);
  //   toggle();
  // };

  const collectFormData = () => {
    const data = {
      title: formData?.jobTitle,
      company_name: portal?.company_name,
      company_id: portal?.company_id,
      company_location: formData?.jobCompanyLocation,
      company_visible: true,
      vacancies: formData?.jobNumberOfVacancies,
      workspace_type: formData?.jobWorkspacetype,
      employment_type: formData?.jobEmploymentType,
      description: convertedContent,
      skills: selected,
      job_location: selectedLocation,
      wp_job: false,
      min_experience: selectedMinExperience,
      max_experience: selectedMaxExperience,
      min_salary: selectedMinSalary,
      max_salary: selectedMaxSalary,
      // cover_letter: coverLetterSelected,
    };

    let error = "";
    if (!handleValidation()) return { data, error: "Job Validation Error" };

    // if (selectedApplyOption === "smartApply") {
    //   data.apply_smart = true;
    //   let smart_apply_questions = [];
    //   for (let i = 0; i < jobscreeningQuestions.length; i++) {
    //     let {
    //       questions,
    //       ideal_type,
    //       ideal_value,
    //       required,
    //       dynamic_key,
    //       dynamic_value,
    //     } = jobscreeningQuestions[i];

    //     if (dynamic_key && !dynamic_value) {
    //       error = `Please select ${dynamic_key}`;
    //       break;
    //     }

    //     if (!questions) {
    //       error = `No.${i + 1} screening question is not valid`;
    //       break;
    //     }
    //     if (ideal_type == typeof ideal_value) {
    //       error = `Please select valid Ideal Answer.`;
    //       break;
    //     }

    //     if (dynamic_key) {
    //       questions = questions?.replace(`[${dynamic_key}]`, dynamic_value);
    //     }

    //     smart_apply_questions.push({
    //       question: questions,
    //       ideal_type,
    //       ideal_value,
    //       required,
    //     });
    //   }

    //   data.smart_apply_questions = smart_apply_questions;

    //   if (data.smart_apply_questions?.length < jobscreeningQuestions?.length) {
    //     return { data: false, error };
    //   }
    //   if (data.smart_apply_questions?.length < 3) {
    //     return { data: false, error: "Minimum 3 screening questions required" };
    //   }
    // } else {
    //   data.apply_smart = false;
    //   if (
    //     !/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
    //       externalLink
    //     )
    //   )
    //     return { data: false, error: "External link is not valid" };
    //   data.apply_link = externalLink;
    // }
    return { data, error: "" };
  };

  const handlePostJob = () => {
    const { data, error } = collectFormData();
    // setScreeningError(error);
    if (data && !error) {
      dispatch(createJobPostAction(data))
        .then((data) => {
          if (data.success) {
            toast.success("Job Created Successfully! Redirecting...", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            if (tab == "home") setGetJobs(new Date().getTime());
            setCreatedJobData(data.data);
            close();
          }
        })
        .catch((e) => {});
    } else
      toast.error(error, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  return (
    <Modal
      isOpen={modal}
      style={{ width: "870px" }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="add-job-detail">
        <ModalHeader toggle={close}>
          <img
            src={back}
            alt="go-back"
            className="me-3 curserPointer"
            onClick={() => {
              setPostJobModal(true);
              toggle();
            }}
          />
          {title}
        </ModalHeader>
        <ModalBody>
          <div className="p-4 sub-heading">Add a job description</div>
          <div className="job-editor">
            {/* <EditorBox
              convertedContent={convertedContent}
              setConvertedContent={setConvertedContent}
              dbFormat={dbFormat}
              setDbFormat={setDbFormat}
            /> */}

            <EditorBox
              convertedContent={convertedContent}
              setConvertedContent={setConvertedContent}
              dbFormat={dbFormat}
              setDbFormat={setDbFormat}
              value={jobDescription}
              onChange={(e) => {
                const trimmedValue = e.target.value.trim();
                setJobDescription(trimmedValue);
              }}
            />
            {jobDescriptionError && (
              <div className="error-message" style={{ color: "red" }}>
                {jobDescriptionError}
              </div>
            )}
          </div>
          <div className="px-4 pt-4 pb-2 sub-heading mt-10">Add skills</div>
          <span className="px-4 sub-content">
            Add skill keywords to make your job more visible to the right
            candidates (Select up to 10)
          </span>

          <div className="d-flex mx-4">
            <div className="d-flex" style={{ flexWrap: "wrap", gap: "8px" }}>
              {selected.length > 0 &&
                selected.map((item, index) => (
                  <div
                    key={index}
                    className="add-skills selectRecptRow"
                    style={{
                      maxWidth: "100%",
                      border: `1px solid ${portal?.theme}`,
                      backgroundColor: portal?.theme,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "hidden",
                        gap: "10px",
                      }}
                    >
                      <p
                        style={{
                          margin: "0",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: "670px",
                        }}
                      >
                        {item}
                      </p>
                      <span
                        onClick={() => removeItem(item)}
                        className="curserPointer mb-1 remove-icon"
                        style={{ marginTop: "4px" }}
                      >
                        X
                      </span>
                    </div>
                  </div>
                ))}
              {showAddInput ? (
                <>
                  <input
                    autoFocus
                    value={input}
                    className="receipientInput form-control"
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e)}
                    onBlur={() => setShowAddInput(false)}
                    style={{
                      width: "100%",
                      border: `1px solid ${portal?.theme}`,
                      color: portal?.theme,
                    }}
                  />
                </>
              ) : (
                <div
                  // className="add-skills"
                  style={{
                    background: "#fff",
                    // border: `1px solid ${portal?.theme}`,
                    borderRadius: "40px",
                    color: "#f68a18",
                    display: "flex",
                    fontSize: "15px",
                    fontWeight: 400,
                    justifyContent: "space-between",
                    lineHeight: "22px",
                    minWidth: "60px",
                    padding: "4px 10px",
                    marginRight: "10px",
                    height: "33px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowAddInput(true)}
                >
                  <div
                    // className="add-skills-recruiter-btn"
                    style={{
                      cursor: "pointer",
                      height: "33px",
                      marginTop: "11px",
                      marginRight: "5px",
                      fontSize: "15px",
                      fontWeight: 400,
                      padding: "5px 10px 5px 10px",
                      borderRadius: "20px",
                      color: portal?.theme,
                      borderRadius: "20px",
                      border: `1px solid ${portal?.theme}`,
                    }}
                  >
                    Add skill +
                  </div>
                </div>
              )}
            </div>
          </div>
          {skillsError && (
            <div
              className="error-message"
              style={{
                color: "red",
                marginLeft: "24px",
                marginTop: "10px",
              }}
            >
              {skillsError}
            </div>
          )}
          {skillsLengthError && (
            <div
              className="error-message"
              style={{
                color: "red",
                marginLeft: "24px",
                marginTop: "10px",
              }}
            >
              {skillsLengthError}
            </div>
          )}
          <div className="location px-4 pt-4 pb-2">
            <div className=" sub-heading">Job Location</div>
            <div className="autocompletejob-wrapper1">
              <Autocomplete
                open={locationOpen}
                value={searchFieldValue}
                items={locationsAPIData}
                getItemValue={(item) => item.name}
                shouldItemRender={() => locationsAPIData}
                renderMenu={(items, value) => (
                  <div className="dropdown" style={{ width: "54%" }}>
                    {items.length === 0 ? (
                      <span style={{ color: "red" }}>
                        No matches for {value}
                      </span>
                    ) : (
                      items
                    )}
                  </div>
                )}
                renderItem={(item, isHighlighted) => (
                  <div
                    className={`item ${isHighlighted ? "selected-item" : ""}`}
                  >
                    {item.name}
                  </div>
                )}
                onChange={(e, val) => {
                  setSelectedLocation("");
                  if (val.length === 0) {
                    if (locationOpen) setLocationOpen(false);
                  } else {
                    if (!locationOpen) setLocationOpen(true);
                  }
                  setSearchFieldValue(e.target.value);
                  // handleValidation();
                  getLocations(e.target);
                  setLocationError("");
                }}
                onSelect={(value, obj) => {
                  setSearchFieldValue(value);
                  setPageDetails({
                    ...pageDetails,
                    location: obj.name,
                  });
                  setSelectedLocation(value);
                  setLocationOpen(false);
                  setLocationError("");
                }}
                wrapperStyle={{}}
              />
            </div>
            {locationError && (
              <div className="error-message" style={{ color: "red" }}>
                {locationError}
              </div>
            )}
          </div>
          <div className="row p-4">
            <div className="sub-heading">Experience</div>
            <div className="col-md-4">
              <div>
                <label className="my-2">Min experience</label>
                <Input
                  type="select"
                  onChange={handleMinExperienceChange}
                  value={selectedMinExperience}
                >
                  <option value="0" disabled selected>
                    Select min experience
                  </option>
                  <option value="1">Fresher</option>
                  <option value="2">1 year</option>
                  <option value="3">2 years</option>
                  <option value="4">3 years</option>
                  <option value="5">4 years</option>
                  <option value="6">5 years</option>
                  <option value="7">6 years</option>
                  <option value="8">7 years</option>
                  <option value="9">8 years</option>
                  <option value="10">9 years</option>
                  <option value="11">10 years</option>
                  <option value="12">11 years</option>
                  <option value="13">12 years</option>
                  <option value="14">13 years</option>
                  <option value="15">14 years</option>
                  <option value="16">15 years</option>
                </Input>
              </div>
              {minexperienceError && (
                <div className="error-message" style={{ color: "red" }}>
                  {minexperienceError}
                </div>
              )}
            </div>
            <div className="col-md-4">
              <div>
                <label className="my-2">Max experience</label>
                <Input
                  type="select"
                  onChange={(e) => handleMaxExperienceChange(e)}
                  value={selectedMaxExperience}
                  // onBlur={()=>handleValidation()}
                >
                  <option value="0" disabled selected>
                    Select max experience
                  </option>
                  <option value="1">1 year</option>
                  <option value="2">2 years</option>
                  <option value="3">3 years</option>
                  <option value="4">4 years</option>
                  <option value="5">5 years</option>
                  <option value="6">6 years</option>
                  <option value="7">7 years</option>
                  <option value="8">8 years</option>
                  <option value="9">9 years</option>
                  <option value="10">10 years</option>
                  <option value="11">11 years</option>
                  <option value="12">12 years</option>
                  <option value="13">13 years</option>
                  <option value="14">14 years</option>
                  <option value="15">15 years</option>
                </Input>
              </div>
              {maxExperienceError && (
                <div className="error-message" style={{ color: "red" }}>
                  {maxExperienceError}
                </div>
              )}
              {experienceError && (
                <div className="error-message" style={{ color: "red" }}>
                  {experienceError}
                </div>
              )}
            </div>
            <div className="col-md-4"></div>
            <div className="row mt-3">
              <div className="sub-heading">Salary</div>
            </div>
            <div className="col-md-4">
              <div>
                <label className="my-2">Min salary</label>
                <Input
                  type="select"
                  // onChange={handleMinSalaryChange,handleValidation()}
                  onChange={(e) => {
                    handleMinSalaryChange(e);
                  }}
                  value={selectedMinSalary}
                >
                  <option value="" disabled selected>
                    Select min salary
                  </option>
                  <option value="100">$100</option>
                  <option value="200">$200</option>
                  <option value="300">$300</option>
                  <option value="400">$400</option>
                  <option value="500">$500</option>
                  <option value="600">$600</option>
                  <option value="700">$700</option>
                  <option value="800">$800</option>
                  <option value="900">$900</option>
                  <option value="1000">$1000</option>
                  <option value="1500">$1500</option>
                  <option value="2000">$2000</option>
                  <option value="2500">$2500</option>
                  <option value="3000">$3000</option>
                  <option value="3500">$3500</option>
                  <option value="4000">$4000</option>
                  <option value="4500">$4500</option>
                  <option value="5000">$5000</option>
                  <option value="5500">$5500</option>
                  <option value="6000">$6000</option>
                  <option value="6500">$6500</option>
                  <option value="7000">$7000</option>
                  <option value="7500">$7500</option>
                  <option value="8000">$8000</option>
                  <option value="8500">$8500</option>
                  <option value="9000">$9000</option>
                  <option value="9500">$9500</option>
                </Input>
              </div>
              {minsalaryError && (
                <div className="error-message" style={{ color: "red" }}>
                  {minsalaryError}
                </div>
              )}
            </div>
            <div className="col-md-4">
              <div>
                <label className="my-2">Max salary</label>
                <Input
                  type="select"
                  onChange={handleMaxSalaryChange}
                  value={selectedMaxSalary}
                >
                  <option value="" disabled selected>
                    Select max salary
                  </option>
                  <option value="10000">$10000</option>
                  <option value="15000">$15000</option>
                  <option value="20000">$20000</option>
                  <option value="25000">$25000</option>
                  <option value="30000">$30000</option>
                  <option value="35000">$35000</option>
                  <option value="40000">$40000</option>
                  <option value="45000">$45000</option>
                  <option value="50000">$50000</option>
                  <option value="55000">$55000</option>
                  <option value="60000">$60000</option>
                  <option value="65000">$65000</option>
                  <option value="70000">$70000</option>
                  <option value="75000">$75000</option>
                  <option value="80000">$80000</option>
                  <option value="85000">$85000</option>
                  <option value="90000">$90000</option>
                  <option value="95000">$95000</option>
                  <option value="100000">$100000</option>
                  <option value="150000">$150000</option>
                  <option value="200000">$200000</option>
                  <option value="250000">$250000</option>
                  <option value="300000">$300000</option>
                  <option value="350000">$350000</option>
                  <option value="400000">$400000</option>
                  <option value="450000">$450000</option>
                  <option value="500000">$500000</option>
                </Input>
              </div>
              {salaryError && (
                <div className="error-message" style={{ color: "red" }}>
                  {salaryError}
                </div>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center p-4">
            <span
              className="fw-bold preview"
              style={{ color: portal?.theme }}
              onClick={() => setPreviewModal(!previewModal)}
            >
              Preview
            </span>
            <div
              className="btn"
              style={{
                background: portal?.theme,
                border: `1px solid ${portal?.theme}`,
                color: "#fff",
              }}
              onClick={() => handlePostJob()}
            >
              Continue
            </div>
          </div>
        </ModalBody>
      </div>
      {previewModal && (
        // <JobPreview
        //   modal={previewModal}
        //   toggle={() => setPreviewModal(!previewModal)}
        //   isTallentPool={true}
        // />

        <JobPreview
          title={formData?.jobTitle}
          company_location={selectedLocation}
          workspace_type={formData?.jobWorkspacetype}
          employment_type={formData?.jobEmploymentType}
          vacancies={formData?.jobNumberOfVacancies}
          company={portal?.company_name}
          listImage={portal?.icon}
          company_visible={true}
          selectedMinExp={selectedMinExp}
          selectedMaxExp={selectedMaxExp}
          selectedMinSalaryLabel={selectedMinSalaryLabel}
          selectedMaxSalaryLabel={selectedMaxSalaryLabel}
          description={convertedContent}
          addSkills={selected}
          // experience={selectedExperience}
          // jobType={selectedJobType}
          // salary={selectedSalary}
          modal={previewModal}
          handlePostJob={handlePostJob}
          toggle={() => setPreviewModal(!previewModal)}
          isTallentPool={true}
        />
      )}
    </Modal>
  );
};

export default AddJobDetailModal;
