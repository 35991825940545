import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { verifyPortalSignup } from "../../../store/portal/portalAction";
import { useNavigate, useParams } from "react-router-dom";

const OtpEmail = (props) => {
  const {
    modal,
    toggle,
    title,
    data,
    setOtpModal,
    email,
    loginWithNp,
    setLoginWithNp,
    handleSubmit,
    handleNPSubmit,
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { param } = useParams();

  const [otp1, setOtp1] = useState();
  const [otp2, setOtp2] = useState();
  const [otp3, setOtp3] = useState();
  const [otp4, setOtp4] = useState();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const combineOtp = () => {
    setOtp(otp1 + otp2 + otp3 + otp4);
  };

  useEffect(() => {
    combineOtp();
    setError("");
  }, [otp1, otp2, otp3, otp4]);

  const handleChange = (value1, event) => {
    if (value1 === "otp1") {
      setOtp1(event.target.value);
    }
    if (value1 === "otp2") {
      setOtp2(event.target.value);
    }
    if (value1 === "otp3") {
      setOtp3(event.target.value);
    }
    if (value1 === "otp4") {
      setOtp4(event.target.value);
    }
  };

  const inputfocus = (elmnt) => {
    console.log("elmnt.target", elmnt.target);
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.previousElementSibling.focus();
      }
    } else {
      console.log("next");

      const next = elmnt.target.tabIndex;
      if (next < 4) {
        elmnt.target.nextElementSibling.focus();
      }
    }
  };
  const verifyOtp = () => {
    if (!otp || otp.length < 4) {
      setError("Please enter otp");
      return;
    }
    let otpData = {
      email: email,
      otp: otp,
      id: param,
    };
    dispatch(verifyPortalSignup(otpData)).then((res) => {
      if (res.success) {
        if (loginWithNp) {
          setLoginWithNp(false);
          handleNPSubmit();
        } else handleSubmit();
        // setTimeout(() => {
        //   navigate(`/tp/portal/${param}/dashboard`);
        // }, 1500);
        setOtpModal(false);
      } else setError(res.message);
    });
  };
  return (
    <div>
      <Modal
        isOpen={modal}
        // style={{ width: "394px", height: "276px" }}
        style={{ width: "464px", left: "17%", top: "10%" }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="verify-email">
          <ModalHeader toggle={toggle} className="text-dark">
            {title}
          </ModalHeader>
          <ModalBody>
            <p className="text-center">
              A verification code has sent to your email
            </p>
            <div
              id="otp"
              class="inputs d-flex flex-row justify-content-center mt-4"
            >
              <input
                class="m-2 text-center form-control rounded"
                id="otp1"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otp1}
                onChange={(e) => handleChange("otp1", e)}
                tabIndex="1"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                class="m-2 text-center form-control rounded"
                id="otp2"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otp2}
                onChange={(e) => handleChange("otp2", e)}
                tabIndex="2"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                class="m-2 text-center form-control rounded"
                id="otp3"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otp3}
                onChange={(e) => handleChange("otp3", e)}
                tabIndex="3"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                class="m-2 text-center form-control rounded"
                id="otp4"
                type="text"
                autoComplete="off"
                className="otpInput"
                value={otp4}
                onChange={(e) => handleChange("otp4", e)}
                tabIndex="4"
                maxLength="1"
                onKeyUp={(e) => inputfocus(e)}
              />
            </div>
            {error && (
              <label className="label-input100 text-danger mt-3 d-flex justify-content-center">
                {error}
              </label>
            )}
            <div className="text-center mt-5">
              <button
                type="button"
                className="btn btn-orange"
                style={{ background: `${data?.theme}` }}
                onClick={() => {
                  combineOtp();
                  verifyOtp();
                }}
              >
                Verify
              </button>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
};

export default OtpEmail;
