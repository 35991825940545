import {
  GET_DATA,
  UPDATE_DATA,
  ADD_EDUCATION_DATA,
  UPDATE_EDUCATION_DATA,
  ADD_COURSE_DATA,
  UPDATE_COURSE_DATA,
  ADD_LANGUAGE_DATA,
  UPDATE_LANGUAGE_DATA,
  ADD_LICENSE_DATA,
  UPDATE_LICENSE_DATA,
  ADD_SKILLS_DATA,
  UPDATE_SKILLS_DATA,
  DELETE_DATA,
  ADD_EXPERIENCE_DATA,
  EXP_DATA,
  EDU_DATA,
  UPDATE_EXPERIENCE_DATA,
  GET_VIEW_PROFILE_LIST,
  GET_VIEW_PROFILE_COUNT,
  UPDATE_CONNECTION_STATUS,
  ADD_HONORS_DATA,
  UPDATE_HONORS_DATA,
  UPLOAD_INTRO_VIDEO,
  UPLOAD_RESUME_DATA,
  DELETE_INTRO_VIDEO,
  CREATE_PROFILE_FOLLOWER,
  CREATE_PROFILE_UNFOLLOWER,
  USER_FOLLOW_STATUS_HOME,
  USER_UNFOLLOW_STATUS_HOME,
  VERIFY_EMAIL,
  CHANGE_EMAIL,
  REMOVE_CONNECTION,
  PUBLIC_POSTS_LIST,
  UPDATE_CONNECTION_REMOVE,
  DELETE_MEDIA_DATA,
  DELETE_HON_DATA,
  DELETE_LAN_DATA,
  GET_RECENT_SEARCH,
  DELETE_SEARCH_HISTORY,
  SEARCH_HISTORY,
  MORE_SEARCH_HISTORY,
  CLEAR_SEARCH_HISTORY,
  ADD_MULTIPLE_URL,
  DELETE_RESUME,
} from "../types";

import { profileApi } from "../../api/profileAPI";
import { upDateUserData } from "../auth/authActions";
import { peopleApi } from "../../api/peopleApi";
import { initPageLoad } from "../loader/loaderActions";
import { stopPageLoad } from "../loader/loaderActions";

export const getUserProfile = (id, token) => (dispatch) => {
  dispatch(initPageLoad());
  return profileApi.getUserDetails(id, token).then(
    (response) => {
      dispatch({
        type: GET_DATA,
        payload: response.data,
      });
      dispatch(stopPageLoad());
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const postUserDetails = (userData, token) => (dispatch) => {
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const uploadVideo = (videoData, token) => (dispatch) => {
  return profileApi.postUserDetails(videoData, token).then(
    (response) => {
      dispatch({
        type: UPLOAD_INTRO_VIDEO,
        payload: videoData,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteVideo = (videoData, token) => (dispatch) => {
  return profileApi.postUserDetails(videoData, token).then(
    (response) => {
      dispatch({
        type: DELETE_INTRO_VIDEO,
        payload: videoData,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const uploadResume = (resumeData, token) => (dispatch) => {
  return profileApi.postUserDetails(resumeData, token).then(
    (response) => {
      dispatch({
        type: UPLOAD_RESUME_DATA,
        payload: resumeData,
      });
      dispatch(upDateUserData({}));
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateUserDetails = (userData, token) => (dispatch) => {
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      console.log(userData, response);
      dispatch({
        type: UPDATE_DATA,
        payload: userData,
      });
      dispatch(upDateUserData(userData));
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addexperiencedetails = (userData, token) => (dispatch) => {
  let expDataformat = {
    experiences: [userData],
  };
  return profileApi.postUserDetails(expDataformat, token).then(
    (response) => {
      console.log(userData);
      if (userData.id) {
        dispatch({
          type: UPDATE_EXPERIENCE_DATA,
          payload: response.data.experience[0],
        });
      } else {
        dispatch({
          type: ADD_EXPERIENCE_DATA,
          payload: response.data.experience[0],
        });
      }
      dispatch(upDateUserData({}));
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addeducationdetails = (userData, token) => (dispatch) => {
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      if (userData.educations[0].id) {
        dispatch({
          type: UPDATE_EDUCATION_DATA,
          payload: response.data.education,
        });
      } else {
        dispatch({
          type: ADD_EDUCATION_DATA,
          payload: response.data.education,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addcoursesdetails = (userData, token) => (dispatch) => {
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      if (userData.courses[0].id) {
        dispatch({
          type: UPDATE_COURSE_DATA,
          payload: userData.courses,
        });
      } else {
        dispatch({
          type: ADD_COURSE_DATA,
          payload: response.data.courses,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addlanguagedetails = (userData, token) => (dispatch) => {
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      if (userData.languages[0].id) {
        dispatch({
          type: UPDATE_LANGUAGE_DATA,
          payload: userData.languages,
        });
      } else {
        dispatch({
          type: ADD_LANGUAGE_DATA,
          payload: response.data.language,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addlicensedetails = (userData, token) => (dispatch) => {
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      if (userData.license_certifications[0].id) {
        dispatch({
          type: UPDATE_LICENSE_DATA,
          payload: response.data.licenseCertification,
        });
      } else {
        dispatch({
          type: ADD_LICENSE_DATA,
          payload: response.data.licenseCertification,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addhonordetails = (userData, token) => (dispatch) => {
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      if (userData.honors_and_awards[0].id) {
        dispatch({
          type: UPDATE_HONORS_DATA,
          payload: response.data.honors_and_awards,
        });
      } else {
        dispatch({
          type: ADD_HONORS_DATA,
          payload: response.data.honors_and_awards,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const addskillsdetails = (userData, token) => (dispatch) => {
  return profileApi.postUserDetails(userData, token).then(
    (response) => {
      if (userData.exps_and_skills[0].id) {
        dispatch({
          type: UPDATE_SKILLS_DATA,
          payload: userData.exps_and_skills,
        });
      } else {
        dispatch({
          type: ADD_SKILLS_DATA,
          payload: response.data.expSkill,
        });
      }
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const updateContactDetails = (userData, token) => (dispatch) => {
  let formatContactData = {
    phone: userData.phone,
    dob: userData.dob,
    address: userData.address,
    nationality_id: userData.nationality?.id,
    country_id: userData.country.id,
    state_id: userData.state,
    city_id: userData.city,
    pin_code: userData.pin_code,
    location: userData.location,
    website: userData.website,
  };
  return profileApi.postUserDetails(formatContactData, token).then(
    (response) => {
      dispatch({
        type: UPDATE_DATA,
        payload: userData,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteUserSkill = (skillId, token) => (dispatch) => {
  return profileApi.deleteUserSkill(skillId, token).then(
    (response) => {
      dispatch({
        type: DELETE_DATA,
        payload: skillId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteProfileMedia = (mediaId, token) => (dispatch) => {
  return profileApi.deleteMedia(mediaId, token).then(
    (response) => {
      dispatch({
        type: DELETE_MEDIA_DATA,
        payload: mediaId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteUserExperience = (userId, token) => (dispatch) => {
  console.log(userId);
  return profileApi.deleteUserExperience(userId, token).then(
    (response) => {
      dispatch({
        type: EXP_DATA,
        payload: userId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteUserEducation = (userId, token) => (dispatch) => {
  return profileApi.deleteUserEducation(userId, token).then(
    (response) => {
      dispatch({
        type: EDU_DATA,
        payload: userId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getProfileViewersList = (params) => (dispatch) => {
  return profileApi.getViewProfileList(params).then(
    (response) => {
      dispatch({
        type: GET_VIEW_PROFILE_LIST,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getProfileCount = (data, token) => (dispatch) => {
  return profileApi.getViewProfileCount(data, token).then(
    (response) => {
      dispatch({
        type: GET_VIEW_PROFILE_COUNT,
        payload: response.data,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const connectionStatus = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_CONNECTION_STATUS,
    payload: data,
  });
};

export const profile_follower = (id, mode, post_id) => (dispatch) => {
  return peopleApi.createFollower(id).then((response) => {
    if (mode === "home") {
      dispatch({
        type: USER_FOLLOW_STATUS_HOME,
        payload: { id: id, post_id: post_id },
      });
    } else
      dispatch({
        type: CREATE_PROFILE_FOLLOWER,
        payload: id,
      });
    return Promise.resolve(response);
  });
};

export const profile_unfollower = (id, mode, post_id) => (dispatch) => {
  return peopleApi.createUnfollower(id).then((response) => {
    if (mode === "home") {
      dispatch({
        type: USER_UNFOLLOW_STATUS_HOME,
        payload: { id: id, post_id: post_id },
      });
    } else
      dispatch({
        type: CREATE_PROFILE_UNFOLLOWER,
        payload: id,
      });
    return Promise.resolve(response);
  });
};

export const remove_connection = (id, mode, post_id) => (dispatch) => {
  return peopleApi.removeConnection(id).then((response) => {
    if (mode === "home") {
      dispatch({
        type: REMOVE_CONNECTION,
        payload: { id: id, post_id: post_id },
      });
    } else {
      dispatch({
        type: UPDATE_CONNECTION_REMOVE,
        payload: null,
      });
    }
    //      else
    // dispatch({
    //   type: CREATE_PROFILE_UNFOLLOWER,
    //   payload: id,
    // });
    return Promise.resolve(response);
  });
};

export const verify_email = () => (dispatch) => {
  return profileApi.verifyEmail().then((response) => {
    dispatch({
      type: VERIFY_EMAIL,
    });
    return Promise.resolve(response);
  });
};
export const change_email = (data) => (dispatch) => {
  return profileApi.changeEmail(data).then((response) => {
    dispatch({
      type: CHANGE_EMAIL,
      payload: data,
    });
    return Promise.resolve(response);
  });
};

export const public_posts_list = (data) => (dispatch) => {
  return profileApi.public_posts_list(data).then((response) => {
    dispatch({
      type: PUBLIC_POSTS_LIST,
      payload: response.data,
    });
    return Promise.resolve(response);
  });
};

export const getAllCompanyList = (data) => (dispatch) => {
  return profileApi.getAllCompanyList(data).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteUserHonors = (userId, token) => (dispatch) => {
  return profileApi.deleteUserHonors(userId, token).then(
    (response) => {
      dispatch({
        type: DELETE_HON_DATA,
        payload: userId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const deleteUserLanguage = (userId, token) => (dispatch) => {
  return profileApi.deleteUserLanguage(userId, token).then(
    (response) => {
      dispatch({
        type: DELETE_LAN_DATA,
        payload: userId,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getSkills = (data) => () => {
  return profileApi
    .getAllSkills(data)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const multiResume = (data) => (dispatch) => {
  return profileApi.multipleResume(data).then((response) => {
    console.log(response?.data);
    dispatch({
      type: ADD_MULTIPLE_URL,
      payload: response?.data,
    });
    return Promise.resolve(response);
  });
};

export const deleteResume = (id) => (dispatch) => {
  return profileApi.deleteUserResume(id).then(
    (response) => {
      dispatch({
        type: DELETE_RESUME,
        payload: id,
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
