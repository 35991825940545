import React, { useEffect, useState } from "react";
import companylogo from "../../../assets/img/company-name.svg";
import profileicon from "../../../assets/img/placeholder_user.jpg";
import arrow from "../../../assets/img/Left-arrow-black.svg";
import "./ApplicantDetails.scss";
import PortalFooter from "../../Portal/portalFooter/PortalFooter";
import close from "../../../assets/img/Close-red.svg";
import list from "../../../assets/img/shopping-list.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../store/profile/profileActions";
import {
  getApplicantsById,
  getTalentPool,
  updateApplicant,
} from "../../../store/talentPool/talentPoolAction";
import moment from "moment";
import CoverletterModal from "./coverletterModal";
import { initLoad, stopLoad } from "../../../store/loader/loaderActions";
import { UPDATE_APPLICANTS_BY_ID } from "../../../store/types";
import { toast } from "react-toastify";
import Loader from "../../../core/components/Loaders/ComponentLoader";

const ApplicantDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { applicant_id, id } = useParams();
  const token = localStorage.getItem("accessToken");
  const { profileData } = useSelector((state) => state.profile);
  const { applicantDetail, portal } = useSelector((state) => state.talentPool);
  const { isLoading } = useSelector((state) => state.loader);
  const theme = "#000";

  useEffect(() => {
    dispatch(getUserProfile(0, token));
    dispatch(getApplicantsById(applicant_id));
    dispatch(getTalentPool(id))
      .then((res) => {
        if (!res.success) {
          navigate("/dashboard");
          toast.error("Permission Denied", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      })
      .catch((err) => {
        navigate("/dashboard");
        toast.error("Permission Denied", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      });
  }, []);
  function calculateAge(dateOfBirth) {
    const dob = moment(dateOfBirth);
    const now = moment();
    return now.diff(dob, "years");
  }

  function formatDuration(start_date, end_date) {
    const startDate = moment(start_date);
    const endDate = moment(end_date);
    const diff = moment.duration(endDate.diff(startDate));
    const years = diff.years();
    const months = diff.months();

    let durationString = "";

    if (years > 0) {
      durationString += `${years} year${years > 1 ? "s" : ""}`;
    }

    if (months > 0) {
      if (durationString !== "") {
        durationString += " ";
      }
      durationString += `${months} month${months > 1 ? "s" : ""}`;
    }
    console.log(durationString, start_date, end_date);
    return durationString;
  }
  const [openLetter, setOpenLetter] = useState(false);

  const handleShortlist = (id, jobId) => {
    dispatch(initLoad());
    dispatch(updateApplicant({ status: "shortlisted" }, id))
      .then((response) => {
        dispatch({
          type: UPDATE_APPLICANTS_BY_ID,
          payload: { id, jobId, status: "shortlisted" },
        });
        dispatch(stopLoad());
        toast.success("Shortlisted successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        dispatch(stopLoad());
        console.error("Error", error);
      });
  };

  const handleReject = (id, jobId) => {
    dispatch(initLoad());
    dispatch(updateApplicant({ status: "rejected" }, id))
      .then((response) => {
        dispatch({
          type: UPDATE_APPLICANTS_BY_ID,
          payload: { id, jobId, status: "rejected" },
        });
        dispatch(stopLoad());
        toast.success("Rejected successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        dispatch(stopLoad());
        console.error("Error", error);
      });
  };
  return (
    <div>
      <div className="job-applicant">
        {isLoading && <Loader color={portal?.theme || theme} />}
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="header-row px-2">
                <img
                  height={37}
                  src={portal?.icon || companylogo}
                  alt="companny-logo"
                  style={{
                    objectFit: "contain",
                    maxWidth: "143px",
                  }}
                />

                <div className="user-profile col-3 justify-content-end">
                  <img
                    className="rounded-circle"
                    src={profileData?.profile_img || profileicon}
                    alt="user-logo"
                    width={30}
                    height={30}
                  />
                  <h5
                    className="card-control-text-oneline"
                    style={{ fontSize: "15px", marginLeft: "-5px" }}
                  >
                    {profileData?.first_name}
                    {profileData?.last_name}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="row my-4">
              <div
                className="w-25 col-12 d-flex back"
                onClick={() => navigate(`/tp/${id}/dashboard`)}
              >
                <img src={arrow} alt="arrow" className="me-2" />
                <h5
                  className="
                text-dark"
                >
                  Back
                </h5>
              </div>
            </div>
          </div>
          <div className="row main-desc">
            <div className="col-md-12 col-sm-12">
              <div className="job-applicant-details">
                <div className="row">
                  <div className="col-md-12 col-lg-5 col-sm-12">
                    <div className="job-applicant-details-left">
                      <div className="profile-image">
                        <img
                          src={
                            applicantDetail?.user?.profile_img || profileicon
                          }
                          alt=""
                          className="pro-img "
                        />
                      </div>
                      <div className="job-applicant-details-left-content">
                        <div className="job-applicant-details-left-content-row mx-5">
                          <h3 className="text-dark">
                            {applicantDetail?.user?.first_name?.length > 10
                              ? applicantDetail?.user?.first_name.slice(0, 7) +
                                "..."
                              : applicantDetail?.user?.first_name}{" "}
                            {applicantDetail?.user?.last_name?.length > 10
                              ? applicantDetail?.user?.last_name.slice(0, 7) +
                                "..."
                              : applicantDetail?.user?.last_name}
                          </h3>
                          <p className="card-control-text-oneline text-dark">
                            {applicantDetail?.user?.profile?.profile_headline}
                          </p>
                          <p> {applicantDetail?.user?.profile?.location}</p>
                          <span>
                            <Link
                              to={
                                applicantDetail?.user?.profile?.profile_url
                                  ? `/profile/${applicantDetail?.user?.profile?.profile_url}`
                                  : `/profile/${applicantDetail?.user?.id}`
                              }
                            >
                              <span className="card-control-text-oneline">
                                {applicantDetail?.user?.profile?.profile_url
                                  ? `${process.env.REACT_APP_WEB_STAGE_URL}profile/${applicantDetail?.user?.profile?.profile_url}`
                                  : `${process.env.REACT_APP_WEB_STAGE_URL}profile/${applicantDetail?.user?.id}`}
                              </span>
                            </Link>
                          </span>
                          {applicantDetail?.user?.profile?.resume_url && (
                            <div className="buttons justify-content-start">
                              {applicantDetail?.apply_status !==
                                "shortlisted" && (
                                <div
                                  className="btn me-3"
                                  onClick={() =>
                                    handleShortlist(
                                      applicant_id,
                                      applicantDetail?.job_id
                                    )
                                  }
                                  style={{
                                    color: "#fff",
                                    border: `1px solid ${portal?.theme}`,
                                    background: portal?.theme,
                                    borderRadius: 0,
                                  }}
                                >
                                  Shortlist
                                </div>
                              )}
                              <a
                                className="btn"
                                href={
                                  applicantDetail?.user?.profile?.resume_url
                                }
                                target="_blank"
                                style={{
                                  color: portal?.theme,
                                  border: `1px solid ${portal?.theme}`,
                                  borderRadius: 0,
                                }}
                              >
                                Resume
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-7 col-sm-12 d-flex">
                    <div className="verlical-line"></div>
                    <div className="job-applicant-details-right">
                      <div className="job-applicant-details-right-content">
                        <div className="job-applicant-details-right-content-row">
                          <div>
                            <p className="text-dark fw-bold">Phone</p>

                            <p className="mt-2 text-dark fw-bold">
                              Date of Birth
                            </p>

                            <p className="mt-2 text-dark fw-bold">Age</p>

                            <p className="mt-2 text-dark fw-bold">
                              Nationality
                            </p>
                          </div>
                          <div>
                            <p>{applicantDetail?.user?.phone || "-"}</p>
                            <p className="m-top">
                              {(applicantDetail?.user?.profile?.dob &&
                                moment(
                                  applicantDetail?.user?.profile?.dob
                                ).format("DD-MM-yyyy")) ||
                                "-"}
                            </p>
                            <p className="m-top">
                              {(applicantDetail?.user?.profile?.dob &&
                                calculateAge(
                                  applicantDetail?.user?.profile?.dob
                                )) ||
                                "-"}
                            </p>
                            <p className="m-top">
                              {applicantDetail?.user?.profile?.nationality
                                ?.name || "-"}
                            </p>
                          </div>
                          <div>
                            <p className="text-dark fw-bold">Region</p>
                            <p className="mt-2 text-dark fw-bold">City</p>
                            <p className="mt-2 text-dark fw-bold">Email</p>
                            <p className="mt-2 text-dark fw-bold">Website</p>
                          </div>
                          <div>
                            <p>
                              {applicantDetail?.user?.profile?.country?.name ||
                                "-"}
                            </p>
                            <p className="m-top">
                              {applicantDetail?.user?.profile?.city?.name ||
                                "-"}
                            </p>
                            <p className="m-top card-control-text-oneline">
                              {applicantDetail?.user?.email || "-"}
                            </p>
                            <p className="m-top card-control-text-oneline">
                              {applicantDetail?.user?.profile?.website || "-"}
                            </p>
                          </div>
                        </div>
                        <div className="resume-icons">
                          {applicantDetail?.apply_status !== "shortlisted" ? (
                            <>
                              <div className="b-white">
                                <img
                                  src={list}
                                  alt="ui"
                                  className="close"
                                  onClick={() =>
                                    handleShortlist(
                                      applicant_id,
                                      applicantDetail?.job_id
                                    )
                                  }
                                />
                              </div>
                              <div className="b-white">
                                <img
                                  src={close}
                                  alt="ui"
                                  className="close"
                                  onClick={() =>
                                    handleReject(
                                      applicant_id,
                                      applicantDetail?.job_id
                                    )
                                  }
                                />
                              </div>
                            </>
                          ) : (
                            <p className="text-capitalize">
                              {applicantDetail?.apply_status}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="experience">
                <h4 className="mb-4 text-dark">Experience</h4>
                <div className="experience-row">
                  {applicantDetail?.user?.profile?.highestExperience ? (
                    <div className="experience-row-left">
                      <div className="d-flex align-items-center gap-2">
                        {applicantDetail?.user?.profile?.highestExperience
                          ?.title && (
                          <strong className="card-control-text-oneline text-dark">
                            {
                              applicantDetail?.user?.profile?.highestExperience
                                ?.title
                            }
                          </strong>
                        )}

                        {applicantDetail?.user?.profile?.highestExperience
                          ?.employment_type && (
                          <p>
                            {
                              applicantDetail?.user?.profile?.highestExperience
                                ?.employment_type
                            }
                          </p>
                        )}
                        {applicantDetail?.user?.profile?.highestExperience
                          ?.location && (
                          <p className="m-0">
                            {
                              applicantDetail?.user?.profile?.highestExperience
                                ?.location
                            }
                          </p>
                        )}
                      </div>
                      <div className="d-flex">
                        <p className="m-top">
                          {moment(
                            applicantDetail?.user?.profile?.highestExperience
                              ?.start_date
                          ).format("MMM yyyy")}
                          {" - "}
                          {applicantDetail?.user?.profile?.highestExperience
                            ?.is_current
                            ? "Present"
                            : applicantDetail?.user?.profile?.highestExperience
                                ?.end_date
                            ? moment(
                                applicantDetail?.user?.profile
                                  ?.highestExperience?.end_date
                              ).format("MMM-yyyy")
                            : "No Date Available"}{" "}
                          .{" "}
                          {formatDuration(
                            applicantDetail?.user?.profile?.highestExperience
                              ?.start_date,
                            applicantDetail?.user?.profile?.highestExperience
                              ?.is_current
                              ? new Date()
                              : applicantDetail?.user?.profile
                                  ?.highestExperience?.end_date || new Date()
                          )}
                        </p>
                      </div>
                      {/* <p className="m-top">
                        {moment(
                          applicantDetail?.user?.profile?.highestExperience
                            ?.start_date
                        ).format("MMM-yyyy")}
                        -
                        {applicantDetail?.user?.profile?.highestExperience
                          ?.is_current
                          ? "Present"
                          : applicantDetail?.user?.profile?.highestExperience
                              ?.end_date
                          ? moment(
                              applicantDetail?.user?.profile?.highestExperience
                                ?.end_date
                            ).format("MMM-yyyy")
                          : "No Date Available"}
                      </p> */}
                      <p className="my-3" style={{ wordBreak: "break-all" }}>
                        {
                          applicantDetail?.user?.profile?.highestExperience
                            ?.description
                        }
                      </p>
                    </div>
                  ) : (
                    <strong>No Experience</strong>
                  )}
                </div>
              </div>
              <div className="experience">
                <h4 className="mb-4 text-dark">Education</h4>
                <div className="experience-row">
                  {applicantDetail?.user?.profile?.highestEducation ? (
                    <div className="experience-row-left">
                      <div className="">
                        {applicantDetail?.user?.profile?.highestEducation
                          ?.school_name && (
                          <strong className="card-control-text-oneline text-dark">
                            {
                              applicantDetail?.user?.profile?.highestEducation
                                ?.school_name
                            }
                          </strong>
                        )}

                        {applicantDetail?.user?.profile?.highestEducation
                          ?.degree ||
                          (applicantDetail?.user?.profile?.highestEducation
                            ?.field_of_study && (
                            <p>
                              {
                                applicantDetail?.user?.profile?.highestEducation
                                  ?.degree
                              }
                              ,
                              {
                                applicantDetail?.user?.profile?.highestEducation
                                  ?.field_of_study
                              }
                            </p>
                          ))}
                        {applicantDetail?.user?.profile?.highestEducation
                          ?.start_date &&
                          applicantDetail?.user?.profile?.highestEducation
                            ?.end_date && (
                            <p className="m-0">
                              {moment(
                                applicantDetail?.user?.profile?.highestEducation
                                  ?.start_date
                              ).format("yyyy")}
                              -
                              {moment(
                                applicantDetail?.user?.profile?.highestEducation
                                  ?.end_date
                              ).format("yyyy")}
                            </p>
                          )}
                        <p className="my-3" style={{ wordBreak: "break-all" }}>
                          {
                            applicantDetail?.user?.profile?.highestEducation
                              ?.description
                          }
                        </p>
                      </div>
                    </div>
                  ) : (
                    <strong>No Education</strong>
                  )}
                </div>
                {(applicantDetail?.cover_letter_subject ||
                  applicantDetail?.cover_letter_desc) && (
                  <p
                    style={{ cursor: "pointer" }}
                    className="cover-letter mt-4"
                    onClick={() => setOpenLetter(!openLetter)}
                  >
                    View cover letter
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "white" }}>
        <PortalFooter whiteBgColor={true} />
      </div>
      {openLetter && (
        <CoverletterModal
          openLetter={openLetter}
          setOpenLetter={setOpenLetter}
          subject={applicantDetail?.cover_letter_subject}
          description={applicantDetail?.cover_letter_desc}
        />
      )}
    </div>
  );
};

export default ApplicantDetail;
