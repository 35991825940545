import React from "react";
import "./Footer.scss";
import logo from "../../../assets/img/company-name.svg";
import smalllogo from "../../../assets/img/small-logo.png";
import companylogo from "../../../assets/img/page-placeholder.svg";
import { useSelector } from "react-redux";

const PortalFooter = (props) => {
  const { activeTab } = props;
  const { talentPoolData } = useSelector((state) => state.portal);
  console.log("activeTab", talentPoolData);
  return (
    <div
      className={`tp-footer ${props?.whiteBgColor ? "bgcolor_applicant" : ""}`}
      style={{
        background:
          activeTab !== "profile" &&
          activeTab !== "resume" &&
          activeTab !== "letter"
            ? "rgba(244, 245, 250, 1)"
            : null,
      }}
    >
      <footer className="mt-4 py-3 pb-0 footer_container">
        <div className="row" style={{ marginTop: "89px" }}>
          <div className="col-md-6 col-sm-12">
            <div className="footer-logo">
              <img
                src={talentPoolData?.icon ? talentPoolData?.icon : companylogo}
                alt="logo"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
            </div>
          </div>

          <div className="col-md-6 col-sm-12" style={{ paddingRight: 0 }}>
            <div className="footer-links">
              <ul
                className="nav justify-content-end  pb-3 mb-3"
                style={{ gap: "20px" }}
              >
                <li>
                  {" "}
                  <a href="/legal" className="nav-link px-2 text-muted">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="/legal" className="nav-link px-2 text-muted">
                    Cookies Policy
                  </a>
                </li>
                <li>
                  {" "}
                  <a href="/legal" className="nav-link px-2 text-muted">
                    Terms & Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="border-bottom my-3"
          style={{ border: "1px solid rgba(209, 223, 255, 1)" }}
        ></div>
        <div className="bottom my-4">
          <p>
            ©
            {talentPoolData?.company_name
              ? talentPoolData?.company_name
              : talentPoolData?.name}
            . All right reserved. Powerd by <img src={smalllogo} alt="logo" />{" "}
            NobelPage
          </p>
        </div>
        {/* <div className="portal-footer">
      <div className="inner-footer-div">
        <img src={logo} alt="logo" />
        <div className="policy">
          <p>Privacy Policy</p>
          <p>Cookies Policy</p>
          <p>Terms & Conditions</p>
        </div>
      </div>
      <div className="line"></div>
      <div className="bottom">
        <p>
          ©Infinite Opensource Solution LTD. All right reserved. Powerd by {" "}
          <img src={smalllogo} alt="logo"/>
          {" "} NobelPage
        </p>
      </div>
    </div> */}
      </footer>
    </div>
  );
};

export default PortalFooter;
