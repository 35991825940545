import React, { useEffect } from "react";
import PortalDashboard from "./PortalDashboard";
import { useDispatch } from "react-redux";
import {
  getTalentPoolDetails,
  getTalentPoolDetailsByToken,
} from "../../../store/portal/portalAction";
import { useNavigate, useParams } from "react-router-dom";

function PortalDashboardContainer() {
  const dispatch = useDispatch();
  const { param } = useParams();
  const token = localStorage.getItem("accessTpToken");
  const navigate = useNavigate();
  useEffect(() => {
    // dispatch(getTalentPoolDetailsByToken());
    dispatch(getTalentPoolDetails(param))
      .then((res) => console.log(res))
      .catch((err) =>
        err?.request?.status == 404 ? navigate("/error") : console.log(err)
      );
    if (token) dispatch(getTalentPoolDetailsByToken());
  }, []);
  return <PortalDashboard />;
}

export default PortalDashboardContainer;
