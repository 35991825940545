import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getmembers } from "../../../store/talentPool/talentPoolAction";
import uidesign from "../../../assets/img/bezier.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import placeholderUser from "../../../assets/img/placeholder_user.jpg";
import { useNavigate, useParams } from "react-router-dom";

const MemberTab = () => {
  const { members, portal } = useSelector((state) => state.talentPool);
  const [currentCount, setCurrectCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    dispatch(
      getmembers(portal?.id, { limit: 10, skip: 0, sort: "desc" }, currentCount)
    ).then((res) => setTotalCount(res?.count));
  }, []);
  useEffect(() => {
    if (currentCount) {
      dispatch(
        getmembers(portal?.id, {
          limit: 10,
          skip: currentCount,
          sort: "desc",
        })
      ).then((res) => setTotalCount(res?.count));
    }
  }, [currentCount]);
  return (
    <>
      <div className="">
        <h5>Members List</h5>
        {members?.length > 0 ? (
          <InfiniteScroll
            dataLength={members?.length ? members?.length : 0}
            next={() => setCurrectCount(currentCount + 10)}
            hasMore={members?.length < totalCount}
            style={{ overflow: "visible" }}
          >
            <div
              style={{
                width: "100%",
                flexWrap: "inherit",
                alignItems: "center",
              }}
            >
              {members?.map((ele) => {
                return (
                  <>
                    <div className="d-flex align-items-center mt-5">
                      <div
                        className="bg-red job-icon"
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={
                            // item?.company?.icon ||
                            ele?.user?.profile_img || placeholderUser
                          }
                          height={50}
                          width={50}
                          alt="ui"
                          className="rounded-circle"
                          onClick={() =>
                            navigate(
                              `/tp/${id}/member/details/${ele?.user?.id}`
                            )
                          }
                        />
                      </div>
                      <h5
                        className="card-control-text-oneline text-start mx-3"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/tp/${id}/member/details/${ele?.user?.id}`)
                        }
                      >
                        {ele?.user?.first_name || "TP"}{" "}
                        {ele?.user?.last_name || "User"}
                      </h5>
                    </div>
                  </>
                );
              })}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="my-job-nav-list-outer d-flex justify-content-center mt-3">
            <h6 className="create">No Members Found</h6>
          </div>
        )}
      </div>
    </>
  );
};

export default MemberTab;
