import React from "react";
import Navbar from "../../pages/navbar/navbar";
import dummyCompanyLogo from "../../assets/img/dummyCompanyLogo.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import pagePlaceholder from "../../assets/img/page-placeholder.svg";
import closeIcon from "../../assets/img/closeIcon2.svg";
import "./myJobsList.scss";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJobs, saveJob } from "../../store/jobs/jobAction";
import { useState } from "react";
import AppLoader from "../../core/components/Loaders/appLoader/appLoader";
import Advertisement from "../dashboard/Advertisement";
import Ad from "../../core/components/layout/Sidebar/Ad";
import JobAlertModal from "./JobAlertModal";
import { ToastContainer, toast } from "react-toastify";
import SalaryComparision from "./SalaryComparision";
import JobCard from "../Jobs/components/JobCard/JobCard";
import Chat from "../../pages/chat/chat";
import SidebarFooter from "../setting/SidebarFooter";
import Refer from "../../core/components/layout/Sidebar/Refer";
import MyJobView from "./MyJobView";
import SideJobCard from "../Jobs/components/SideJobCard/SideJobCard";

const MyJobs = () => {
  const [currentCount, setCurrectCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [totalFeaturedCount, setTotalFeaturedCount] = useState(0);
  const [jobAlert, setJobAlert] = useState(false);
  const [totalSaveCount, setTotalSaveCount] = useState(0);
  const [totalAppliedCount, setTotalAppliedCount] = useState(0);
  const [totalSmartCount, setTotalSmartCount] = useState(0);
  const [totalInProgressCount, setTotalInProgressCount] = useState(0);
  const [totalClosedCount, setTotalClosedCount] = useState(0);
  const [tab, setTab] = useState("my");
  const [sortBy, setSortBy] = useState("desc");
  const [selectedSubTab, setSelectedSubTab] = useState("Saved");
  const [isMoreJobs, setIsMoreJobs] = useState();
  const [subTabs, setSubTabs] = useState([
    "Saved",
    "Applied",
    "Smart Interview Sent",
  ]);
  const {
    getAllJobs,
    getFeaturedJobs,
    getSavedJobs,
    getAppliedJobs,
    getSentInterviewJobs,
    getInProgressJobs,
    getClosedJobs,
  } = useSelector((state) => state.jobs);
  const { isLoading } = useSelector((state) => state.loader);
  const [activeTab, setActiveTab] = useState(0);
  const [showSalaryComparision, setShowSalaryComparision] = useState(false);
  const navigate = useNavigate();
  const params = useLocation();
  const { user } = useSelector((state) => state.auth);
  console.log(user);
  const dispatch = useDispatch();
  useEffect(() => {
    if (params?.state?.subTab == "Smart Interview Sent") {
      setActiveTab(2);
      setSelectedSubTab("Smart Interview Sent");
    }
    window.history.replaceState({}, "");
    dispatch(
      getJobs({ limit: 10, skip: currentCount, filter: "my" }, currentCount)
    ).then((res) => {
      console.log(res.totalItem);
      setTotalCount(res.totalItem);
      // setCurrectCount(currentCount);
    });
    dispatch(
      getJobs(
        { limit: 3, skip: currentCount, filter: "featured" },
        currentCount,
        "featured"
      )
    ).then((res) => {
      setTotalFeaturedCount(res.totalItem);
    });
  }, []);
  useEffect(() => {
    if (tab === "my") {
      setSubTabs(["Saved", "Applied", "Smart Interview Sent"]);
      setCurrectCount(0);
    } else if (tab === "posted") {
      setSubTabs(["In Progress", "Closed"]);
      setCurrectCount(0);
    }
  }, [tab]);

  useEffect(() => {
    setCurrectCount(0);

    if (selectedSubTab === "Saved") {
      dispatch(
        getJobs(
          { limit: 10, skip: currentCount, filter: "saved", sort: sortBy },
          currentCount,
          "saved"
        )
      ).then((res) => {
        setTotalSaveCount(res.totalItem);
      });
    } else if (selectedSubTab === "Applied") {
      dispatch(
        getJobs(
          { limit: 10, skip: currentCount, filter: "applied", sort: sortBy },
          currentCount,
          "applied"
        )
      ).then((res) => {
        setTotalSaveCount(res.totalItem);
      });
    } else if (selectedSubTab === "Smart Interview Sent") {
      dispatch(
        getJobs(
          { limit: 10, skip: currentCount, filter: "interview", sort: sortBy },
          currentCount,
          "interview"
        )
      ).then((res) => {
        setTotalSaveCount(res.totalItem);
      });
    } else if (selectedSubTab === "In Progress") {
      dispatch(
        getJobs(
          {
            limit: 10,
            skip: currentCount,
            status: "active",
            filter: "my",
            sort: sortBy,
          },
          currentCount,
          "inprogress"
        )
      ).then((res) => {
        setTotalSaveCount(res.totalItem);
      });
    } else if (selectedSubTab === "Closed") {
      dispatch(
        getJobs(
          {
            limit: 10,
            skip: currentCount,
            status: "deactive",
            filter: "my",
            sort: sortBy,
          },
          currentCount,
          "closed"
        )
      ).then((res) => {
        setTotalSaveCount(res.totalItem);
      });
    }
  }, [sortBy]);

  useEffect(() => {
    if (selectedSubTab === "Saved") getAllSavedJobs();
    else if (selectedSubTab === "Applied") getAllAppliedJobs();
    else if (selectedSubTab === "Smart Interview Sent") getSmartSentJobs();
    else if (selectedSubTab === "In Progress") getAllInProgressJobs();
    else if (selectedSubTab === "Closed") getAllClosedJobs();
  }, [selectedSubTab]);
  useEffect(() => {
    if (selectedSubTab === "Saved") {
      if (getAllJobs?.length < totalSaveCount) setIsMoreJobs(true);
      else setIsMoreJobs(false);
    } else if (selectedSubTab === "Applied") {
      if (getAllJobs?.length < totalAppliedCount) setIsMoreJobs(true);
      else setIsMoreJobs(false);
    } else if (selectedSubTab === "Smart Interview Sent") {
      if (getAllJobs?.length < totalSmartCount) setIsMoreJobs(true);
      else setIsMoreJobs(false);
    } else if (selectedSubTab === "In Progress") {
      if (getAllJobs?.length < totalInProgressCount) setIsMoreJobs(true);
      else setIsMoreJobs(false);
    } else if (selectedSubTab === "Closed") {
      if (getAllJobs?.length < totalClosedCount) setIsMoreJobs(true);
      else setIsMoreJobs(false);
    }
  }, [
    currentCount,
    totalSaveCount,
    totalAppliedCount,
    totalSmartCount,
    totalInProgressCount,
    totalClosedCount,
  ]);

  const getAllSavedJobs = () => {
    dispatch(
      getJobs(
        { limit: 10, skip: currentCount, filter: "saved", sort: sortBy },
        currentCount,
        "saved"
      )
    ).then((res) => {
      setTotalSaveCount(res.totalItem);
    });
  };
  const getAllAppliedJobs = () => {
    dispatch(
      getJobs(
        { limit: 10, skip: currentCount, filter: "applied", sort: sortBy },
        currentCount,
        "applied"
      )
    ).then((res) => {
      setTotalAppliedCount(res.totalItem);
    });
  };
  const getSmartSentJobs = () => {
    dispatch(
      getJobs(
        { limit: 10, skip: currentCount, filter: "interview", sort: sortBy },
        currentCount,
        "interview"
      )
    ).then((res) => {
      setTotalSmartCount(res.totalItem);
    });
  };
  const getAllInProgressJobs = () => {
    dispatch(
      getJobs(
        {
          limit: 10,
          skip: currentCount,
          status: "active",
          filter: "my",
          sort: sortBy,
        },
        currentCount,
        "inprogress"
      )
    ).then((res) => {
      setTotalInProgressCount(res.totalItem);
    });
  };
  const getAllClosedJobs = () => {
    dispatch(
      getJobs(
        {
          limit: 10,
          skip: currentCount,
          status: "deactive",
          filter: "my",
          sort: sortBy,
        },
        currentCount,
        "closed"
      )
    ).then((res) => {
      setTotalClosedCount(res.totalItem);
    });
  };

  const toggleSalaryComparision = () => {
    setShowSalaryComparision(!showSalaryComparision);
  };

  const loadMore = () => {
    setCurrectCount(currentCount + 10);
  };

  useEffect(() => {
    if (selectedSubTab === "Saved") getAllSavedJobs();
    else if (selectedSubTab === "Applied") getAllAppliedJobs();
    else if (selectedSubTab === "Smart Interview Sent") getSmartSentJobs();
    else if (selectedSubTab === "In Progress") getAllInProgressJobs();
    else if (selectedSubTab === "Closed") getAllClosedJobs();
  }, [currentCount]);

  const handleClose = (id) => {
    dispatch(saveJob(id)).then((res) => {
      if (res.success) {
        toast.success("Job unsaved successfuly", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    });
    const indexToRemove = getSavedJobs.findIndex((job) => job.id === id);
    getSavedJobs.splice(indexToRemove, 1);
    setTotalSaveCount(totalSaveCount - 1);
  };

  return (
    <>
      <Navbar />
      <div className="bg-grey">
        <div className="my-jobs-page">
          {isLoading && <AppLoader />}
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <div className="my-jobs-list-section mt-4">
                  <ul>
                    <li
                      className={tab === "my" ? "active" : ""}
                      onClick={() => {
                        setTab("my");
                        setActiveTab(0);
                        setSelectedSubTab("Saved");
                      }}
                    >
                      <div className="job-list">
                        <span className="title">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.82231 1.22309C3.97202 1.19848 4.12436 1.1979 4.27583 1.19907C6.47338 1.19936 8.67094 1.19907 10.8682 1.19936C11.1278 1.19702 11.3888 1.2436 11.6255 1.352C12.2217 1.61508 12.6284 2.24555 12.6216 2.89741C12.6237 6.1602 12.6216 9.42299 12.6225 12.6858C12.6266 12.8657 12.5871 13.0476 12.4998 13.2055C12.3407 13.5017 12.0237 13.7053 11.6885 13.729C11.4383 13.7475 11.1832 13.662 10.9951 13.4961C9.92807 12.5513 8.86166 11.6056 7.79467 10.6608C7.68452 10.5571 7.51665 10.5196 7.37397 10.5714C7.2729 10.606 7.19936 10.6866 7.12114 10.7548C6.08989 11.668 5.05952 12.5818 4.02827 13.4944C3.8062 13.6948 3.48598 13.7786 3.19448 13.7109C2.74975 13.6168 2.39643 13.1997 2.39907 12.7415C2.3979 9.46137 2.39877 6.18129 2.39848 2.90122C2.39848 2.5312 2.52094 2.16206 2.74682 1.86879C3.00581 1.52719 3.39868 1.29047 3.82231 1.22309Z"
                              fill="#0432A3"
                            />
                          </svg>
                          My Jobs
                        </span>
                        <span className="number">
                          {tab === "my"
                            ? totalSaveCount +
                              totalAppliedCount +
                              totalSmartCount
                            : ""}
                        </span>
                      </div>
                    </li>
                    <li
                      className={
                        user?.data?.membership?.id == 1
                          ? "opacity-25"
                          : tab === "posted"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        if (user?.data?.membership?.id != 1) {
                          setTab("posted");
                          setActiveTab(0);
                          setSelectedSubTab("In Progress");
                        } else {
                          toast.error(
                            "To access this feature, please upgrade your membership"
                          );
                        }
                      }}
                    >
                      <div className="job-list">
                        <span className="title">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1603_26063)">
                              <path
                                d="M12.3421 0.4786C12.6397 0.183874 13.0593 0.0183462 13.4779 0.0309438C13.8875 0.038854 14.2897 0.217858 14.5701 0.516686C14.8335 0.790026 14.9853 1.16239 14.9999 1.5412V1.62587C14.9902 1.91444 14.9062 2.20155 14.7465 2.44296C14.6381 2.61317 14.4872 2.7494 14.3463 2.89178C14.1233 3.11444 13.901 3.33827 13.6774 3.56034C12.9409 2.8244 12.2047 2.08817 11.4688 1.35194C11.7594 1.06014 12.05 0.768639 12.3421 0.4786Z"
                                fill="#676A79"
                              />
                              <path
                                d="M5.86647 6.95195C7.51266 5.30664 9.15768 3.65986 10.8048 2.01514C11.541 2.75107 12.2775 3.48701 13.0132 4.22383C11.3787 5.86152 9.74069 7.496 8.10563 9.13311C8.04323 9.20488 7.95768 9.25147 7.86364 9.26524C7.19977 9.39531 6.53708 9.53067 5.8735 9.66192C5.76393 9.67979 5.64967 9.72432 5.53923 9.68623C5.38717 9.63994 5.29225 9.46651 5.33415 9.31299C5.47829 8.58994 5.62331 7.8666 5.76774 7.14356C5.77946 7.07119 5.81257 7.00264 5.86647 6.95195Z"
                                fill="#676A79"
                              />
                              <path
                                d="M0.306152 3.38093C0.6375 2.86794 1.23105 2.53249 1.84365 2.52985C3.52412 2.52897 5.20459 2.52927 6.88477 2.52985C7.12852 2.52927 7.36143 2.68893 7.45195 2.91452C7.53984 3.12165 7.50352 3.37507 7.35938 3.54821C7.23926 3.69821 7.04736 3.78376 6.85576 3.77907C5.26406 3.77966 3.67236 3.77907 2.08066 3.77936C1.98223 3.78024 1.88379 3.77468 1.78594 3.78581C1.5627 3.81481 1.36465 3.97712 1.28848 4.18835C1.24131 4.30934 1.2501 4.44147 1.2498 4.56891C1.25039 7.43063 1.24922 10.2924 1.25039 13.1541C1.24189 13.4883 1.54219 13.7872 1.87588 13.7787C4.78565 13.7801 7.69541 13.779 10.6052 13.7793C10.9447 13.7963 11.2564 13.4971 11.2494 13.157C11.2515 11.4859 11.2485 9.81481 11.2509 8.14401C11.2506 7.90495 11.4035 7.67643 11.6224 7.5821C11.8225 7.49216 12.0688 7.51852 12.2446 7.65007C12.4096 7.76843 12.5057 7.97087 12.5001 8.17331C12.5001 9.82389 12.5001 11.4748 12.5001 13.1257C12.5016 13.3527 12.4655 13.5803 12.387 13.7936C12.167 14.4086 11.6063 14.8832 10.9638 15.0001H1.546C1.03301 14.9104 0.566895 14.5928 0.290332 14.1522C0.115723 13.8833 0.0213867 13.5674 0 13.2487V4.30788C0.0219727 3.97975 0.121875 3.65485 0.306152 3.38093Z"
                                fill="#676A79"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1603_26063">
                                <rect width="15" height="15" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          Posted Jobs
                        </span>
                        {user?.data?.membership?.id !== 1 && (
                          <span className="number">{totalCount}</span>
                        )}
                      </div>
                    </li>
                    <li
                      className={
                        user?.data?.membership?.id == 1
                          ? "opacity-25"
                          : tab === "featured"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        if (user?.data?.membership?.id != 1) {
                          setTab("featured");
                          navigate("/jobs", { state: { isFeatured: true } });
                        } else {
                          toast.error(
                            "To access this feature, please upgrade your membership"
                          );
                        }
                      }}
                    >
                      <div className="job-list">
                        <span className="title">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1603_26080)">
                              <path
                                d="M9.24412 0.487323C9.51804 0.396795 9.83152 0.61828 9.84148 0.905096C9.84646 1.22883 9.84324 1.55285 9.84324 1.87687C9.8488 2.12648 9.62322 2.3506 9.3739 2.34328C9.12517 2.34855 8.89929 2.12414 8.90691 1.87482C8.90603 1.56193 8.90603 1.24904 8.90691 0.936151C8.90339 0.733416 9.049 0.541229 9.24412 0.487323Z"
                                fill="#676A79"
                              />
                              <path
                                d="M6.37227 1.53931C6.50146 1.273 6.89785 1.20093 7.10498 1.41714C9.0752 3.38296 11.0431 5.35171 13.0124 7.3187C13.0991 7.40015 13.1826 7.49448 13.2084 7.6146C13.2638 7.82466 13.1486 8.05903 12.9521 8.14897C11.271 9.04458 9.5917 9.9437 7.90918 10.8367C6.50293 9.42778 5.09375 8.02182 3.6875 6.61323C4.58223 4.92192 5.47666 3.23003 6.37227 1.53931Z"
                                fill="#676A79"
                              />
                              <path
                                d="M12.1045 1.88267C12.309 1.84341 12.5287 1.95826 12.6122 2.14898C12.6916 2.32154 12.6547 2.54068 12.5182 2.67398C12.2006 2.99214 11.8839 3.31148 11.5637 3.62701C11.3897 3.79458 11.0844 3.78755 10.9177 3.61294C10.7425 3.44624 10.7343 3.1398 10.903 2.96578C11.2089 2.65582 11.5189 2.34996 11.8256 2.04058C11.8997 1.96148 11.9952 1.89908 12.1045 1.88267Z"
                                fill="#676A79"
                              />
                              <path
                                d="M12.5699 4.69484C12.9104 4.67785 13.2523 4.69133 13.593 4.68781C13.8426 4.68136 14.0655 4.90754 14.0597 5.15627C14.0658 5.40617 13.8402 5.63117 13.5901 5.62414C13.2777 5.62502 12.9654 5.62502 12.6531 5.62414C12.4466 5.62707 12.2509 5.47472 12.2005 5.27521C12.1255 5.02179 12.3092 4.73439 12.5699 4.69484Z"
                                fill="#676A79"
                              />
                              <path
                                d="M3.47086 7.72461C4.57858 8.82969 5.68453 9.93652 6.7902 11.0437C6.40992 11.4236 6.02877 11.8033 5.64762 12.1824C5.52457 12.3031 5.40797 12.4332 5.26412 12.5302C4.95445 12.7438 4.55748 12.8237 4.18922 12.7479C3.91442 12.6945 3.65895 12.5542 3.46266 12.3553C3.02789 11.9211 2.59313 11.4869 2.15895 11.0519C1.9858 10.8811 1.85602 10.666 1.79127 10.4313C1.68024 10.0423 1.74557 9.60693 1.96969 9.26973C2.06402 9.12236 2.1941 9.004 2.31656 8.88066C2.70123 8.49512 3.08707 8.11104 3.47086 7.72461Z"
                                fill="#676A79"
                              />
                              <path
                                d="M1.04291 11.083C1.16156 11.315 1.31273 11.5304 1.49906 11.7132C1.88959 12.1046 2.28041 12.4951 2.67123 12.8859C2.89213 13.1183 3.13734 13.3345 3.43148 13.4692C3.03012 13.6181 2.57572 13.6134 2.17406 13.4675C1.72641 13.3052 1.34701 12.965 1.13432 12.5396C0.908437 12.0926 0.875624 11.5547 1.04291 11.083Z"
                                fill="#676A79"
                              />
                              <path
                                d="M6.3998 12.7594C6.75283 12.4081 7.10527 12.0565 7.4583 11.7056C7.72285 11.9675 7.98477 12.2317 8.24844 12.4945C8.55957 12.7837 8.69697 13.2442 8.58564 13.6553C8.49775 14.0033 8.24053 14.3042 7.90771 14.4404C7.48438 14.6212 6.95967 14.5236 6.63359 14.1973C6.34209 13.9081 6.05264 13.6169 5.76172 13.3269C6.0043 13.1736 6.19707 12.9589 6.3998 12.7594Z"
                                fill="#676A79"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1603_26080">
                                <rect width="15" height="15" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          Featured Jobs
                        </span>
                        {user?.data?.membership?.id !== 1 && (
                          <span className="number">{totalFeaturedCount}</span>
                        )}
                      </div>
                    </li>
                    <li
                      className={
                        user?.data?.membership?.id == 1
                          ? "opacity-25"
                          : tab === "camparision"
                          ? "active"
                          : ""
                      }
                      onClick={() => {
                        if (user?.data?.membership?.id != 1) {
                          toggleSalaryComparision();
                          setTab("comparision");
                        } else {
                          toast.error(
                            "To access this feature, please upgrade your membership"
                          );
                        }
                      }}
                    >
                      <div className="job-list">
                        <span className="title">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1603_26095)">
                              <path
                                d="M9.64775 0H9.73359C9.80801 0.0213867 9.87773 0.0588867 9.93223 0.114258C11.5825 1.76514 13.2334 3.41543 14.8837 5.06602C14.9388 5.12021 14.9769 5.18848 15 5.26201V5.36074C14.978 5.43018 14.9446 5.49697 14.8931 5.54912C11.8321 8.61035 8.77119 11.6716 5.71025 14.7325C5.60713 14.8339 5.5125 14.9563 5.36807 15H5.28281C5.13223 14.9786 5.03818 14.8518 4.93711 14.7527C3.38408 13.1991 1.83047 11.6458 0.277148 10.0925C0.172852 9.98467 0.0416016 9.88945 0 9.7377V9.63867C0.0380859 9.48955 0.166113 9.39551 0.26748 9.29062C3.3293 6.22939 6.39023 3.16787 9.45176 0.106641C9.50508 0.0527344 9.57451 0.018457 9.64775 0ZM12.6958 5.02324C12.5757 5.08037 12.4975 5.19375 12.4017 5.28164C12.0498 5.63408 11.6965 5.98477 11.3458 6.33838C11.2137 6.45938 11.221 6.69053 11.3619 6.80156C11.4823 6.90703 11.6789 6.89443 11.7879 6.77812C12.2001 6.36914 12.6097 5.95723 13.0204 5.54678C13.1227 5.45215 13.1563 5.29014 13.0869 5.1668C13.0175 5.02881 12.8382 4.96318 12.6958 5.02324ZM6.65801 5.63818C6.37031 5.66572 6.08936 5.79727 5.90215 6.02051C5.68447 6.271 5.60742 6.61758 5.63643 6.94277C5.67686 7.40244 5.88193 7.83311 6.15527 8.19932C6.51797 8.67773 7.00664 9.07266 7.57676 9.27246C7.93623 9.39551 8.34346 9.43447 8.70205 9.28857C8.99707 9.17109 9.23057 8.91445 9.32197 8.61064C9.43887 8.23506 9.37236 7.82666 9.23086 7.46719C8.99971 6.89941 8.58252 6.41631 8.08037 6.06973C7.66494 5.79023 7.16602 5.59219 6.65801 5.63818ZM3.38232 8.13076C3.30234 8.14248 3.23789 8.19463 3.18252 8.24971C2.78291 8.65166 2.38125 9.05127 1.98105 9.45234C1.89082 9.53555 1.84951 9.66914 1.8917 9.78662C1.93535 9.92461 2.08359 10.0178 2.22715 9.99668C2.31035 9.98848 2.38037 9.93809 2.4375 9.88037C2.82393 9.49395 3.20947 9.10664 3.59707 8.72168C3.67705 8.64639 3.75557 8.55176 3.74854 8.43457C3.75293 8.24854 3.56426 8.09238 3.38232 8.13076Z"
                                fill="#676A79"
                              />
                              <path
                                d="M0.384375 7.45342C0.825586 7.29287 1.26504 7.12764 1.70654 6.96826C1.20967 7.46514 0.712793 7.9626 0.215332 8.45918C0.144141 8.2585 0.065332 8.06045 0 7.85771V7.76279C0.0254883 7.68867 0.0568359 7.60957 0.124805 7.56475C0.203613 7.51143 0.296484 7.48682 0.384375 7.45342Z"
                                fill="#676A79"
                              />
                              <path
                                d="M11.4824 9.84512C12.3057 9.02188 13.1283 8.19805 13.9524 7.37598C14.3014 8.3416 14.6605 9.304 15.0016 10.2726V10.3367C14.9857 10.424 14.9497 10.5146 14.8718 10.5644C14.7833 10.6209 14.6808 10.649 14.5838 10.6862C12.5046 11.4503 10.4271 12.2193 8.34766 12.9822C9.21572 12.1115 10.0873 11.244 10.9551 10.3728C11.0049 10.4398 11.0523 10.5181 11.135 10.5488C11.2299 10.5939 11.338 10.5679 11.4317 10.5321C11.9242 10.3464 12.4199 10.17 12.9124 9.98457C13.0627 9.93418 13.1591 9.76514 13.1157 9.61074C13.0762 9.4373 12.8746 9.32861 12.7085 9.3957C12.2989 9.54336 11.892 9.69746 11.4824 9.84512Z"
                                fill="#676A79"
                              />
                              <path
                                d="M1.62109 12.3242C2.3875 13.0854 3.1498 13.8506 3.91299 14.6146C3.55937 14.7424 3.20928 14.8807 2.85273 14.9999H2.78945C2.70977 14.9832 2.6251 14.9557 2.57383 14.8883C2.51406 14.8095 2.48887 14.7122 2.45459 14.6214C2.17832 13.8553 1.89619 13.0909 1.62109 12.3242Z"
                                fill="#676A79"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1603_26095">
                                <rect width="15" height="15" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          Salary comparison
                        </span>
                      </div>
                    </li>
                    <li className="" onClick={() => setJobAlert(true)}>
                      <div className="job-list">
                        <span className="title">
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M7.42848 0H7.56471C7.84801 0.0331055 8.09557 0.270117 8.11901 0.558105C8.13072 0.805078 8.11988 1.05293 8.12428 1.3002C8.8857 1.40918 9.61549 1.72471 10.217 2.2043C11.0056 2.82744 11.5702 3.72949 11.7773 4.71387C11.915 5.32061 11.864 5.9458 11.8737 6.5625C11.8816 7.06172 11.845 7.56563 11.932 8.06045C12.0735 8.92207 12.4954 9.73389 13.1173 10.3465C13.2621 10.4956 13.4399 10.6146 13.5565 10.7895C13.744 11.0599 13.7982 11.417 13.6986 11.7308C13.5659 12.1775 13.1226 12.5062 12.6559 12.4995C9.21793 12.5004 5.77965 12.5004 2.34166 12.4995C1.92418 12.5033 1.52047 12.2435 1.34938 11.8629C1.19234 11.5283 1.2234 11.1146 1.42848 10.807C1.54947 10.6175 1.74195 10.4933 1.8943 10.3321C2.54527 9.68408 2.9733 8.81719 3.08815 7.90547C3.1359 7.55713 3.12154 7.20498 3.12389 6.8543C3.12506 6.40605 3.12096 5.95752 3.12594 5.50928C3.1482 4.46426 3.56832 3.43594 4.27936 2.67041C4.95318 1.93477 5.88482 1.44111 6.87301 1.3002C6.87799 1.05205 6.86686 0.80332 6.87857 0.555469C6.9026 0.269824 7.14781 0.0357422 7.42848 0Z"
                              fill="#676A79"
                            />
                            <path
                              d="M5.20312 13.1257C6.73418 13.1248 8.26523 13.1248 9.79629 13.1257C9.67266 13.7224 9.30908 14.265 8.80107 14.6028C8.44893 14.8422 8.03027 14.9758 7.60605 15.0001H7.37256C6.90703 14.9684 6.45029 14.8032 6.08027 14.5176C5.6332 14.1789 5.3168 13.6747 5.20312 13.1257Z"
                              fill="#676A79"
                            />
                          </svg>
                          Job Alert
                        </span>
                      </div>
                    </li>
                    <li>
                      <div className="job-list">
                        <span
                          className="title"
                          onClick={() => navigate("/interview-preperation")}
                        >
                          <svg
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1694_29450)">
                              <path
                                d="M1.56357 0H5.90596C6.52148 0.0161133 7.10508 0.415137 7.33945 0.984961C7.45459 1.24512 7.47773 1.53369 7.46924 1.81494C7.46836 2.46006 7.47012 3.10518 7.46807 3.75029C7.4666 3.8001 7.47363 3.85137 7.50498 3.89209C7.77861 4.30166 8.05342 4.71094 8.32529 5.12197C8.43164 5.27256 8.43691 5.48701 8.32588 5.63643C8.24121 5.7624 8.08799 5.82305 7.93975 5.8207C5.84033 5.82129 3.74062 5.821 1.64092 5.8207C1.19678 5.82744 0.752637 5.64521 0.447656 5.32119C0.176367 5.03848 0.0181641 4.65557 0 4.26504V1.56445C0.0149414 1.20732 0.144434 0.854004 0.376758 0.580664C0.665625 0.228809 1.10977 0.0164062 1.56357 0ZM2.29336 1.65C2.17412 1.65703 2.05957 1.71533 1.98457 1.8082C1.86445 1.94883 1.84951 2.16973 1.95527 2.32266C2.03848 2.45273 2.19287 2.52773 2.3458 2.5251C3.2625 2.52686 4.17949 2.5248 5.09619 2.52627C5.21631 2.53008 5.34229 2.50137 5.43311 2.41816C5.58633 2.29102 5.63086 2.05312 5.53154 1.88027C5.44922 1.72559 5.27168 1.63887 5.09941 1.64678C4.16426 1.64912 3.22852 1.64297 2.29336 1.65ZM2.26904 3.3C2.04873 3.32109 1.87002 3.53145 1.88467 3.75234C1.88848 3.97764 2.09414 4.16836 2.3168 4.17187C2.76504 4.17686 3.21328 4.17187 3.66152 4.17422C3.78574 4.17861 3.91553 4.15166 4.01191 4.06934C4.17861 3.94072 4.22227 3.68643 4.10977 3.50889C4.02686 3.36299 3.85635 3.28564 3.6917 3.29473C3.21768 3.29853 2.74277 3.28828 2.26904 3.3Z"
                                fill="#676A79"
                              />
                              <path
                                d="M11.2894 0.954933C11.6802 0.909523 12.0857 0.993019 12.4247 1.1937C12.7982 1.41226 13.0891 1.76851 13.228 2.17866C13.3862 2.63482 13.3516 3.15396 13.1339 3.58521C12.9224 4.01353 12.5351 4.35015 12.0822 4.50161C11.817 4.59771 11.5305 4.60386 11.2525 4.58042C10.726 4.51157 10.2391 4.1978 9.9564 3.74868C9.71412 3.37046 9.62066 2.90025 9.69888 2.45816C9.76539 2.06148 9.96988 1.69087 10.2672 1.42046C10.5476 1.16265 10.9106 0.996828 11.2894 0.954933Z"
                                fill="#676A79"
                              />
                              <path
                                d="M11.1002 4.62871C11.9428 4.49658 12.8372 4.79277 13.4448 5.38955C13.901 5.83018 14.1966 6.43076 14.2722 7.06006C12.4235 7.06065 10.5752 7.06035 8.72656 7.06035C8.80098 6.43926 9.08955 5.84629 9.53574 5.40742C9.95586 4.98906 10.5131 4.71104 11.1002 4.62871Z"
                                fill="#676A79"
                              />
                              <path
                                d="M3.11837 6.61361C3.58858 6.54564 4.08048 6.63763 4.49298 6.87435C4.90987 7.11282 5.24473 7.49339 5.42286 7.94017C5.59952 8.37523 5.62706 8.86829 5.50137 9.32064C5.36573 9.82249 5.0376 10.2672 4.60313 10.5514C4.25596 10.7837 3.83966 10.8927 3.4254 10.9109C2.99796 10.8936 2.56876 10.7761 2.21485 10.5306C1.76925 10.2294 1.44346 9.75599 1.32452 9.23128C1.20118 8.70159 1.28702 8.12562 1.56505 7.65775C1.88848 7.09759 2.47706 6.70062 3.11837 6.61361Z"
                                fill="#676A79"
                              />
                              <path
                                d="M6.3583 7.96926C9.08232 7.96897 11.8066 7.96868 14.5307 7.96926C14.7562 7.95344 14.9599 8.12717 14.9991 8.34631V13.642C14.9877 13.8559 14.8072 14.046 14.5913 14.0563C14.3604 14.0791 14.141 13.8852 14.1255 13.6561C14.1126 13.4768 14.1237 13.2969 14.1199 13.1173C11.9719 13.1179 9.82383 13.1153 7.67607 13.1185C7.63652 13.1132 7.58906 13.1267 7.55478 13.1053C7.48682 12.9515 7.45078 12.7854 7.37607 12.6342C7.0415 11.8452 6.45879 11.1705 5.74219 10.7035C6.07383 10.306 6.30762 9.82639 6.40371 9.31721C6.49248 8.87131 6.4626 8.4093 6.3583 7.96926Z"
                                fill="#676A79"
                              />
                              <path
                                d="M1.08896 11.8399C1.65586 11.3 2.41377 10.9613 3.19629 10.9147C3.33193 10.9124 3.46758 10.9171 3.60322 10.9136C4.70801 10.954 5.7627 11.5883 6.33662 12.5299C6.70605 13.1202 6.87891 13.826 6.84375 14.52C6.82207 14.7834 6.58242 14.9967 6.32021 15.0002H0.512109C0.24375 14.9876 0.0134766 14.7579 0 14.4896V14.3105C0.0166992 13.3856 0.411914 12.4725 1.08896 11.8399Z"
                                fill="#676A79"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1694_29450">
                                <rect width="15" height="15" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          Prepare for Interview
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div style={{ marginTop: "26px" }}>
                  <Refer />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="my-jobs-section mt-4">
                  <div className="head">
                    <h4>
                      {tab === "my"
                        ? "My jobs"
                        : tab === "posted"
                        ? "Posted jobs"
                        : "All jobs"}
                    </h4>
                    <select
                      name="select"
                      className="form-select"
                      value={sortBy}
                      onChange={(e) => setSortBy(e.target.value)}
                    >
                      <option value="desc">Latest</option>
                      <option value="asc">Older</option>
                    </select>
                  </div>

                  <ul className="nav subTabs" role="tablist">
                    {subTabs?.map((item, indx) => (
                      <li className="" key={indx}>
                        <div
                          className={
                            "nav-link " + (indx === activeTab ? "active" : "")
                          }
                          data-bs-toggle="tab"
                          onClick={() => {
                            // if (item === "Smart Interview Sent") {
                            //   alert(
                            //     "This feature will be implemented in upcomming milestones"
                            //   );
                            //   return;
                            // }
                            setActiveTab(indx);
                            setSelectedSubTab(item);
                          }}
                        >
                          {item === "Smart Interview Sent"
                            ? "Smart Interview"
                            : item}{" "}
                          {getAllJobs?.length > 0 && indx === activeTab ? (
                            <>
                              (
                              {item === "Saved" ? (
                                `${totalSaveCount}`
                              ) : item === "Applied" ? (
                                `${totalAppliedCount}`
                              ) : item === "Smart Interview Sent" ? (
                                `${totalSmartCount}`
                              ) : item === "In Progress" ? (
                                `${totalInProgressCount}`
                              ) : item === "Closed" ? (
                                `
                                ${totalClosedCount}
                              `
                              ) : (
                                <></>
                              )}
                              )
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>

                  {selectedSubTab === "Saved" ? (
                    <MyJobView
                      getAllJobs={getSavedJobs}
                      loadMore={loadMore}
                      isMoreJobs={isMoreJobs}
                      selectedSubTab={selectedSubTab}
                      handleClose={handleClose}
                    />
                  ) : selectedSubTab === "Applied" ? (
                    <MyJobView
                      getAllJobs={getAppliedJobs}
                      loadMore={loadMore}
                      isMoreJobs={isMoreJobs}
                      selectedSubTab={selectedSubTab}
                    />
                  ) : selectedSubTab === "Smart Interview Sent" ? (
                    <MyJobView
                      getAllJobs={getSentInterviewJobs}
                      loadMore={loadMore}
                      isMoreJobs={isMoreJobs}
                      selectedSubTab={selectedSubTab}
                    />
                  ) : selectedSubTab === "In Progress" ? (
                    <MyJobView
                      getAllJobs={getInProgressJobs}
                      loadMore={loadMore}
                      isMoreJobs={isMoreJobs}
                      selectedSubTab={selectedSubTab}
                    />
                  ) : selectedSubTab === "Closed" ? (
                    <MyJobView
                      getAllJobs={getClosedJobs}
                      loadMore={loadMore}
                      isMoreJobs={isMoreJobs}
                      selectedSubTab={selectedSubTab}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="middle mt-4">
                  <Advertisement />

                  <div style={{ marginTop: "26px" }}>
                    <Ad />
                  </div>
                  <div style={{ marginTop: "26px" }}>
                    {getFeaturedJobs?.map((item, idx) => (
                      <SideJobCard
                        key={idx}
                        jobData={item}
                        notShowFeatured={true}
                      />
                    ))}
                  </div>
                  {totalFeaturedCount > 3 && (
                    <div
                      className="see-more-featured"
                      onClick={() =>
                        navigate("/jobs", { state: { isFeatured: true } })
                      }
                    >
                      See more featured jobs
                    </div>
                  )}
                </div>
                <div className="sidebar-sticky">
                  <SidebarFooter isNoBackground={true} />
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
          <Chat />
        </div>
        {jobAlert && (
          <JobAlertModal
            modal={jobAlert}
            toggle={() => setJobAlert(!jobAlert)}
          />
        )}
        <ToastContainer />
      </div>
      {showSalaryComparision && (
        <SalaryComparision
          toggle={() => toggleSalaryComparision()}
          isOpen={showSalaryComparision}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default MyJobs;
