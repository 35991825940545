import React, { useEffect, useState } from "react";
import newcandi from "../../../assets/img/profile-2user.svg";
import shortcandi from "../../../assets/img/profile-tick.svg";
import plus from "../../../assets/img/plus-orange.svg";
import postnew from "../../../assets/img/postnewjob.svg";
import uidesign from "../../../assets/img/bezier.svg";
import close from "../../../assets/img/Close-red.svg";
import placeholderUser from "../../../assets/img/default_profile_sqr img.svg";
import list from "../../../assets/img/shopping-list.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  closeCompanyJob,
  getApplicants,
  getJobs,
  updateApplicant,
} from "../../../store/talentPool/talentPoolAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { initLoad, stopLoad } from "../../../store/loader/loaderActions";
import { toast } from "react-toastify";
import { UPDATE_APPLICANTS } from "../../../store/types";

const HomeTab = (props) => {
  const { setPostJobModal, getJob } = props;
  const [currentCount, setCurrectCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { portal, jobs } = useSelector((state) => state.talentPool);
  const { id } = useParams();
  useEffect(() => {
    if (getJob) {
      setCurrectCount(0);
      dispatch(
        getJobs(
          {
            status: "active",
            company_id: portal?.company_id,
            limit: 10,
            skip: 0,
            sort: "desc",
          },
          0,
          "company"
        )
      ).then((res) => {
        setTotalCount(res.totalItem);
      });
    }
  }, [getJob]);

  useEffect(() => {
    if (currentCount) {
      dispatch(
        getJobs(
          {
            status: "active",
            company_id: portal?.company_id,
            limit: 10,
            skip: currentCount,
            sort: "desc",
          },
          currentCount,
          "company"
        )
      ).then((res) => {
        setTotalCount(res.totalItem);
      });
    }
  }, [currentCount]);

  const getApplicantsByJob = (jobId) => {
    const accordionBody = document.getElementById(`collapse-${jobId}`);

    setTimeout(() => {
      const isCollapsed = accordionBody.classList.contains("show");
      if (isCollapsed) dispatch(getApplicants({ job_id: jobId, limit: 6 }));
    }, 500);
  };

  const closeJob = (e, jobId) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(initLoad());
    dispatch(closeCompanyJob(jobId))
      .then((res) => dispatch(stopLoad()))
      .catch((err) => dispatch(stopLoad()));
  };

  const handleShortlist = (id, jobId) => {
    dispatch(initLoad());
    dispatch(updateApplicant({ status: "shortlisted" }, id))
      .then((response) => {
        dispatch({
          type: UPDATE_APPLICANTS,
          payload: { id, jobId, status: "shortlisted" },
        });
        dispatch(stopLoad());
        toast.success("Shortlisted successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        dispatch(stopLoad());
        console.error("Error", error);
      });
  };

  const handleReject = (id, jobId) => {
    dispatch(initLoad());
    dispatch(updateApplicant({ status: "rejected" }, id))
      .then((response) => {
        dispatch({
          type: UPDATE_APPLICANTS,
          payload: { id, jobId, status: "rejected" },
        });
        dispatch(stopLoad());
        toast.success("Rejected successfully", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        dispatch(stopLoad());
        console.error("Error", error);
      });
  };
  return (
    <div className="homepage">
      <div>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 col-sm-6 col-xs-12">
            <div className="card border-0">
              <div
                className="card-body d-flex flex-column justify-content-center "
                style={{ paddingLeft: "50px" }}
              >
                <div className="d-flex">
                  <img src={newcandi} alt="new candidate" />
                  <h3 className="ms-3">{portal?.new_member_count || 0}</h3>
                </div>
                <h5 className="card-title mt-2">New Candidate Applications</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 col-sm-6 col-xs-12">
            <div className="card border-0">
              <div
                className="card-body d-flex flex-column justify-content-center "
                style={{ backgroundColor: "#8cc5d545", paddingLeft: "50px" }}
              >
                <div className="d-flex">
                  <img src={shortcandi} alt="new candidate" />
                  <h3 className="ms-3">{portal?.shortlisted_count || 0}</h3>
                </div>
                <h5 className="card-title mt-2">Shortlisted Candidate</h5>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 col-sm-6 col-xs-12">
            <div
              className="card border-0"
              style={{ cursor: "pointer" }}
              onClick={() => setPostJobModal(true)}
            >
              <div
                className="card-body p-0"
                style={{ backgroundColor: `${portal?.theme}` }}
              >
                <div className="d-flex mt-2 ps-4 justify-content-center align-items-center">
                  <div className="plus bg-white p-2 rounded-circle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5V19"
                        stroke={`${portal?.theme}`}
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 12H19"
                        stroke={`${portal?.theme}`}
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <h5 className="card-title mt-2 ms-4 text-white">
                    Post New Job
                  </h5>
                  <img src={postnew} className="job-icon" alt="new candidate" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4 ">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center bg-white p-3">
            <h4 className="text-dark">{totalCount} open recruitment</h4>
          </div>
        </div>
      </div>
      <div className="row mt-3 job-position ">
        <div className="col-12">
          <div className="row p-2">
            <div className="col-lg-3">
              <h5>Position</h5>
            </div>
            <div className="col-lg-3">
              <h5>Vacants</h5>
            </div>
            <div className="col-lg-3">
              <h5>Candidates</h5>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
      <div className="row mt-3 job-position-desc ">
        <div className="col-12 ">
          <div className="accordion" id="accordionExample">
            {jobs?.length > 0 ? (
              <InfiniteScroll
                dataLength={jobs?.length ? jobs?.length : 0}
                next={() => setCurrectCount(currentCount + 10)}
                hasMore={jobs?.length < totalCount}
                style={{ overflow: "visible" }}
              >
                <div className="my-job-nav-list-outer">
                  {jobs?.map((item, index) => (
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`heading-${item?.id}`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse-${item?.id}`}
                          aria-expanded="false"
                          aria-controls={`collapse-${item?.id}`}
                          onClick={() => getApplicantsByJob(item?.id)}
                        >
                          <div
                            className="row"
                            style={{
                              width: "100%",
                              flexWrap: "inherit",
                              alignItems: "center",
                            }}
                          >
                            <div className="d-flex align-items-center col-lg-3 ms-3">
                              <div className="bg-red job-icon">
                                <img
                                  src={
                                    item?.company?.icon ||
                                    // item?.user?.profile_img
                                    uidesign
                                  }
                                  height={50}
                                  width={50}
                                  alt="ui"
                                  className="rounded-circle"
                                />
                              </div>
                              <h5
                                className="card-control-text-oneline text-start"
                                title={item?.title}
                              >
                                {item?.title}
                              </h5>
                            </div>

                            <div className=" col-lg-3">
                              <h5 className="vacant-no ms-3 ">
                                {item?.vacancies}
                              </h5>
                            </div>
                            <div className=" col-lg-3">
                              <h5 className="ms-3">
                                {item?.applied_count} Applicants
                              </h5>
                            </div>

                            <div className=" col-lg-3">
                              <div
                                className="btn close ms-3"
                                onClick={(e) => closeJob(e, item?.id)}
                              >
                                <img src={close} alt="ui" />
                                Close Job
                              </div>
                            </div>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`collapse-${item?.id}`}
                        className="accordion-collapse collapse"
                      >
                        {item?.applicants?.length ? (
                          <div className="accordion-body">
                            {item?.applicants?.map((applicant, i) => (
                              <div className="row  mb-3" key={i}>
                                <div className="col-12">
                                  <div className="jop-applicatns">
                                    <div
                                      className="col-4 d-flex align-items-center"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div className="flex-shrink-0">
                                        <img
                                          src={
                                            applicant?.user?.profile_img ||
                                            placeholderUser
                                          }
                                          alt="ui"
                                          className="profile"
                                          onClick={() =>
                                            navigate(
                                              `/tp/${id}/applicant/details/${applicant?.id}`
                                            )
                                          }
                                        />
                                      </div>

                                      <h4
                                        className="card-control-text-oneline"
                                        onClick={() =>
                                          navigate(
                                            `/tp/${id}/applicant/details/${applicant?.id}`
                                          )
                                        }
                                      >
                                        {applicant?.user?.first_name}{" "}
                                        {applicant?.user?.last_name}
                                      </h4>
                                    </div>
                                    <p className="col-5 card-control-text-oneline">
                                      {applicant?.user?.profile?.profile_headline?.trim() ||
                                        "-"}
                                    </p>
                                    {applicant?.apply_status === "pending" ? (
                                      <div className="col-1 d-flex g-2">
                                        <div className="b-white action">
                                          <img
                                            src={list}
                                            alt="ui"
                                            className=""
                                            onClick={
                                              () =>
                                                handleShortlist(
                                                  applicant?.id,
                                                  item?.id
                                                )
                                              // navigate("/applicant/details")
                                            }
                                          />
                                        </div>
                                        <div className="b-white action">
                                          <img
                                            src={close}
                                            alt="ui"
                                            className=""
                                            onClick={() =>
                                              handleReject(
                                                applicant?.id,
                                                item?.id
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    ) : applicant?.apply_status ===
                                      "shortlisted" ? (
                                      <div className="px-2">Shortlisted</div>
                                    ) : applicant?.apply_status ===
                                      "rejected" ? (
                                      <div className="px-2">Rejected</div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="accordion-body">
                            <div className="text-center">No Applicants</div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </InfiniteScroll>
            ) : (
              <div className="my-job-nav-list-outer d-flex justify-content-center mt-3">
                <h6 className="create">No Jobs</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTab;
