import React, { useEffect, useState } from "react";
import plus from "../../../assets/img/plus-orange.svg";
import postnew from "../../../assets/img/postnewjob.svg";
import { PhoneNumberUtil } from "google-libphonenumber";
import { useNavigate } from "react-router-dom";
import MobileInput from "../../../core/components/Inputs/MobileInput";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "reactstrap";
import { call } from "../../../api/apiCall";
import { updateTalentPool } from "../../../store/talentPool/talentPoolAction";
import {
  getJobsMessage,
  updateJobsMessage,
} from "../../../store/jobs/jobAction";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { initLoad, stopLoad } from "../../../store/loader/loaderActions";

const SettingTab = (props) => {
  const urlRegex = /^(?=.*[a-zA-Z])[a-zA-Z0-9]+$/;
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { portal } = useSelector((state) => state.talentPool);
  const { setPostJobModal } = props;
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [phoneNum, setPhoneNum] = useState(portal?.phone || null);
  const [phoneCode, setPhoneCode] = useState("");
  const [settingTab, setSettingTab] = useState("job");
  const [pageNavURLStatus, setPageNavURLStatus] = useState(true);
  const [urlErrorMessage, setUrlErrorMessage] = useState("");
  const [isUrlEdit, setIsUrlEdit] = useState(false);
  const [isPhoneEdit, setIsPhoneEdit] = useState(false);
  const [pageDetails, setPageDetails] = useState({
    url: portal?.url || "",
    phone: portal?.phone || "",
  });
  const [shortlistEditMode, setShortlistEditMode] = useState(false);
  const [rejectionEditMode, setRejectionEditMode] = useState(false);
  const [closeEditMode, setCloseEditMode] = useState(false);
  const [formData, setFormData] = useState({
    job_shortlist_message: "",
    job_reject_message: "",
    job_close_message: "",
  });
  const [errorData, setErrorData] = useState({
    job_shortlist_message: "",
    job_reject_message: "",
    job_close_message: "",
  });
  const { getJobsMessageData } = useSelector((state) => state.jobs);

  useEffect(() => {
    if (settingTab == "job") dispatch(getJobsMessage());
  }, [settingTab]);

  useEffect(() => {
    setFormData({
      job_shortlist_message: getJobsMessageData?.job_shortlist_message,
      job_reject_message: getJobsMessageData?.job_reject_message,
      job_close_message: getJobsMessageData?.job_close_message,
    });
  }, [getJobsMessageData]);

  const jobMessageValidate = (type) => {
    if (!formData[type]?.trim()) {
      setErrorData({
        ...errorData,
        [type]: `Job ${type?.split("_")[1]} message is not valid`,
      });
      return false;
    }
    setErrorData({
      ...errorData,
      [type]: ``,
    });
    return true;
  };

  const handleSave = (type) => {
    if (jobMessageValidate(type)) {
      dispatch(initLoad());
      dispatch(
        updateJobsMessage({
          [type]: formData[type],
        })
      )
        .then((res) => {
          dispatch(stopLoad());
          if (res.success) {
            toast.success("Job Message updated successfuly", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            if (type == "job_shortlist_message") setShortlistEditMode(false);
            else if (type == "job_reject_message") setRejectionEditMode(false);
            else if (type == "job_close_message") setCloseEditMode(false);
          }
        })
        .catch(() => dispatch(stopLoad()));
    }
  };

  const checkUrl = (url) => {
    if (!url?.trim()) {
      setUrlErrorMessage("Field is required");
      return false;
    }
    if (!urlRegex.test(url)) {
      setUrlErrorMessage(" Url should be in valid format");
      return false;
    }
    setUrlErrorMessage("");
    return true;
  };

  const phoneUtil = PhoneNumberUtil.getInstance();
  const phoneCountValidation = () => {
    let num = JSON.stringify(pageDetails?.phone);
    let code = JSON.stringify(phoneCode);
    const number = phoneUtil.parse(num, code);
    return phoneUtil.isValidNumber(number);
  };

  const validation = (type) => {
    let formIsValid = true;

    if ((type = "all" || type === "phone")) {
      if (pageDetails?.phone?.length === 0) {
        setPhoneErrorMessage("Please enter the phone number.");
        formIsValid = false;
        return;
      } else if (pageDetails?.phone?.length < 10) {
        setPhoneErrorMessage("Please enter the valid number.");
        formIsValid = false;
        return;
      } else if (!phoneCountValidation()) {
        setPhoneErrorMessage("Please enter the valid number.");
        formIsValid = false;
        return;
      }
    }
    return formIsValid;
  };
  const checkUrlExist = async (url) => {
    if (portal?.url != url) {
      try {
        checkUrl(url);
        const res = await call(
          "get",
          `api/v1/portal/url-exist/${url}`,
          null,
          null,
          token
        );
        if (res.data.success === true) {
          setPageNavURLStatus(true);
        }
        if (res.data.success === false) {
          setPageNavURLStatus(false);
          setUrlErrorMessage(
            "This url already exist. Please enter another url"
          );
          return;
        }
      } catch (err) {
        setPageNavURLStatus(false);
        console.log(err);
        return;
      }
    } else setPageNavURLStatus(true);
  };
  const handleAddUrl = (e) => {
    e.preventDefault();

    dispatch(updateTalentPool({ url: pageDetails?.url }, portal?.id)).then(
      (res) => {
        if (res?.success) {
          setIsUrlEdit(false);
          setPageNavURLStatus(true);
          toast.success("Url updated successfuly", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      }
    );
  };

  const handleAddPhone = (e) => {
    e.preventDefault();
    if (validation("phone"))
      dispatch(
        updateTalentPool({ phone: pageDetails?.phone }, portal?.id)
      ).then((res) => {
        if (res?.success) {
          setIsPhoneEdit(false);
          toast.success("Phone number updated successfuly", {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      });
  };
  return (
    <div className="settingpage">
      <div class="">
        <div class="row">
          <div
            style={
              settingTab == "job"
                ? {
                    border: `1px solid ${portal?.theme}`,
                    cursor: "pointer",
                    borderRadius: 0,
                  }
                : { border: 0, cursor: "pointer" }
            }
            class="card col-lg-4 col-md-6 mb-4 col-sm-12"
            onClick={() => setSettingTab("job")}
          >
            <div class="card-body">
              <h5 class="card-title mt-2">Job Messages</h5>
              <h5 class="card-title mt-2">Settings</h5>
            </div>
          </div>

          <div
            style={
              settingTab == "talent"
                ? {
                    border: `1px solid ${portal?.theme}`,
                    cursor: "pointer",
                    borderRadius: 0,
                  }
                : { border: 0, cursor: "pointer" }
            }
            class="card card-talent card col-lg-4 col-md-6 mb-4 col-sm-12"
            onClick={() => setSettingTab("talent")}
          >
            <div class="card-body">
              <h5 class="card-title mt-2">Talent Pool</h5>
              <h5 class="card-title mt-2">Settings</h5>
            </div>
          </div>

          <div className="col-lg-4 mb-4 col-sm-12 col-md-6">
            <div
              className="card border-0"
              style={{ cursor: "pointer" }}
              onClick={() => setPostJobModal(true)}
            >
              <div
                className="card-body p-0"
                style={{ backgroundColor: `${portal?.theme}` }}
              >
                <div className="d-flex mt-2 ps-4 justify-content-center align-items-center">
                  <div className="plus bg-white p-2 rounded-circle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 5V19"
                        stroke={`${portal?.theme}`}
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5 12H19"
                        stroke={`${portal?.theme}`}
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <h5 className="card-title mt-2 ms-4 text-white">
                    Post New Job
                  </h5>
                  <img src={postnew} className="job-icon" alt="new candidate" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-4 bg-white">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-4">
          {settingTab === "job" && (
            <div className="seeting-job job-messages">
              <div className="shortlist">
                <div className="row">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="">Job Application Shortlisted Message</h4>
                    </div>
                    <div>
                      {!shortlistEditMode && (
                        <h4
                          className="edit"
                          style={{ color: portal?.theme }}
                          onClick={() => setShortlistEditMode(true)}
                        >
                          {formData?.job_shortlist_message ? "Edit" : "Add"}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>

                {shortlistEditMode ? (
                  <>
                    <div className="job-msg">
                      {/* <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                    ></textarea> */}
                      <textarea
                        class="form-control"
                        rows="5"
                        value={formData?.job_shortlist_message || ""}
                        onChange={(e) =>
                          setFormData((p) => ({
                            ...p,
                            job_shortlist_message: e.target.value,
                          }))
                        }
                      />
                      {errorData?.job_shortlist_message && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errorData?.job_shortlist_message}
                        </div>
                      )}
                    </div>{" "}
                    <div className="job-message-buttons">
                      <div
                        className="btn btn-org-outline"
                        style={{
                          color: portal?.theme,
                          border: `1px solid ${portal?.theme}`,
                        }}
                        onClick={() =>
                          setFormData((p) => ({
                            ...p,
                            job_shortlist_message: "",
                          }))
                        }
                      >
                        Remove
                      </div>
                      <div
                        className="btn btn-orange"
                        onClick={() => {
                          handleSave("job_shortlist_message");
                        }}
                        style={{ background: portal?.theme }}
                      >
                        Save
                      </div>
                    </div>
                  </>
                ) : (
                  <p>{formData?.job_shortlist_message}</p>
                )}
              </div>
              <div className="rejection mt-5">
                <div className="row">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4>Job Application Rejection Message</h4>
                    </div>
                    <div>
                      {!rejectionEditMode && (
                        <h4
                          className="edit"
                          onClick={() => setRejectionEditMode(true)}
                          style={{ color: portal?.theme }}
                        >
                          {formData?.job_reject_message ? "Edit" : "Add"}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>

                {rejectionEditMode ? (
                  <>
                    <div className="job-msg">
                      {/* <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                    ></textarea> */}
                      <textarea
                        class="form-control"
                        rows="5"
                        value={formData?.job_reject_message || ""}
                        onChange={(e) =>
                          setFormData((p) => ({
                            ...p,
                            job_reject_message: e.target.value,
                          }))
                        }
                      />
                      {errorData?.job_reject_message && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errorData?.job_reject_message}
                        </div>
                      )}
                    </div>{" "}
                    <div className="job-message-buttons">
                      <div
                        className="btn btn-org-outline"
                        onClick={() =>
                          setFormData((p) => ({
                            ...p,
                            job_reject_message: "",
                          }))
                        }
                        style={{
                          color: portal?.theme,
                          border: `1px solid ${portal?.theme}`,
                        }}
                      >
                        Remove
                      </div>
                      <div
                        className="btn btn-orange"
                        onClick={() => {
                          handleSave("job_reject_message");
                        }}
                        style={{ background: portal?.theme }}
                      >
                        Save
                      </div>
                    </div>
                  </>
                ) : (
                  <p>{formData?.job_reject_message}</p>
                )}
              </div>
              <div className="closed mt-5">
                <div className="row">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="">Job Ad Close Message</h4>
                    </div>
                    <div>
                      {!closeEditMode && (
                        <h4
                          className="edit"
                          onClick={() => setCloseEditMode(true)}
                          style={{ color: portal?.theme }}
                        >
                          {formData?.job_close_message ? "Edit" : "Add"}
                        </h4>
                      )}
                    </div>
                  </div>
                </div>

                {closeEditMode ? (
                  <>
                    <div className="job-msg">
                      {/* <textarea
                      class="form-control"
                      id="exampleFormControlTextarea1"
                      rows="5"
                    ></textarea> */}
                      <textarea
                        class="form-control"
                        rows="5"
                        value={formData?.job_close_message || ""}
                        onChange={(e) =>
                          setFormData((p) => ({
                            ...p,
                            job_close_message: e.target.value,
                          }))
                        }
                      />
                      {errorData?.job_close_message && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errorData?.job_close_message}
                        </div>
                      )}
                    </div>{" "}
                    <div className="job-message-buttons">
                      <div
                        className="btn btn-org-outline"
                        onClick={() =>
                          setFormData((p) => ({
                            ...p,
                            job_close_message: "",
                          }))
                        }
                        style={{
                          color: portal?.theme,
                          border: `1px solid ${portal?.theme}`,
                        }}
                      >
                        Remove
                      </div>
                      <div
                        className="btn btn-orange"
                        onClick={() => {
                          handleSave("job_close_message");
                        }}
                        style={{ background: portal?.theme }}
                      >
                        Save
                      </div>
                    </div>
                  </>
                ) : (
                  <p>{formData?.job_close_message}</p>
                )}
              </div>
            </div>
          )}
          {settingTab === "talent" && (
            <div className="setting-talent">
              <div className="talent-url">
                <div className="row">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="">Custom Talent Pool URL</h4>
                    </div>
                  </div>
                </div>
                <p>Edit your Talent Pool URL</p>
                <div className="talent-url-input align-items-center">
                  {isUrlEdit || !portal?.url ? (
                    <div className="col-sm-6">
                      <Input
                        type="text"
                        maxLength={30}
                        onChange={(e) => {
                          checkUrlExist(e.target.value);
                          setPageDetails({ url: e.target.value });
                        }}
                        value={pageDetails?.url}
                      />
                    </div>
                  ) : (
                    <small>{`${process.env.REACT_APP_WEB_STAGE_URL}tp/${
                      portal?.url || ""
                    }/dashboard`}</small>
                  )}
                  {isUrlEdit || !portal?.url ? (
                    <>
                      <button
                        className={
                          pageNavURLStatus && !urlErrorMessage
                            ? "btn btn-orange"
                            : "btn btn-grey ms-0 text-orange border-orange"
                        }
                        onClick={(e) =>
                          pageNavURLStatus &&
                          !urlErrorMessage &&
                          handleAddUrl(e)
                        }
                        style={{ background: portal?.theme }}
                      >
                        Add
                      </button>
                    </>
                  ) : (
                    // <img
                    //   src={edit}
                    //   alt="edit"
                    //   onClick={() => {
                    //     if (!isUrlEdit) setIsUrlEdit(!isUrlEdit);
                    //   }}
                    // />
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        if (!isUrlEdit) setIsUrlEdit(!isUrlEdit);
                      }}
                    >
                      <circle cx="18" cy="18" r="18" fill={portal?.theme} />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.5137 9.8065C20.5869 8.73174 22.3274 8.73108 23.4013 9.80503L25.8932 12.2969C26.958 13.3617 26.969 15.0854 25.918 16.1638L16.6849 25.637C15.9793 26.3609 15.0117 26.7691 14.0012 26.7691L11.2491 26.7689C9.96984 26.7689 8.94823 25.7025 9.00203 24.4234L9.12019 21.6144C9.15968 20.6754 9.54996 19.7854 10.2138 19.1205L19.5137 9.8065ZM22.3415 10.8665C21.8533 10.3783 21.0622 10.3786 20.5744 10.8672L18.9113 12.5328L23.1911 16.8125L24.8446 15.116C25.3224 14.6259 25.3173 13.8423 24.8333 13.3583L22.3415 10.8665ZM11.2745 20.1812L17.8514 13.5942L22.144 17.8868L15.6115 24.5893C15.1882 25.0236 14.6076 25.2685 14.0013 25.2685L11.2492 25.2684C10.8227 25.2684 10.4822 24.9129 10.5001 24.4865L10.6183 21.6775C10.642 21.1141 10.8762 20.5801 11.2745 20.1812ZM26.5148 26.6958C26.9289 26.6958 27.2645 26.3598 27.2645 25.9455C27.2645 25.5311 26.9289 25.1952 26.5148 25.1952H20.3931C19.9791 25.1952 19.6434 25.5311 19.6434 25.9455C19.6434 26.3598 19.9791 26.6958 20.3931 26.6958H26.5148Z"
                        fill="white"
                      />
                    </svg>
                  )}
                  {isUrlEdit && (
                    <small
                      className={
                        pageNavURLStatus
                          ? "available text-success"
                          : "available text-danger"
                      }
                    >
                      {pageNavURLStatus ? "Available" : "Unavailable"}
                    </small>
                  )}
                </div>
                {/* {!pageDetails?.url?.trim() ? (
                  <p className="text-danger mt-2">Field is Required</p>
                ) : !urlRegex.test(pageDetails?.url) ? (
                  <p className="text-danger mt-2">
                    Url should be in valid format
                  </p>
                ) : (
                  ""
                )} */}

                {urlErrorMessage && (
                  <p className="text-danger mt-2">{urlErrorMessage}</p>
                )}
              </div>
              <div className="talent-email mt-5">
                <div className="row">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4>Email Address</h4>
                    </div>
                  </div>
                </div>
                <p>{portal?.email}</p>
                {/* <div className="talent-url-input">
                  <small>https://nobelpage.com/np/james.rodriguz</small>
                  <img src={edit} alt="edit" />
                  <small className="available">Available</small>
                </div> */}
              </div>
              <div className="talent-phone mt-5">
                <div className="row">
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="">Add Phone Number</h4>
                    </div>
                  </div>
                </div>
                <p>
                  Enter the email address associated with the account you wish
                  to merge into the account you are keeping.
                </p>
                <div className="talent-url-input align-items-center">
                  {/* <small>https://nobelpage.com/np/james.rodriguz</small>
                  <img src={edit} alt="edit" />
                  <small className="available">Available</small> */}
                  {isPhoneEdit || !portal?.phone ? (
                    <div className="col-sm-6">
                      {/* <MobileInput
                        label="Phone Number*"
                        value={pageDetails?.phone}
                        onChange={(value, country) => {
                          setPageDetails({
                            ...pageDetails,
                            phone: `+${country.dialCode}-${value.slice(
                              country.dialCode.length
                            )}`,
                          });
                          setPhoneCode(country.countryCode);
                          setPhoneErrorMessage("");
                        }}
                        error={phoneErrorMessage}
                        onBlur={() => validation("phone")}
                      /> */}
                      <PhoneInput
                        inputClass={`w-100 ps-5`}
                        // inputStyle={{
                        //   border: "none",
                        // }}
                        buttonClass="bg-white"
                        // buttonStyle={{ border: "none" }}
                        containerClass=""
                        countryCodeEditable={false}
                        disableCountryGuess
                        country={"us"}
                        value={pageDetails?.phone}
                        onChange={(value, country) => {
                          setPageDetails({
                            ...pageDetails,
                            phone: `+${country.dialCode}-${value.slice(
                              country.dialCode.length
                            )}`,
                          });
                          setPhoneCode(country.countryCode);
                          setPhoneErrorMessage("");
                        }}
                        onBlur={() => validation("phone")}
                      />
                      {phoneErrorMessage && (
                        <label className="label-input100 text-danger">
                          {phoneErrorMessage}
                        </label>
                      )}
                    </div>
                  ) : (
                    <small>{portal?.phone}</small>
                  )}
                  {isPhoneEdit || !portal?.phone ? (
                    <>
                      <button
                        className={"btn btn-orange"}
                        style={{ background: portal?.theme }}
                        onClick={(e) => handleAddPhone(e)}
                      >
                        Add
                      </button>
                    </>
                  ) : (
                    // <img
                    //   src={edit}
                    //   alt="edit"
                    //   onClick={() => {
                    //     if (!isPhoneEdit) setIsPhoneEdit(!isPhoneEdit);
                    //   }}
                    // />
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        if (!isPhoneEdit) setIsPhoneEdit(!isPhoneEdit);
                      }}
                    >
                      <circle cx="18" cy="18" r="18" fill={portal?.theme} />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.5137 9.8065C20.5869 8.73174 22.3274 8.73108 23.4013 9.80503L25.8932 12.2969C26.958 13.3617 26.969 15.0854 25.918 16.1638L16.6849 25.637C15.9793 26.3609 15.0117 26.7691 14.0012 26.7691L11.2491 26.7689C9.96984 26.7689 8.94823 25.7025 9.00203 24.4234L9.12019 21.6144C9.15968 20.6754 9.54996 19.7854 10.2138 19.1205L19.5137 9.8065ZM22.3415 10.8665C21.8533 10.3783 21.0622 10.3786 20.5744 10.8672L18.9113 12.5328L23.1911 16.8125L24.8446 15.116C25.3224 14.6259 25.3173 13.8423 24.8333 13.3583L22.3415 10.8665ZM11.2745 20.1812L17.8514 13.5942L22.144 17.8868L15.6115 24.5893C15.1882 25.0236 14.6076 25.2685 14.0013 25.2685L11.2492 25.2684C10.8227 25.2684 10.4822 24.9129 10.5001 24.4865L10.6183 21.6775C10.642 21.1141 10.8762 20.5801 11.2745 20.1812ZM26.5148 26.6958C26.9289 26.6958 27.2645 26.3598 27.2645 25.9455C27.2645 25.5311 26.9289 25.1952 26.5148 25.1952H20.3931C19.9791 25.1952 19.6434 25.5311 19.6434 25.9455C19.6434 26.3598 19.9791 26.6958 20.3931 26.6958H26.5148Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingTab;
