import React, { useEffect, useState } from "react";
import FormInput from "../../../core/components/Inputs/FormInput";
import { Input } from "reactstrap";
import infoIcon from "../../../assets/img/infoIcon.png";
import DatePickerCustom from "../../../core/components/DatePicker/DatePickerReact";
import Autocomplete from "react-autocomplete";
import Dropzone from "./dragAndDrop/DragAndDrop";
import axios from "axios";
import removeIcon from "../../../assets/img/removeIcon.png";
import MobileInput from "../../../core/components/Inputs/MobileInput";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import { call } from "../../../api/apiCall";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  PhoneNumberUtil,
  PhoneNumberFormat as PNF,
} from "google-libphonenumber";
import postalCodes from "postal-codes-js";
import { updateBasicInfo } from "../../../store/backgroundScreening/backgroundScreeningAction";
import { initLoad, stopLoad } from "../../../store/loader/loaderActions";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import ApiLoader from "../../../core/components/Loaders/apiLoader/apiLoader";
import InfoAlertModel from "./InfoAlertModel";
import { updateUserDetails } from "../../../store/profile/profileActions";
import { toast } from "react-toastify";
const BasicInfoForm = ({
  user,
  completedStep,
  setCompletedStep,
  activeStep,
  setActiveStep,
  data,
}) => {
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, seCityList] = useState([]);
  const [locationInput, setLocationInput] = useState("");
  const [phoneCount, setPhoneCount] = useState("");
  const phoneUtil = PhoneNumberUtil.getInstance();
  const [filesSize, setFilesSize] = useState([]);
  const [countryId, setCountryId] = useState(
    data?.basicInfo?.country_id || null
  );
  const [stateId, setStateId] = useState(data?.basicInfo?.state_id || null);

  const [formData, setFormData] = useState({
    info: {
      first_name: data?.basicInfo?.info?.first_name || "",
      middle_name: data?.basicInfo?.info?.middle_name || "",
      last_name: data?.basicInfo?.info?.last_name || "",
      name_type: data?.basicInfo?.info?.name_type || "",
    },
    otherInfo: {
      first_name: data?.basicInfo?.otherInfo?.first_name || "",
      middle_name: data?.basicInfo?.otherInfo?.middle_name || "",
      last_name: data?.basicInfo?.otherInfo?.last_name || "",
      name_type: data?.basicInfo?.otherInfo?.name_type || "",
    },
    ssn: data?.basicInfo?.ssn || "",
    gender: data?.basicInfo?.gender || "",
    dob: data?.basicInfo?.dob || null,
    country: data?.basicInfo?.country || "",
    nationality: data?.basicInfo?.nationality || "",
    additional_nationality: data?.basicInfo?.additional_nationality || "",
    applicable_nationality: data?.basicInfo?.applicable_nationality || false,
    residence: data?.basicInfo?.residence || "",
    state: data?.basicInfo?.state || "",
    applicable_state: data?.basicInfo?.applicable_state || false,
    city: data?.basicInfo?.city || "",
    zip: data?.basicInfo?.zip || "",
    address: data?.basicInfo?.address || "",
    applicant_phone: data?.basicInfo?.applicant_phone || "",
    applicant_email: data?.basicInfo?.applicant_email || "",
    document: data?.basicInfo?.document || [],
    country_id: data?.basicInfo?.country_id || "",
    state_id: data?.basicInfo?.state_id || "",
    city_id: data?.basicInfo?.city_id || "",
    documents_name: data?.basicInfo?.documents_name || [],
  });

  useEffect(() => {
    setFormData({
      info: {
        first_name: data?.basicInfo?.info?.first_name || "",
        middle_name: data?.basicInfo?.info?.middle_name || "",
        last_name: data?.basicInfo?.info?.last_name || "",
        name_type: data?.basicInfo?.info?.name_type || "",
      },
      otherInfo: {
        first_name: data?.basicInfo?.otherInfo?.first_name || "",
        middle_name: data?.basicInfo?.otherInfo?.middle_name || "",
        last_name: data?.basicInfo?.otherInfo?.last_name || "",
        name_type: data?.basicInfo?.otherInfo?.name_type || "",
      },
      ssn: data?.basicInfo?.ssn || "",
      gender: data?.basicInfo?.gender || "",
      dob: data?.basicInfo?.dob || null,
      country: data?.basicInfo?.country || "",
      nationality: data?.basicInfo?.nationality || "",
      additional_nationality: data?.basicInfo?.additional_nationality || "",
      applicable_nationality: data?.basicInfo?.applicable_nationality || false,
      residence: data?.basicInfo?.residence || "",
      state: data?.basicInfo?.state || "",
      applicable_state: data?.basicInfo?.applicable_state || false,
      city: data?.basicInfo?.city || "",
      zip: data?.basicInfo?.zip || "",
      address: data?.basicInfo?.address || "",
      applicant_phone: data?.basicInfo?.applicant_phone || "",
      applicant_email: data?.basicInfo?.applicant_email || "",
      document: data?.basicInfo?.document || [],
      country_id: data?.basicInfo?.country_id || "",
      state_id: data?.basicInfo?.state_id || "",
      city_id: data?.basicInfo?.city_id || "",
      documents_name: data?.basicInfo?.documents_name || [],
    });
    setCountryId(data?.basicInfo?.country_id || null);
    setStateId(data?.basicInfo?.state_id || null);
  }, []);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleBannerPicture = async (files) => {
    try {
      setLoading(true);
      if (!files.length) {
        throw new Error("No files selected");
      }

      const UPLOAD_URL = `${process.env.REACT_APP_API_URL}api/v1/upload`;
      const formdata = new FormData();
      const headers = {
        "Accept-Language": "en",
        "content-type": "multipart/form-data",
      };

      for (let i = 0; i < files.length; i++) {
        formdata.append("image", files[i]);
      }

      await axios
        .post(UPLOAD_URL, formdata, { headers })
        .then((res) => {
          // Construct the array of document objects including URLs
          const documents = res?.data?.filesArray.map(
            ({ originalname, size }, index) => ({
              url: res?.data?.urlsArray[index],
              name: originalname,
              size,
            })
          );
          const docu_name = res?.data?.filesArray?.map(
            ({ originalname }) => originalname
          );

          setFormData({
            ...formData,
            document: !formData?.document?.length
              ? documents
              : [...formData?.document, ...documents],

            documents_name: !formData?.documents_name?.length
              ? docu_name
              : [...formData?.documents_name, ...docu_name],
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    } catch (err) {
      console.log(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await call("get", "api/v1/country", null, null);
        setCountryList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          `api/v1/state?country_id=${countryId}`,
          null,
          null
        );
        setStateList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [countryId]);

  useEffect(() => {
    (async () => {
      try {
        const res = await call(
          "get",
          formData?.applicable_state && stateId
            ? `api/v1/city?state_id=${stateId}`
            : `api/v1/city?country_id=${countryId}`,
          null,
          null
        );
        seCityList(res?.data?.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [stateId, countryId]);

  const [error, setError] = useState({
    info: {
      first_name: "",
      middle_name: "",
      last_name: "",
      name_type: "",
    },
    otherInfo: {
      first_name: "",
      middle_name: "",
      last_name: "",
      name_type: "",
    },
    ssn: "",
    gender: "",
    dob: "",
    country: "",
    nationality: "",
    additional_nationality: "",
    residence: "",
    state: "",
    city: "",
    zip: "",
    address: "",
    applicant_phone: "",
    applicant_email: "",
    document: "",
  });
  const phoneCountValidation = () => {
    let num = JSON.stringify(formData?.applicant_phone);
    let code = JSON.stringify(phoneCount);
    const number = phoneUtil.parse(num, code);
    return phoneUtil.isValidNumber(number);
  };

  const handleChangeValue = (type) => {
    console.log(
      type == "info_last_name" &&
        user?.last_name &&
        formData?.info?.last_name !== user?.last_name
    );
    if (
      type == "info_first_name" &&
      user?.first_name &&
      formData?.info?.first_name !== user?.first_name
    ) {
      toast.error("First Name is not matching with the profile information");
    }
    if (
      type == "info_last_name" &&
      user?.last_name &&
      formData?.info?.last_name !== user?.last_name
    ) {
      toast.error("Last Name is not matching with the profile information");
    }

    if (type == "gender" && user?.gender && formData?.gender !== user?.gender) {
      toast.error("Gender is not matching with the profile information");
    }

    if (
      type == "applicant_email" &&
      user?.email &&
      formData?.applicant_email !== user?.email
    ) {
      toast.error(
        "Applicant Email is not matching with the profile information"
      );
    }

    if (
      type == "applicant_phone" &&
      user?.phone &&
      formData?.applicant_phone !== user?.phone
    ) {
      toast.error(
        "Applicant Phone is not matching with the profile information"
      );
    }

    if (
      type == "dob" &&
      user.dob &&
      moment(formData?.dob).format("DD-mm-yyyy") !==
        moment(user?.dob).format("DD-mm-yyyy")
    ) {
      toast.error("DOB is not matching with the profile information");
    }
  };

  const checkValidation = (type) => {
    let isFormValid = true;
    let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let nameRegex = /^[^\d\s]+$/;
    let numberRegex = /^[0-9]+$/;
    if (type == "info_first_name" || type == "all") {
      if (!formData?.info?.first_name.trim()) {
        setError({
          ...error,
          info: { ...error?.info, first_name: "First name is required" },
        });
        isFormValid = false;
        return;
      } else if (!nameRegex.test(formData?.info?.first_name.trim())) {
        setError({
          ...error,
          info: {
            ...error?.info,
            first_name: "Spaces and numeric are not allowed",
          },
        });
        isFormValid = false;
        return;
      } else {
        setError({
          ...error,
          info: { ...error?.info, first_name: "" },
        });
      }
    }
    if (formData?.info?.middle_name) {
      if (type == "info_middle_name" || type == "all") {
        if (!formData?.info?.middle_name.trim()) {
          setError({
            ...error,
            info: { ...error?.info, middle_name: "Middle name is required" },
          });
          isFormValid = false;
          return;
        } else if (!nameRegex.test(formData?.info?.middle_name.trim())) {
          setError({
            ...error,
            info: {
              ...error?.info,
              middle_name: "Spaces and numeric are not allowed",
            },
          });
          isFormValid = false;
          return;
        } else {
          setError({
            ...error,
            info: { ...error?.info, middle_name: "" },
          });
        }
      }
    }

    if (type == "info_last_name" || type == "all") {
      if (!formData?.info?.last_name.trim()) {
        setError({
          ...error,
          info: { ...error?.info, last_name: "Last name is required" },
        });
        isFormValid = false;
        return;
      } else if (!nameRegex.test(formData?.info?.last_name.trim())) {
        setError({
          ...error,
          info: {
            ...error?.info,
            last_name: "Spaces and numeric are not allowed",
          },
        });
        isFormValid = false;
        return;
      } else {
        setError({
          ...error,
          info: { ...error?.info, last_name: "" },
        });
      }
    }

    if (type == "info_name_type" || type == "all") {
      if (!formData?.info?.name_type.trim()) {
        setError({
          ...error,
          info: { ...error?.info, name_type: "Name type is required" },
        });
        isFormValid = false;
        return;
      } else {
        setError({
          ...error,
          info: { ...error?.info, name_type: "" },
        });
      }
    }
    if (additionalInfo) {
      if (type == "otherInfo_first_name" || type == "all") {
        if (!formData?.otherInfo?.first_name.trim()) {
          setError({
            ...error,
            otherInfo: {
              ...error?.otherInfo,
              first_name: "First name is required",
            },
          });
          isFormValid = false;
          return;
        } else if (!nameRegex.test(formData?.otherInfo?.first_name.trim())) {
          setError({
            ...error,
            otherInfo: {
              ...error?.otherInfo,
              first_name: "Spaces and numeric are not allowed",
            },
          });
          isFormValid = false;
          return;
        } else {
          setError({
            ...error,
            otherInfo: { ...error?.otherInfo, first_name: "" },
          });
        }
      }

      if (type == "otherInfo_last_name" || type == "all") {
        if (!formData?.otherInfo?.last_name.trim()) {
          setError({
            ...error,
            otherInfo: {
              ...error?.otherInfo,
              last_name: "Last name is required",
            },
          });
          isFormValid = false;
          return;
        } else if (!nameRegex.test(formData?.otherInfo?.last_name.trim())) {
          setError({
            ...error,
            otherInfo: {
              ...error?.otherInfo,
              last_name: "Spaces and numeric are not allowed",
            },
          });
          isFormValid = false;
          return;
        } else {
          setError({
            ...error,
            otherInfo: { ...error?.otherInfo, last_name: "" },
          });
        }
      }

      if (type == "otherInfo_name_type" || type == "all") {
        if (!formData?.otherInfo?.name_type.trim()) {
          setError({
            ...error,
            otherInfo: {
              ...error?.otherInfo,
              name_type: "Name type is required",
            },
          });
          isFormValid = false;
          return;
        } else {
          setError({
            ...error,
            otherInfo: { ...error?.otherInfo, name_type: "" },
          });
        }
      }
    }

    if (type == "ssn" || type == "all") {
      if (!formData?.ssn.trim()) {
        setError({ ...error, ssn: "SSN is required" });
        isFormValid = false;
        return;
      } else if (!numberRegex.test(formData?.ssn.trim())) {
        setError({ ...error, ssn: "Please enter a valid ssn number" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, ssn: "" });
      }
    }

    if (type == "zip" || type == "all") {
      if (formData?.zip && !numberRegex.test(formData?.zip?.trim())) {
        setError({ ...error, zip: "Only numeric are allowed" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, zip: "" });
      }
    }
    if (type == "dob" || type == "all") {
      if (!formData?.dob) {
        setError({ ...error, dob: "DOB is required" });
        isFormValid = false;
        return;
      } else if (
        new Date().getFullYear() - new Date(formData?.dob).getFullYear() <=
        17
      ) {
        setError({ ...error, dob: "DOB is should be 18+" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, dob: "" });
      }
    }
    if (type == "country" || type == "all") {
      if (!formData?.country) {
        setError({ ...error, country: "Country is required" });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, country: "" });
      }
    }
    if (type == "nationality" || type == "all") {
      if (!formData?.nationality) {
        setError({ ...error, nationality: "Nationality is required" });
        isFormValid = false;
        return;
      } else if (formData?.nationality == formData?.additional_nationality) {
        setError({
          ...error,
          nationality:
            "Nationality should be different from additional nationality",
        });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, nationality: "" });
      }
    }
    if (!formData?.applicable_nationality) {
      if (type == "additional_nationality" || type == "all") {
        if (!formData?.additional_nationality) {
          setError({
            ...error,
            additional_nationality: "Additional nationality is required",
          });
          isFormValid = false;
          return;
        } else if (formData?.additional_nationality == formData?.nationality) {
          setError({
            ...error,
            additional_nationality:
              "Additional nationality should be different from Nationality",
          });
          isFormValid = false;
          return;
        } else {
          setError({ ...error, additional_nationality: "" });
        }
      }
    }

    if (type == "residence" || type == "all") {
      if (!formData?.residence) {
        setError({
          ...error,
          residence: "Residence is required",
        });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, residence: "" });
      }
    }
    if (!formData?.applicable_state) {
      if (type == "state" || type == "all") {
        if (!formData?.state) {
          setError({
            ...error,
            state: "State is required",
          });
          isFormValid = false;
          return;
        } else {
          setError({ ...error, state: "" });
        }
      }
    }
    if (type == "city" || type == "all") {
      if (!formData?.city) {
        setError({
          ...error,
          city: "City is required",
        });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, city: "" });
      }
    }

    if (type == "address" || type == "all") {
      if (!formData?.address.trim()) {
        setError({
          ...error,
          address: "Address is required",
        });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, address: "" });
      }
    }
    if (type == "applicant_phone" || type == "all") {
      if (!formData?.applicant_phone.trim()) {
        setError({
          ...error,
          applicant_phone: "Applicant phone is required",
        });
        isFormValid = false;
        return;
      } else if (formData?.applicant_phone.length < 10) {
        setError({
          ...error,
          applicant_phone: "Please enter the valid number.",
        });
        isFormValid = false;
        return;
      } else if (!phoneCountValidation()) {
        setError({
          ...error,
          applicant_phone: "Please enter the valid number.",
        });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, applicant_phone: "" });
      }
    }
    if (type == "applicant_email" || type == "all") {
      if (!formData?.applicant_email.trim()) {
        setError({
          ...error,
          applicant_email: "Applicant email is required",
        });
        isFormValid = false;
        return;
      } else if (!emailRegex.test(formData?.applicant_email.trim())) {
        setError({
          ...error,
          applicant_email: "Please enter your email in a valid format.",
        });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, applicant_email: "" });
      }
    }
    if (type == "document" || type == "all") {
      if (!formData?.document.length) {
        setError({
          ...error,
          document: "Documents are required",
        });
        isFormValid = false;
        return;
      } else {
        setError({ ...error, document: "" });
      }
    }
    return isFormValid;
  };
  const [alertModel, setAlertModel] = useState(false);
  const [updateInfo, setupdateInfo] = useState(false);
  const checkInfo = () => {
    let info = true;
    if (
      (user?.first_name && formData?.info?.first_name !== user?.first_name) ||
      (user?.last_name && formData?.info?.last_name !== user?.last_name) ||
      (user?.gender && formData?.gender !== user?.gender) ||
      (user?.email && formData?.applicant_email !== user?.email) ||
      (user?.phone && formData?.applicant_phone !== user?.phone)
    ) {
      setAlertModel(true);
      info = false;
    }
    return info;
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    if (checkValidation("all")) {
      if (checkInfo()) {
        dispatch(updateBasicInfo(formData));
        if (!completedStep.includes("step2"))
          setCompletedStep([...completedStep, "step2"]);
        window.scrollTo(0, 0);
        setActiveStep(activeStep + 1);
      }
    }
  };

  const handleNext = () => {
    if (checkValidation("all")) {
      dispatch(updateBasicInfo(formData));
      if (!completedStep.includes("step2"))
        setCompletedStep([...completedStep, "step2"]);
      window.scrollTo(0, 0);
      setActiveStep(activeStep + 1);
    }
  };

  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (updateInfo) {
      console.log("", data);
      dispatch(
        updateUserDetails(
          {
            first_name: formData?.info?.first_name,
            last_name: formData?.info?.last_name,
            gender: formData?.gender,
            email: formData?.applicant_email,
            phone: formData?.applicant_phone,
          },
          token
        )
      );
      handleNext();
      setAlertModel(false);
    }
  }, [updateInfo]);

  return (
    <>
      <div className="px-5 py-3 package_form">
        <form>
          <div className="row">
            <div className="col-3">
              <FormInput
                type="text"
                label={"First Name"}
                required={true}
                value={formData?.info?.first_name}
                onBlur={() => {
                  checkValidation("info_first_name");
                  handleChangeValue("info_first_name");
                }}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    info: {
                      ...formData?.info,
                      first_name: e.target.value,
                    },
                  })
                }
                maxLength={20}
                error={error?.info?.first_name}
              />
            </div>
            <div className="col-3">
              <FormInput
                type="text"
                label={"Middle Name"}
                value={formData?.info?.middle_name}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    info: {
                      ...formData?.info,
                      middle_name: e.target.value,
                    },
                  })
                }
                maxLength={20}
                error={error?.info?.middle_name}
              />
            </div>
            <div className="col-3">
              <FormInput
                type="text"
                label={"Last Name"}
                required={true}
                value={formData?.info?.last_name}
                onBlur={() => {
                  checkValidation("info_last_name");
                  handleChangeValue("info_last_name");
                }}
                onChange={(e) =>
                  setFormData({
                    ...formData,

                    info: {
                      ...formData?.info,
                      last_name: e.target.value,
                    },
                  })
                }
                maxLength={20}
                error={error?.info?.last_name}
              />
            </div>
            <div className="col-3 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                Name Type <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                onClick={() => checkValidation("info_name_type")}
                value={formData?.info?.name_type}
                onChange={(e) =>
                  setFormData({
                    ...formData,

                    info: {
                      ...formData?.info,
                      name_type: e.target.value,
                    },
                  })
                }
              >
                <option>Select</option>
                <option value="current">Current</option>
                <option value="previous">Previous</option>
                <option value="maiden">Madien</option>
                <option value="alias">Alias</option>
              </Input>
              {error?.info?.name_type && (
                <p className="text-danger">{error?.info?.name_type}</p>
              )}
            </div>
          </div>

          <p
            className="additional_name p-1 mt-3"
            onClick={() => setAdditionalInfo(!additionalInfo)}
          >
            {!additionalInfo
              ? "+ Add an additional or previous name"
              : "- Remove additional or previous name"}
          </p>

          {additionalInfo && Object.keys(data?.basicInfo?.otherInfo).length && (
            <div className="row">
              <div className="col-3">
                <FormInput
                  type="text"
                  label={"Other First Name 1"}
                  required={true}
                  value={formData?.otherInfo?.first_name}
                  onBlur={() => checkValidation("otherInfo_first_name")}
                  onChange={(e) =>
                    setFormData({
                      ...formData,

                      otherInfo: {
                        ...formData?.otherInfo,
                        first_name: e.target.value,
                      },
                    })
                  }
                  error={error?.otherInfo?.first_name}
                  maxLength={20}
                />
              </div>
              <div className="col-3">
                <FormInput
                  type="text"
                  label={"Other Middle Name 1"}
                  value={formData?.otherInfo?.middle_name}
                  onChange={(e) =>
                    setFormData({
                      ...formData,

                      otherInfo: {
                        ...formData?.otherInfo,
                        middle_name: e.target.value,
                      },
                    })
                  }
                  maxLength={20}
                  error={error?.otherInfo?.middle_name}
                />
              </div>
              <div className="col-3">
                <FormInput
                  type="text"
                  label={"Other Last Name 1"}
                  required={true}
                  value={formData?.otherInfo?.last_name}
                  onBlur={() => checkValidation("otherInfo_last_name")}
                  onChange={(e) =>
                    setFormData({
                      ...formData,

                      otherInfo: {
                        ...formData?.otherInfo,
                        last_name: e.target.value,
                      },
                    })
                  }
                  maxLength={20}
                  error={error?.otherInfo?.last_name}
                />
              </div>
              <div className="col-3 d-flex flex-column justify-content-end">
                <label htmlFor="name_type" className="form-label">
                  Name Type <span className="text-danger">*</span>
                </label>
                <Input
                  type="select"
                  onClick={() => checkValidation("otherInfo_name_type")}
                  value={formData?.otherInfo?.name_type}
                  onChange={(e) =>
                    setFormData({
                      ...formData,

                      otherInfo: {
                        ...formData?.otherInfo,
                        name_type: e.target.value,
                      },
                    })
                  }
                >
                  <option>Select</option>
                  <option value="current">Current</option>
                  <option value="previous">Previous</option>
                  <option value="maiden">Madien</option>
                  <option value="alias">Alias</option>
                </Input>
                {error?.otherInfo?.name_type && (
                  <p className="text-danger">{error?.otherInfo?.name_type}</p>
                )}
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-6">
              <FormInput
                type="text"
                labelImage={infoIcon}
                label={"SSN (NON-USA Applicants, enter 000-00-0000)"}
                required={true}
                onBlur={() => checkValidation("ssn")}
                onChange={(e) =>
                  setFormData({
                    ...formData,

                    ssn: e.target.value,
                  })
                }
                maxLength={9}
                value={formData?.ssn}
                error={error?.ssn}
                tooltipMessage={
                  "The Social Security Number for the subject (NON-USA Applicant type in 000 00 0000)"
                }
              />
            </div>
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Gender, if supplied, may be either M or F"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                Gender{" "}
              </label>
              <Input
                type="select"
                value={formData?.gender}
                onClick={() => {
                  checkValidation("gender");
                  handleChangeValue("gender");
                }}
                onChange={(e) =>
                  setFormData({
                    ...formData,

                    gender: e.target.value,
                  })
                }
              >
                <option>Select</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </Input>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="The subject's Date of Birth"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                DOB (MM/DD/YYYY) <span className="text-danger">*</span>
              </label>
              <DatePickerCustom
                value={formData?.dob ? new Date(formData?.dob) : null}
                onChange={(date) => {
                  date
                    ? setFormData({
                        ...formData,

                        dob: moment(date).format("YYYY-MM-DD"),
                      })
                    : setFormData({
                        ...formData,

                        dob: null,
                      });
                }}
                max={new Date()}
                placeholder=""
                onBlur={() => {
                  checkValidation("dob");
                  handleChangeValue("dob");
                }}
              />
              {error?.dob && <p className="text-danger">{error?.dob}</p>}
            </div>
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Your Country of Birth"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                Country of Birth <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.country}
                onClick={() => checkValidation("country")}
                onChange={(e) =>
                  setFormData({
                    ...formData,

                    country: e.target.value,
                  })
                }
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.country && (
                <p className="text-danger">{error?.country}</p>
              )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Your Nationality"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                Nationality <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.nationality}
                onClick={() => checkValidation("nationality")}
                error={error?.nationality}
                onChange={(e) =>
                  setFormData({
                    ...formData,

                    nationality: e.target.value,
                  })
                }
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.nationality && (
                <p className="text-danger">{error?.nationality}</p>
              )}
            </div>
            <div className="col-6 d-flex flex-column justify-content-end">
              <label
                htmlFor="name_type"
                className="form-label d-flex justify-content-between align-items-center"
              >
                <div className="">
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Your Additional Nationality"
                  >
                    <img src={infoIcon} width={20} className="me-2" />
                  </button>{" "}
                  Previous / Additional Nationality{" "}
                  {!formData?.applicable_nationality && (
                    <span className="text-danger">*</span>
                  )}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ width: "30%" }}
                >
                  <p
                    // style={{ marginLeft: "120px " }}
                    className="mx-2"
                  >
                    Not Applicable
                  </p>
                  <div className="switch-box d-flex justify-content-center align-items-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formData?.applicable_nationality}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              applicable_nationality: true,
                              additional_nationality: "",
                            });
                            setError({ ...error, additional_nationality: "" });
                          } else {
                            setFormData({
                              ...formData,
                              applicable_nationality: false,
                            });
                          }
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </label>
              <Input
                type="select"
                value={formData?.additional_nationality}
                onClick={() => checkValidation("additional_nationality")}
                error={error?.additional_nationality}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    additional_nationality: e.target.value,
                  })
                }
                disabled={formData?.applicable_nationality}
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.additional_nationality && (
                <p className="text-danger">{error?.additional_nationality}</p>
              )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Current Country of Residence"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                Country of Residence <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.residence}
                onClick={() => checkValidation("residence")}
                error={error?.residence}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    residence: e.target.value,
                    country_id: countryList.find(
                      (ele) => ele?.name == e.target.value
                    )?.id,
                  });
                  setCountryId(
                    countryList.find((ele) => ele?.name == e.target.value)?.id
                  );
                }}
              >
                <option>Select</option>
                {countryList?.map((ele) => {
                  return (
                    <>
                      <option value={ele.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.residence && (
                <p className="text-danger">{error?.residence}</p>
              )}
            </div>
            <div className="col-6 d-flex flex-column justify-content-end">
              <label
                htmlFor="name_type"
                className="form-label d-flex justify-content-between align-items-center"
              >
                <div className="">
                  <button
                    type="button"
                    className="bg-transparent border-0"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Your State"
                  >
                    <img src={infoIcon} width={20} className="me-2" />
                  </button>{" "}
                  State / Province
                  {!formData?.applicable_state && (
                    <span className="text-danger">*</span>
                  )}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ width: "30%" }}
                >
                  <p
                    // style={{ marginLeft: "120px " }}
                    className="mx-2"
                  >
                    Not Applicable
                  </p>
                  <div className="switch-box d-flex justify-content-center align-items-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={formData?.applicable_state}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setFormData({
                              ...formData,
                              state: "",
                              applicable_state: true,
                            });
                            setError({ ...error, state: "" });
                          } else {
                            setFormData({
                              ...formData,
                              applicable_state: false,
                            });
                          }
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </label>
              <Input
                type="select"
                value={formData?.state}
                disabled={!formData?.residence || formData?.applicable_state}
                onClick={() => checkValidation("state")}
                error={error?.state}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    state: e.target.value,
                    state_id: stateList.find(
                      (ele) => ele?.name == e.target.value
                    )?.id,
                  });
                  setStateId(
                    stateList.find((ele) => ele?.name == e.target.value)?.id
                  );
                }}
              >
                <option>Select</option>
                {stateList?.map((ele) => {
                  return (
                    <>
                      <option value={ele.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.state && <p className="text-danger">{error?.state}</p>}
            </div>
          </div>

          <div className="row">
            <div className="col-6 d-flex flex-column justify-content-end">
              <label htmlFor="name_type" className="form-label">
                <button
                  type="button"
                  className="bg-transparent border-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="USA City name letter abbreviation (NON-USA Applicants, type in XX)"
                >
                  <img src={infoIcon} width={20} className="me-2" />
                </button>{" "}
                City <span className="text-danger">*</span>
              </label>
              <Input
                type="select"
                value={formData?.city}
                onClick={() => checkValidation("city")}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    city: e.target.value,
                    city_id: cityList.find((ele) => ele?.name == e.target.value)
                      ?.id,
                  })
                }
              >
                <option>Select</option>
                {cityList?.map((ele) => {
                  return (
                    <>
                      <option value={ele?.name}>{ele?.name}</option>
                    </>
                  );
                })}
              </Input>
              {error?.city && <p className="text-danger">{error?.city}</p>}
            </div>
            <div className="col-6">
              <FormInput
                type="text"
                label={"Zip (type 00000 if not applicable)"}
                labelImage={infoIcon}
                value={formData?.zip}
                error={error?.zip}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    zip: e.target.value,
                  })
                }
                onBlur={() => checkValidation("zip")}
                maxLength={6}
                tooltipMessage={
                  "Five digit USA zip code. NON USA Applicants, type 00000."
                }
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <FormInput
                type="text"
                value={formData?.address}
                required={true}
                label="Address (NON-USA Applicants,
              type your FULL ADDRESS with CITY,.."
                labelImage={infoIcon}
                error={error?.address}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  })
                }
                onBlur={() => checkValidation("address")}
                tooltipMessage={
                  "ADD Your address here. (NON-USA Applicants, type in your FULL ADDRESS with CITY, ZIP CODE, COUNTRY here)"
                }
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <MobileInput
                label="Applicant Phone"
                labelStyle={{ fontSize: "auto", fontWeight: "auto" }}
                labelImage={infoIcon}
                required={true}
                onChange={(value, country, e, formattedValue) => {
                  setFormData({
                    ...formData,
                    applicant_phone: `+${country.dialCode}-${value.slice(
                      country.dialCode.length
                    )}`,
                  });
                  setPhoneCount(country.countryCode);
                }}
                value={formData?.applicant_phone}
                // placeholder="Number"
                inputType="page"
                containerClassName="mt-3"
                error={error?.applicant_phone}
                onBlur={() => {
                  checkValidation("applicant_phone");
                  handleChangeValue("applicant_phone");
                }}
                tooltipMessage={"Applicant's phone number. e.g. 601-555-1212."}
              />
            </div>

            <div className="col-6">
              <FormInput
                type="email"
                label={"Applicant Email"}
                labelImage={infoIcon}
                disabled={formData?.applicant_email ? true : false}
                required={true}
                value={formData?.applicant_email}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    applicant_email: e.target.value,
                  })
                }
                error={error?.applicant_email}
                tooltipMessage={
                  "Applicant's email address. This may be used to contact the applicant to obtain PII (SSN or DOB) or a signed release if you request that we do so."
                }
                onBlur={() => {
                  checkValidation("applicant_email");
                  handleChangeValue("applicant_email");
                }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <label
                htmlFor="name_type"
                className="form-label"
                style={{ fontSize: "20px", fontWeight: 600 }}
              >
                Attachments <span className="text-danger">*</span>
              </label>
              <p className="mt-2">
                Please attach your identity supporting documents for
                verification, such as a government-issued passport copy, ID
                copy, or driver's license photo. If your documents for
                verification display a previous name or nationality, kindly
                provide proof of the change along with the documents.
              </p>
            </div>
          </div>

          {loading ? (
            <div className="d-flex justify-content-center align-items-center">
              {1 == 1 && <ApiLoader />}
            </div>
          ) : (
            <Dropzone handleBannerPicture={handleBannerPicture} />
          )}

          {formData?.document?.length == 0 && error?.document && (
            <p className="text-danger">{error?.document}</p>
          )}

          <div className="row row-cols-2 p-3">
            {formData?.document?.map((ele, index) => (
              <div className="col p-2 bg-white mt-2 mb-2" key={index}>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <p
                      className="m-0 px-2 py-1"
                      style={{ background: "#BDE7FF" }}
                    >
                      {ele?.name?.split(".")?.pop()?.toUpperCase() || "Pdf"}
                    </p>
                    <p className="m-0 mx-3">{ele?.name}</p>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <p
                      className="mx-4 text-secondary"
                      style={{ fontSize: "12px", paddingBottom: 0 }}
                    >
                      {(ele?.size / 1024).toFixed(2) + " KB"}
                    </p>
                    <img
                      src={removeIcon}
                      alt="remove"
                      width={15}
                      onClick={() =>
                        setFormData({
                          ...formData,
                          document: formData?.document?.filter(
                            (_, i) => i !== index
                          ),
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <button className="btn btn-blue" onClick={(e) => handleSubmit(e)}>
              Continue to Next Step
            </button>
          </div>
        </form>
      </div>

      {alertModel && (
        <InfoAlertModel
          modal={alertModel}
          toggle={() => setAlertModel(!alertModel)}
          updateInfo={updateInfo}
          setupdateInfo={setupdateInfo}
          handleNext={handleNext}
        />
      )}
    </>
  );
};

export default BasicInfoForm;
