import React, { useEffect, useState } from "react";
import cover from "../../../assets/img/portal-cover.png";
import findjob from "../../../assets/img/find-job.svg";
import "./PortalDashboard.scss";
import Autocomplete from "react-autocomplete";
import JobCard from "./JobCard";
import { FormGroup, Input } from "reactstrap";
import BenifitCard from "./BenifitCard";
import CardCarousel from "./CardCarousel";
import FooterJobUpdates from "../portalFooter/FooterJobUpdates";
import PortalFooter from "../portalFooter/PortalFooter";
import { useDispatch, useSelector } from "react-redux";
import placeholderUser from "../../../assets/img/default_profile_sqr img.svg";
import user_logout from "../../../assets/img/Logout.svg";
import searchIcon from "../../../assets/img/Search-white.svg";
import { useNavigate, useParams } from "react-router-dom";
import { logoutPortal } from "../../../store/portal/portalAction";
import {
  createSearchHistory,
  getAllPortalJobs,
  getSearchHistory,
} from "../../../store/jobs/jobAction";
import { getLocationsData } from "../../../store/connections/connectionsActions";
import { ToastContainer } from "react-toastify";
import AppLoader from "../../../core/components/Loaders/appLoader/appLoader";
import pagePlaceholder from "../../../assets/img/page-placeholder.svg";
import locationIcon from "../../../assets/img/location-job.svg";

const PortalDashboard = () => {
  const [themeColor, setThemeColor] = useState();
  const { profileData } = useSelector((state) => state.profile);
  const { getAllJobs, getAllFeaturedJobs } = useSelector((state) => state.jobs);
  const { searchHistory } = useSelector((state) => state.jobs);
  const { isLoading } = useSelector((state) => state.loader);
  const { talentPoolData, talentPoolDataByToken } = useSelector(
    (state) => state.portal
  );
  const [profileDropdown, setProfileDropdown] = useState(false);
  const { param } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("accessTpToken");
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const [totalPages, setTotalPages] = useState(0);
  const [locationOpen, setLocationOpen] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState(null);
  const [locationsAPIData, setLocationsAPIData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [workType, setWorkType] = useState("");
  const [remoteJobs, setRemoteJobs] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    location: searchFieldValue,
  });
  const [searchKey, setSearchKey] = useState("");
  const menuStyle = {
    fontSize: "14px",
    fontWeight: 500,
  };

  useEffect(() => {
    if (talentPoolData)
      setThemeColor(
        talentPoolData?.theme ? talentPoolData.theme : "rgb(246, 138, 24)"
      );
    getFeaturedJobs();
    getPortalJobs();
  }, [talentPoolData]);
  useEffect(() => {
    if (token)
      dispatch(getSearchHistory({ limit: 4, skip: 0, type: "job" }, true))
        .then((res) => {
          // if (!res?.data?.length) setIsViewMore(false);
        })
        .catch((err) => {});
  }, []);

  useEffect(() => {
    getPortalJobs();
  }, [filter, workType, remoteJobs]);

  useEffect(() => {
    getPaginationJobs();
  }, [page]);

  const searchJobs = () => {
    if (token && searchKey)
      dispatch(
        createSearchHistory(
          { search: searchKey, type: "job", type_id: null },
          true
        )
      );
    getPortalJobs();
  };

  const getPortalJobs = () => {
    setPage(1);
    if (talentPoolData?.company_id)
      dispatch(
        getAllPortalJobs(
          {
            limit: pageSize,
            skip: 0,
            search: searchKey || undefined,
            location: searchFieldValue || undefined,
            company_id: talentPoolData?.company_id || undefined,
            sort: filter == "older" ? "asc" : "desc",
            filter: !filter || filter == "older" ? undefined : filter,
            employment_type: workType || undefined,
            workspace_type: remoteJobs ? "Remote" : null,
            status: "active",
          },
          0
        )
      ).then((res) => {
        setTotalPages(Math.ceil(res?.totalItem / pageSize));
      });
  };

  const getPaginationJobs = () => {
    if (talentPoolData?.company_id)
      dispatch(
        getAllPortalJobs(
          {
            limit: pageSize,
            skip: (page - 1) * pageSize,
            search: searchKey || undefined,
            location: searchFieldValue || undefined,
            company_id: talentPoolData?.company_id || undefined,
            sort: filter == "older" ? "asc" : "desc",
            filter: !filter || filter == "older" ? undefined : filter,
            employment_type: workType || undefined,
            workspace_type: remoteJobs ? "Remote" : null,
            status: "active",
          },
          0
        )
      ).then((res) => {
        setTotalPages(Math.ceil(res?.totalItem / pageSize));
      });
  };

  const getFeaturedJobs = () => {
    if (talentPoolData?.company_id)
      dispatch(
        getAllPortalJobs(
          {
            limit: 30,
            skip: 0,
            filter: "allfeatured",
            company_id: talentPoolData?.company_id || undefined,
            status: "active",
          },
          0,
          "allfeatured"
        )
      ).then((res) => {});
  };

  const handleLogout = async () => {
    try {
      dispatch(logoutPortal())
        .then(() => {
          navigate(`/tp/portal/${param}/login`);
        })
        .catch(() => {
          navigate(`/tp/portal/${param}/login`);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const inputProps = {
    placeholder: "Select Location",
    style: {
      fontSize: "14px",
      fontWeight: 500,
    },
  };
  const getLocations = ({ value }) => {
    if (value)
      dispatch(getLocationsData(value))
        .then((response) => {
          if (response.data) {
            setLocationsAPIData(response.data);
          } else setLocationsAPIData([]);
        })
        .catch((err) => {
          setLocationsAPIData([]);
        });
    else setLocationsAPIData([]);
  };

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const checkedStyle = {
    accentColor: themeColor,
    backgroundColor: themeColor,
  };
  return (
    <div className="portal-dashboard">
      {isLoading && <AppLoader />}
      <div className="portal-cover-img">
        <img
          src={
            talentPoolData?.background_img
              ? talentPoolData?.background_img
              : cover
          }
          alt="big-img"
          className="img-cover"
        />
        <div className="d-flex justify-content-center">
          <div className="header-row ">
            <div
              className="d-flex justify-content-sm-center"
              style={{ height: "40px", alignItems: "center" }}
            >
              <img
                src={
                  talentPoolData?.icon ? talentPoolData?.icon : pagePlaceholder
                }
                alt="companny-logo"
                style={{ width: "50px", height: "50px", borderRadius: "50%" }}
              />
              <h4 className="card-control-text-oneline ms-2">
                {talentPoolData?.company_name}
              </h4>
            </div>
            <div className="authenticate">
              {talentPoolData?.id != talentPoolDataByToken?.id ? (
                <>
                  <p
                    className="login"
                    onClick={() => navigate(`/tp/portal/${param}/login`)}
                  >
                    Login
                  </p>
                  <div
                    className="btn btn-orange"
                    onClick={() => navigate(`/tp/portal/${param}/signup`)}
                    style={{ background: themeColor, whiteSpace: "nowrap" }}
                  >
                    Register Now
                  </div>
                </>
              ) : (
                <a className="nav-link">
                  <div className="prof" style={{ position: "sticky" }}>
                    <img
                      src={
                        profileData?.profile_img
                          ? profileData.profile_img
                          : placeholderUser
                      }
                      className="rounded-circle"
                      height={40}
                      width={40}
                      alt=""
                      onClick={() => setProfileDropdown(!profileDropdown)}
                    />
                    {profileDropdown ? (
                      <div className="admin" style={{ width: "170px" }}>
                        <ul>
                          <li className="dropdown-span">
                            <img
                              src={
                                profileData?.profile_img
                                  ? profileData.profile_img
                                  : placeholderUser
                              }
                              alt=""
                              style={{ height: "17px", width: "17px" }}
                            />
                            <span
                              onClick={() =>
                                navigate(`/tp/portal/${param}/profile/edit`)
                              }
                            >
                              View Profile
                            </span>
                          </li>
                          <li className="dropdown-span">
                            <img
                              src={user_logout}
                              alt="log_out"
                              style={{ height: "17px", width: "17px" }}
                            />
                            <span onClick={() => handleLogout()}>Log out</span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="center_banner_content">
          <div className="find-jobs">
            <img src={findjob} alt="find-jobs" />
          </div>
          <div className="testtt">
            <div className="search">
              <div>
                <Input
                  type="text"
                  value={searchKey}
                  // onChange={(e) => setSearchKey(e.target.value)}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                  }}
                  placeholder="Search for jobs by skill or keyword"
                />
              </div>
              <div
                className="autocomplete-wrapper"
                style={{ width: "200px", position: "relative" }}
              >
                <img
                  src={locationIcon}
                  alt="search"
                  style={{
                    top: "10px",
                    position: "absolute",
                    left: "10px",
                    zIndex: 999,
                  }}
                />
                <Autocomplete
                  open={locationOpen}
                  inputProps={inputProps}
                  value={searchFieldValue}
                  items={locationsAPIData}
                  getItemValue={(item) => item.name}
                  shouldItemRender={() => locationsAPIData}
                  renderMenu={(items, value) => (
                    <div className="dropdown dropdown-location">
                      {items.length === 0 ? `No matches for ${value}` : items}
                    </div>
                  )}
                  renderItem={(item, isHighlighted) => (
                    <div
                      className={`item ${isHighlighted ? "selected-item" : ""}`}
                    >
                      {item.name}
                    </div>
                  )}
                  onChange={(e, val) => {
                    if (val.length === 0) {
                      if (locationOpen) setLocationOpen(false);
                    } else {
                      if (!locationOpen) setLocationOpen(true);
                    }
                    setSearchFieldValue(e.target.value);
                    getLocations(e.target);
                  }}
                  onSelect={(value, obj) => {
                    setSearchFieldValue(value);
                    setLocationDetails({
                      ...locationDetails,
                      location: obj.name,
                    });
                    setLocationOpen(false);
                  }}
                  wrapperStyle={{}}
                  menuStyle={menuStyle}
                />
              </div>
              <div
                className="search-icon"
                style={{ backgroundColor: themeColor }}
              >
                <img
                  src={searchIcon}
                  alt="search"
                  onClick={() => searchJobs()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ backgroundColor: "white" }}>
        <div className="heading">
          {getAllJobs?.length > 0 ? <h2>New Jobs</h2> : <h4>No Jobs</h4>}
        </div>
        <div>
          {getAllFeaturedJobs?.length > 2 ? (
            <div className="card-crousel">
              <CardCarousel
                featuredJobs={getAllFeaturedJobs}
                themeColor={themeColor}
                param={param}
                isLoggedIn={talentPoolData?.id === talentPoolDataByToken?.id}
              />{" "}
            </div>
          ) : getAllFeaturedJobs?.length == 2 ? (
            <div className="d-flex">
              <JobCard
                themeColor={themeColor}
                jobs={getAllFeaturedJobs[0]}
                param={param}
                isLoggedIn={talentPoolData?.id === talentPoolDataByToken?.id}
              />
              <JobCard
                themeColor={themeColor}
                jobs={getAllFeaturedJobs[1]}
                param={param}
                isLoggedIn={talentPoolData?.id === talentPoolDataByToken?.id}
              />
            </div>
          ) : getAllFeaturedJobs?.length == 1 ? (
            <div className="d-flex justify-content-center">
              <JobCard
                themeColor={themeColor}
                jobs={getAllFeaturedJobs[0]}
                param={param}
                isLoggedIn={talentPoolData?.id === talentPoolDataByToken?.id}
              />{" "}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="row">
          <div className="col-lg-3 ">
            <h6>Filter</h6>
            <div className="d-flex  mt-3">
              <input
                type="radio"
                name="fav_language"
                className="me-3 custom-radio"
                style={{
                  // backgroundColor: themeColor,
                  accentColor: themeColor,
                }}
                onClick={() => setFilter("")}
                checked={!filter}
              />

              <p>All jobs</p>
            </div>

            <div className="d-flex   mt-3">
              <input
                type="radio"
                name="fav_language"
                className="me-3"
                style={{
                  backgroundColor: themeColor,
                  accentColor: themeColor,
                }}
                onClick={() => setFilter("relevent")}
                checked={filter == "relevent"}
              />
              <p>Most Relevant</p>
            </div>

            <div className="d-flex   mt-3">
              <input
                type="radio"
                name="fav_language"
                className="me-3"
                style={{
                  backgroundColor: themeColor,
                  accentColor: themeColor,
                }}
                onChange={() => setFilter("recommended")}
                checked={filter == "recommended"}
              />
              <p>Recommended</p>
            </div>
            <div className="d-flex  mt-3">
              <input
                type="radio"
                name="fav_language"
                className="me-3"
                style={{
                  backgroundColor: themeColor,
                  accentColor: themeColor,
                }}
                onClick={() => setFilter("older")}
                checked={filter === "older"}
              />
              <p>Most Older</p>
            </div>
            <h6 className="mt-3">Type of Work</h6>
            <select
              className="form-select mt-2"
              aria-label="Default select example"
              onChange={(e) => setWorkType(e?.target?.value)}
              style={{ fontSize: "14px" }}
            >
              <option selected value="">
                All Types
              </option>
              <option value="Full-time">Full time</option>
              <option value="Part-time">Part time</option>
              <option value="Contract">Contract</option>
              <option value="Temporary">Temporary</option>
              <option value="Other">Other</option>
              <option value="Volunteer">Volunteer</option>
              <option value="Internship">Internship</option>
            </select>

            <FormGroup switch className="mt-3 d-flex">
              <Input
                type="switch"
                role="switch"
                className="me-3"
                style={remoteJobs ? checkedStyle : {}}
                checked={remoteJobs}
                onChange={(e) => {
                  setRemoteJobs(e?.target?.checked ? true : false);
                }}
              />
              <p>Only Remote</p>
            </FormGroup>
          </div>
          <div className="col-lg-6">
            {getAllJobs?.map((itm, idx) => (
              <JobCard
                key={idx}
                themeColor={themeColor}
                jobs={itm}
                param={param}
                isLoggedIn={talentPoolData?.id === talentPoolDataByToken?.id}
              />
            ))}
            {getAllJobs?.length > 0 && (
              <div className="pagination">
                <button
                  className="pagination-button"
                  onClick={handlePrevious}
                  disabled={page == 1}
                >
                  &lt;
                </button>

                {totalPages > 1 ? (
                  <>
                    <button
                      className={`page-number ${page === 1 ? "active" : ""}`}
                      onClick={() => setPage(1)}
                    >
                      1
                    </button>

                    {page > 3 && <span className="ellipsis">...</span>}
                    {page > 2 && (
                      <button
                        className="page-number"
                        onClick={() => setPage(page - 1)}
                      >
                        {page - 1}
                      </button>
                    )}
                    {page !== 1 && page !== totalPages && (
                      <button className="page-number active">{page}</button>
                    )}
                    {page < totalPages - 1 && (
                      <button
                        className="page-number"
                        onClick={() => setPage(page + 1)}
                      >
                        {page + 1}
                      </button>
                    )}
                    {page < totalPages - 2 && (
                      <span className="ellipsis">...</span>
                    )}
                    <button
                      className={`page-number ${
                        page === totalPages ? "active" : ""
                      }`}
                      onClick={() => setPage(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </>
                ) : (
                  <button className="page-number active">1</button>
                )}

                <button
                  className="pagination-button"
                  onClick={handleNext}
                  disabled={page >= totalPages}
                >
                  &gt;
                </button>
              </div>
            )}
          </div>
          <div className="col-lg-3 right-side">
            <div className="recent-views" style={{ left: 0 }}>
              {searchHistory?.length > 0 && <h6 className="">Recent Views</h6>}
              {searchHistory?.map((item, index) => {
                return (
                  <p className="mt-3" key={index}>
                    {item?.search}
                  </p>
                );
              })}
            </div>
            <div className="mt-3" style={{ position: "relative" }}>
              <BenifitCard />
            </div>
          </div>
        </div>
        <FooterJobUpdates themeColor={themeColor} />
      </div>
      <PortalFooter talentPoolData={talentPoolData} />
      <ToastContainer />
    </div>
  );
};

export default PortalDashboard;
