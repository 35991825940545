import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addCriminal,
  addEducation,
  addEmployment,
  getScreeningRequest,
  removeAllEducation,
  removeAllEmployment,
  removeCriminal,
  removePerticularEducation,
  removePerticularEmployment,
  updateCriminal,
} from "../../../store/backgroundScreening/backgroundScreeningAction";

const PackageForm = ({
  experience,
  education,
  // step,
  // setStep,
  completedStep,
  setCompletedStep,
  activeStep,
  setActiveStep,
  data,
  user,
  // setData,
}) => {
  console.log(experience, education);
  const { screeningList } = useSelector((state) => state?.backgroundScreen);
  const [error, setError] = useState("");
  const handleStep = (e) => {
    e.preventDefault();
    if (
      data?.employment?.length == 0 &&
      data?.education?.length == 0 &&
      Object.keys(data?.criminal).length == 0
    ) {
      setError("Please Select an Option to continue");
    } else {
      setCompletedStep([...completedStep, "step2"]);
      setActiveStep(activeStep + 1);
      window.scrollTo(0, 0);
      setError("");
    }
  };
  const dispatch = useDispatch();

  return (
    <>
      <div className="px-5 py-3 package_form">
        <ul>
          <li>
            Enjoy an exclusive bundle discount when you select all three
            screenings together, offering significant savings.
          </li>
          <li className="mt-2">
            NobelPage premium members enjoy special reduced rates.
          </li>
        </ul>
        <div className="">
          <div className="">
            <div className="switch-box mt-3 d-flex justify-content-center align-items-center">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={data?.employment?.length > 0}
                  onChange={(e) => {
                    if (e.target.checked) {
                      // setStep({
                      //   ...step,
                      //   employment: experience?.map((ele) => ele?.id),
                      // });
                      experience?.map((exp) => {
                        dispatch(
                          addEmployment({
                            id: exp?.id,
                            title: exp?.title,
                            company_name: exp?.company_name,
                            country: exp?.country ? exp?.country?.name : null,
                            contact_name: user?.first_name || "",
                            state: exp?.state ? exp?.state?.name : null,
                            applicable_state: false,
                            city: exp?.city ? exp?.city?.name : null,
                            phone: user?.phone || "",
                            email: user?.email || "",
                            zip: "",
                            company_address: "",
                            is_current: exp?.is_current,
                            position: exp?.title || "",
                            employeeId: "",
                            comment: "",
                            start_date: exp?.start_date,
                            end_date: exp?.end_date,
                            separation_type: "",
                            document: [],
                            country_id: exp?.country ? exp?.country?.id : null,
                            state_id: exp?.state ? exp?.state?.id : null,
                            city_id: exp?.city ? exp?.city.id : null,
                          })
                        );
                      });
                      // setData({
                      //   ...data,
                      //   employment: experience.map((exp) => ({
                      // id: exp?.id,
                      // company_name: exp?.company_name,
                      // country: exp?.country ? exp?.country?.name : null,
                      // contact_name: "",
                      // state: exp?.state ? exp?.state?.name : null,
                      // applicable_state: false,
                      // city: exp?.city ? exp?.city?.name : null,
                      // phone: "",
                      // email: "",
                      // zip: "",
                      // company_address: "",
                      // is_current: exp?.is_current,
                      // position: "",
                      // employeeId: "",
                      // comment: "",
                      // start_date: exp?.start_date,
                      // end_date: exp?.end_date,
                      // separation_type: "",
                      // document: [],
                      // country_id: exp?.country ? exp?.country?.id : null,
                      // state_id: exp?.state ? exp?.state?.id : null,
                      // city_id: exp?.city ? exp?.city.id : null,
                      //   })),
                      // });
                    } else {
                      // setStep({ ...step, employment: [] });
                      // setData({ ...data, employment: [] });
                      dispatch(removeAllEmployment());
                    }
                  }}
                />

                <span className="slider round"></span>
              </label>
              <p style={{ marginLeft: "120px " }} className="fw-bold text-dark">
                Employment
              </p>
            </div>
            <p className="mx-4 mt-2">Select the desired option(s)</p>
            {experience?.map((ele, index) => {
              return (
                <>
                  <div className="mx-4 mt-3">
                    {console.log(ele)}
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            checked={
                              data?.employment?.find(
                                (element) => element?.id == ele?.id
                              )
                                ? true
                                : false
                            }
                            disabled={
                              ele?.screening_status == "Verified" ||
                              screeningList?.some(
                                (exp) =>
                                  exp?.employment?.some(
                                    (el) => el?.id === ele?.id
                                  ) && exp?.backgroundStatus === "pending"
                              )
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                dispatch(
                                  addEmployment({
                                    id: ele?.id,
                                    title: ele?.title,
                                    company_name: ele?.company_name,
                                    country: ele?.country
                                      ? ele?.country?.name
                                      : null,
                                    contact_name: user?.first_name || "",
                                    state: ele?.state ? ele?.state?.name : null,
                                    applicable_state: false,
                                    city: ele?.city ? ele?.city?.name : null,
                                    phone: user?.phone || "",
                                    email: user?.email || "",
                                    zip: "",
                                    company_address: "",
                                    is_current: ele?.is_current,
                                    position: ele?.title || "",
                                    employeeId: "",
                                    comment: "",
                                    start_date: ele?.start_date,
                                    end_date: ele?.end_date,
                                    separation_type: "",
                                    document: [],
                                    country_id: ele?.country
                                      ? ele?.country?.id
                                      : null,
                                    state_id: ele?.state
                                      ? ele?.state?.id
                                      : null,
                                    city_id: ele?.city ? ele?.city.id : null,
                                  })
                                );
                              } else {
                                // setStep({
                                //   ...step,
                                //   employment: step?.employment?.filter(
                                //     (id) => id !== ele.id
                                //   ),
                                // });
                                // setData({
                                //   ...data,
                                //   employment: data?.employment.slice(0, -1),
                                // });
                                dispatch(removePerticularEmployment(ele.id));
                              }
                            }}
                          />
                          <span className="fw-bold mx-2 text-dark">
                            {ele?.title}{" "}
                          </span>
                          <span>
                            {ele?.employment_type &&
                              `| ${ele?.employment_type}`}{" "}
                            {ele?.country?.name && `| ${ele?.country?.name}`}
                            {ele?.country?.code && `, ${ele?.country?.code}`}
                          </span>
                        </div>
                        {console.log(ele)}
                        <p className="mx-4">
                          {moment(ele?.start_date).format("MMM yyyy")} -{" "}
                          {ele?.is_current || ele?.end_date == null
                            ? "Present"
                            : moment(ele?.end_date).format("MMM yyyy")}
                        </p>
                      </div>
                      <p className="fw-bold text-dark">$54</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="mt-5">
            <div className="switch-box mt-3 d-flex justify-content-center align-items-center">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={data?.education?.length > 0 ? true : false}
                  onChange={(e) => {
                    if (e.target.checked) {
                      // setStep({
                      //   ...step,
                      //   education: education?.map((ele) => ele?.id),
                      // });
                      // setData({
                      //   ...data,
                      //   education: education.map((exp) => ({
                      // school_name: exp?.school_name,
                      // country: "",
                      // campus: "",
                      // state: "",
                      // applicable_state: false,
                      // city: "",
                      // phone: "",
                      // email: "",
                      // zip: "",
                      // address: "",
                      // student_id: "",
                      // major: "",
                      // degree: exp?.degree,
                      // comment: "",
                      // gpa: exp?.grade,
                      // start_date: exp?.start_date,
                      // end_date: exp?.end_date,
                      // graduated: false,
                      // highest_achieved: false,
                      // transcript: false,
                      // document: [],
                      // country_id: null,
                      // state_id: null,
                      // city_id: null,
                      //   })),
                      // });

                      education?.map((exp) => {
                        dispatch(
                          addEducation({
                            id: exp?.id,
                            title: exp?.title,
                            school_name: exp?.school_name,
                            country: "",
                            campus: "",
                            state: "",
                            applicable_state: false,
                            city: "",
                            phone: user?.phone || "",
                            email: user?.email || "",
                            zip: "",
                            address: "",
                            student_id: "",
                            major: exp?.field_of_study || "",
                            degree: exp?.degree,
                            comment: "",
                            gpa: exp?.grade || 0,
                            start_date: exp?.start_date,
                            end_date: exp?.end_date,
                            graduated: false,
                            highest_achieved: false,
                            transcript: false,
                            document: [],
                            country_id: null,
                            state_id: null,
                            city_id: null,
                          })
                        );
                      });
                    } else {
                      // setStep({ ...step, education: [] });
                      // setData({ ...data, education: [] });
                      dispatch(removeAllEducation());
                    }
                  }}
                />
                <span className="slider round"></span>
              </label>
              <p style={{ marginLeft: "120px " }} className="fw-bold text-dark">
                Education
              </p>
            </div>
            <p className="mx-4 mt-2">Select the desired option(s)</p>
            {education?.map((ele) => {
              return (
                <>
                  <div className="mx-4 mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="">
                        <div className="d-flex align-items-center">
                          <input
                            type="checkbox"
                            checked={
                              data?.education?.find(
                                (element) => element?.id == ele?.id
                              )
                                ? true
                                : false
                            }
                            disabled={
                              ele?.screening_status == "Verified" ||
                              screeningList?.some(
                                (exp) =>
                                  exp?.education?.some(
                                    (el) => el?.id === ele?.id
                                  ) && exp?.backgroundStatus === "pending"
                              )
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                // setStep({
                                //   ...step,
                                //   education: [...step?.education, ele?.id],
                                // });
                                // setData({
                                //   ...data,
                                //   education: [
                                //     ...data?.education,
                                // {
                                //   school_name: ele?.school_name,
                                //   country: "",
                                //   campus: "",
                                //   state: "",
                                //   applicable_state: false,
                                //   city: "",
                                //   phone: "",
                                //   email: "",
                                //   zip: "",
                                //   address: "",
                                //   student_id: "",
                                //   major: "",
                                //   degree: ele?.degree,
                                //   comment: "",
                                //   gpa: ele?.grade,
                                //   start_date: ele?.start_date,
                                //   end_date: ele?.end_date,
                                //   graduated: false,
                                //   highest_achieved: false,
                                //   transcript: false,
                                //   document: [],
                                //   country_id: null,
                                //   state_id: null,
                                //   city_id: null,
                                // },
                                //   ],
                                // });

                                dispatch(
                                  addEducation({
                                    id: ele?.id,
                                    school_name: ele?.school_name,
                                    country: "",
                                    campus: "",
                                    state: "",
                                    applicable_state: false,
                                    city: "",
                                    phone: user?.phone || "",
                                    email: user?.email || "",
                                    zip: "",
                                    address: "",
                                    student_id: "",
                                    major: "",
                                    degree: ele?.degree,
                                    comment: "",
                                    gpa: ele?.grade,
                                    start_date: ele?.start_date,
                                    end_date: ele?.end_date,
                                    graduated: false,
                                    highest_achieved: false,
                                    transcript: false,
                                    document: [],
                                    country_id: null,
                                    state_id: null,
                                    city_id: null,
                                  })
                                );
                              } else {
                                // setStep({
                                //   ...step,
                                //   education: step?.education?.filter(
                                //     (id) => id !== ele.id
                                //   ),
                                // });
                                // setData({
                                //   ...data,
                                //   education: data?.education.slice(0, -1),
                                // });
                                dispatch(removePerticularEducation(ele.id));
                              }
                            }}
                          />
                          <span className="fw-bold mx-2 text-dark">
                            {ele?.school_name}
                          </span>
                          <span>
                            {ele?.degree && `, ${ele?.degree}`}
                            {ele?.field_of_study && `, ${ele?.field_of_study}`},
                          </span>
                        </div>
                        <p className="mx-4">
                          {moment(ele?.start_date).format("MMM yyyy")} -{" "}
                          {moment(ele?.end_date).format("MMM yyyy")}
                        </p>
                      </div>
                      <p className="fw-bold text-dark">$54</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="mt-5">
            <div className="mt-3">
              <div className="d-flex justify-content-between align-items-center me-4">
                <div className="d-flex justify-content-center align-items-baseline">
                  <div className="switch-box mt-3 d-flex align-items-center">
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={
                          Object.keys(data?.criminal).length > 0 ? true : false
                        }
                        disabled={screeningList?.some(
                          (exp) => Object.keys(exp?.criminal || {}).length > 0
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            // setStep({ ...step, criminal: true });
                            // setData({
                            //   ...data,
                            //   criminal: { residence: "", nationality: "" },
                            // });
                            dispatch(addCriminal());
                          } else {
                            // setStep({ ...step, criminal: false });
                            // setData({
                            //   ...data,
                            //   criminal: {},
                            // });
                            dispatch(removeCriminal());
                          }
                        }}
                      />
                      <span className="slider round packageForm"></span>
                    </label>
                  </div>
                  <div>
                    <span className="fw-bold text-dark mx-4">
                      Criminal / WorldScan
                    </span>
                    <p className="mx-4 mt-2">Global Sanctions Watch Lists</p>
                  </div>
                </div>
                <p className="fw-bold text-dark">$54</p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p className="text-danger mb-3">{error}</p>
          <button className="btn btn-blue" onClick={(e) => handleStep(e)}>
            Continue to Next Step
          </button>
        </div>
      </div>
    </>
  );
};

export default PackageForm;
